import clsx from "clsx"
import LoanPendingLayout from "../layouts/LoanPendingLayout"
import LoanVehicleDetailsForm from "../components/forms/LoanVehicleDetailsForm"
import styles from "../styles/LoanSubpageVehicleDetails.module.scss"

function LoanPendingVehicleDetails() {

  return (
    <LoanPendingLayout
      subPageTitle="Vehicle Details"
      subPageExtraClassName={clsx(styles.cPageVehicleDetails, "c-page-vehicle-details",)}
      sectionExtraClassName="c-loan-vehicle-details"
    >

      <LoanVehicleDetailsForm />

    </LoanPendingLayout>
  )
}

export default LoanPendingVehicleDetails
