import {useTranslation} from "react-i18next"
import clsx from "clsx"
import {useMediaQuery} from "usehooks-ts"
import {responsiveBreakpoints} from "../constant/constants"
import HomeSectionControlImg from "../assets/images/home-section-control-img-x2.webp"
import HomeSectionControlImgMobile from "../assets/images/home-section-control-img-x2-mobile.webp"
import styles from "../styles/HomeSectionControl.module.scss"

function HomeSectionControl() {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  return (
    <section className={clsx(styles.cHomeSectionControl, "c-home-section-control")}>

      <div className="c-home-section-control-wrapper">

        <div className="c-home-section-control-grid">

          <div className="c-home-section-control-grid-item header">

            <div className="c-home-section-control-grid-item-header">

              <h2 className="c-font-staatliches">
                {t("Take control of your car loan at Leasyfi")}
              </h2>

              <p className="c-text-px18-responsive">
                {t("Leasyfi offers quick and flexible car financing. Discover your eligibility in just 60 seconds, without affecting your credit score.")}
              </p>

            </div>

          </div>

          <div className="c-home-section-control-grid-item image">

            <img
              src={isMobileBreakpoint? HomeSectionControlImgMobile : HomeSectionControlImg}
              alt={t("Take control of your car loan at Leasyfi")}
            />

          </div>

        </div>

      </div>

    </section>
  )
}

export default HomeSectionControl