import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "../common/Button"
import CustomSelect from "../form-elements/CustomSelect"
import CustomInput from "../form-elements/CustomInput"
import CustomInputPattern from "../form-elements/CustomInputPattern"
import CustomNumberInput from "../form-elements/CustomInputNumber"
import CustomSectionBoxed from "../common/CustomSectionBoxed"
import {
  getVehicleDetails,
  updateVehicleDetails,
} from "../../services/api/vehicleDetailsService"
import { useAppContext } from "../../contexts/AppContext"
import { vehicleDetails } from "../../types/vehicleDetails"

const validationSchema = yup.object({
  brand: yup.string().required("Brand is required"),
  model: yup.string().required("Model is required"),
  year: yup.string().required("Year is required"),
  odometer: yup.number().required("Kilometers is required"),
  vin: yup.string().required("VIN is required"),
})

interface FormData {
  brand: string
  model: string
  year: string
  odometer: number
  vin: string
}

const defaultValues: FormData = {
  brand: "",
  model: "",
  year: "",
  odometer: 0,
  vin: "",
}

function LoanVehicleDetailsForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeLoan } = useAppContext()

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
  } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(validationSchema as any),
    mode: "onChange",
  })

  const onSubmit = async (data: vehicleDetails) => {
    console.log("Form data:", data)
    try {
      await updateVehicleDetails(activeLoan.message, data)
      navigate(-1)
    } catch (error) {
      console.error("Failed to update vehicle details:", error)
    }
  }

  const onReset = () => {
    reset()
    navigate(-1)
  }

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      try {
        const response = await getVehicleDetails(activeLoan.message)
        if (response.status === 200 && response.message) {
          reset({
            brand: response.message?.brand ?? "",
            model: response.message?.model ?? "",
            year: response.message?.year ?? "",
            vin: response.message?.vin ?? "",
            odometer: response.message?.odometer ?? 0,
          })
        }
      } catch (error) {
        console.error("Failed to fetch vehicle details:", error)
      }
    }
    fetchVehicleDetails()
  }, [activeLoan.message, reset])

  return (
    <CustomSectionBoxed
      classname="c-loan-vehicle-details-section-form"
      headerContentType="title"
      headerTitle="Vehicle Details"
      headerTitleParagraph="Below are the specifications for submitting your vehicle details"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="c-loan-vehicle-details-form"
      >
        <fieldset>
          <legend>
            <span>{t("Vehicle Specifications")}</span>
            <small>
              {t(
                "Please provide the vehicle's brand, model, year, mileage (kilometers), and VIN for a comprehensive assessment.",
              )}
            </small>
          </legend>

          <div className="c-loan-vehicle-details-form-grid">
            <CustomInput labelText="Brand" name="brand" control={control} />

            <CustomInput labelText="Model" name="model" control={control} />

            <CustomInputPattern
              labelText="Year"
              name="year"
              control={control}
              valueIsNumericString
              format="####"
              mask="YYYY"
              placeholder=""
            />

            <CustomNumberInput
              labelText="odometer"
              name="odometer"
              control={control}
              suffix=" KM"
            />

            <CustomInput
              extraClassName="c-form-group-vin"
              labelText="VIN"
              name="vin"
              control={control}
            />
          </div>
        </fieldset>

        <div className="c-button-container">
          <Button text="Cancel" onClick={onReset} />

          <Button
            type="submit"
            text="Save & close"
            btnColor="blue"
            disabled={!isValid || !isDirty}
          />
        </div>
      </form>
    </CustomSectionBoxed>
  )
}

export default LoanVehicleDetailsForm
