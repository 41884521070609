import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { ColumnDef } from "@tanstack/react-table"
import moment from "moment"
import { NumericFormat } from "react-number-format"
import { loanDetails, DashboardLoansTableProps } from "../types/dashboard"
import CustomTable from "./common/CustomTable"

const generateColumns = (
  t: (key: string) => string,
): ColumnDef<loanDetails>[] => {
  return [
    {
      id: "vehicle",
      header: () => <>{t("Vehicle")}</>,
      cell: ({ row }) => {
        return (
          <>
            {row.original.vehicle}
            <small>#{row.original.id}</small>
          </>
        )
      },
    },
    {
      id: "expired",
      header: () => <>{t("Expired on")}</>,
      cell: ({ row }) => {
        return (
          <>
            {row.original.submitted_on === "N/A" ? (
              <>N/A</>
            ) : (
              <time>
                {moment(row.original.submitted_on).format("MMM D, YYYY")}
              </time>
            )}
          </>
        )
      },
    },
    {
      id: "loanAmount",
      header: () => <>{t("Loan amount")}</>,
      cell: ({ row }) => {
        return (
          <NumericFormat
            displayType="text"
            value={row.original.lease_amount || 0}
            prefix="$"
            decimalScale={2}
            thousandSeparator=","
            decimalSeparator="."
            fixedDecimalScale
            valueIsNumericString
          />
        )
      },
    },
  ]
}

function DashboardLoansExpiredTable({ loans }: DashboardLoansTableProps) {
  const { t } = useTranslation()
  const columns = generateColumns(t)

  return (
    <CustomTable
      extraClassName={clsx(
        "is-responsive",
        "c-dashboard-section-table",
        "expired",
      )}
      data={loans}
      columns={columns}
      customDefaultEmpty
      defaultEmptyHeading="No expired loans"
      defaultEmptyTitle="All expired loans will appear here"
    />
  )
}

export default DashboardLoansExpiredTable
