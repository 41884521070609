import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import clsx from "clsx"
import moment from "moment-timezone"
import { useAppContext } from "../contexts/AppContext"
import { IPreApprovalStatusComponent } from "../types/preApproval"
import ButtonLink from "./common/ButtonLink"
import { ReactComponent as IcnChevronRight } from "../assets/icons/icn-chevron-right.svg"

interface FootLinkProps {
  url: string
  text: string
  icon?: ReactNode
}

interface PreApprovalStatusGridProps {
  isSingleColumn?: boolean
  icon: ReactNode
  iconType: "info" | "danger"
  preApprovalStatusData: IPreApprovalStatusComponent
  title: string
  subtitle: string
  footNote?: ReactNode
  footCta?: FootLinkProps | null
}

interface LinkProps {
  url: string
  text: string
}
interface PreApprovalValidationStepsProps {
  status: string
  title: string
  subTitle: ReactNode
  date?: string
  cta?: LinkProps | null
}

const PreApprovalSectionStatusGrid = ({
  isSingleColumn,
  icon,
  iconType,
  title,
  preApprovalStatusData,
  subtitle,
  footNote,
  footCta,
}: PreApprovalStatusGridProps) => {
  const { t } = useTranslation()
  const { userEmail } = useAppContext()

  const formatLocalTime = (timestamp: string) => {
    const cleanedTimestamp = timestamp.replace(/(\d+)(st|nd|rd|th),/, "$1,")

    const utcTime = moment.tz(
      cleanedTimestamp,
      "MMMM Do, YYYY, HH:mm:ss",
      "UTC",
    )

    if (!utcTime.isValid()) {
      console.log(`Invalid EST time: ${cleanedTimestamp}`)
      return "Invalid Date"
    }

    const localTime = utcTime.tz(moment.tz.guess())

    return localTime.format("MMMM Do, YYYY, HH:mm:ss")
  }

  const PreApprovalValidationSteps: PreApprovalValidationStepsProps[] = [
    {
      status: "completed",
      title: "Application submitted",
      subTitle: "Passed AI Beta 2.4 ID Verification",
      date: formatLocalTime(preApprovalStatusData.pre_approval_timestamp),
      cta: null,
    },
    {
      status: "completed",
      title: "Bank verification passed",
      subTitle: "Passed IBV Verification",
      date: formatLocalTime(preApprovalStatusData.ibv_timestamp),
      cta: null,
    },
    {
      status: "pending",
      title: "Account confirmation pending",
      subTitle: (
        <>
          We sent an email to <em>{userEmail}</em> with instructions on how to
          finish setting up your account.
        </>
      ),
      date: "",
      cta: null,
    },
  ]

  return (
    <section className="c-pre-approval-section-status">
      <div
        className={clsx(
          "c-pre-approval-section-status-grid",
          isSingleColumn && "single",
        )}
      >
        <div className="c-pre-approval-section-status-grid-item title">
          <div className="c-pre-approval-section-status-grid-item-title">
            <div className="c-pre-approval-section-status-grid-item-title-header">
              <div className="c-pre-approval-section-status-grid-item-title-header-wrapper">
                <div className="c-pre-approval-section-status-grid-item-title-header-image" />

                <div className="c-pre-approval-section-status-grid-item-title-header-content">
                  <div className="c-pre-approval-section-status-grid-item-title-header-icon">
                    <i className={clsx(iconType)}>{icon}</i>
                  </div>

                  <h1 className="h4">{t(title)}</h1>

                  <p>{t(subtitle)}</p>
                </div>
              </div>
            </div>

            <div className="c-pre-approval-section-status-grid-item-title-footer">
              <p className="c-pre-approval-section-status-grid-item-title-footer-number">
                {t("Application number")}:&nbsp;
                <span>{`#${preApprovalStatusData.preApprovalId}`}</span>
              </p>

              {footNote && (
                <p className="c-pre-approval-section-status-grid-item-title-footer-note">
                  <Trans>{footNote}</Trans>
                </p>
              )}

              {footCta && (
                <div className="c-pre-approval-section-status-grid-item-title-footer-cta">
                  <ButtonLink
                    url={footCta.url}
                    btnVariant="link"
                    btnSize="md"
                    icon={footCta.icon}
                    text={footCta.text}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {!isSingleColumn && (
          <div className="c-pre-approval-section-status-grid-item next-steps">
            <div className="c-pre-approval-section-status-grid-item-next-steps">
              <div className="c-pre-approval-section-status-grid-item-next-steps-header">
                <h2 className="h4">{t("What's next?")}</h2>
              </div>

              <ul className="c-pre-approval-section-status-grid-item-next-steps-list">
                {PreApprovalValidationSteps.map((step, idx) => (
                  <li
                    key={idx}
                    className={clsx(
                      "c-pre-approval-section-status-grid-item-next-steps-list-item",
                      step.status,
                    )}
                  >
                    <span className="c-pre-approval-section-status-grid-item-next-steps-list-item-progress">
                      <i />
                    </span>

                    <div className="c-pre-approval-section-status-grid-item-next-steps-list-item-content">
                      <p className="c-pre-approval-section-status-grid-item-next-steps-list-item-content-title">
                        {t(step.title)}
                      </p>

                      <div className="c-pre-approval-section-status-grid-item-next-steps-list-item-content-subtitle">
                        {step.subTitle}
                      </div>

                      {step.date && <time dateTime="">{step.date}</time>}

                      {step.cta && (
                        <div className="c-pre-approval-section-status-grid-item-next-steps-list-item-content-cta">
                          <ButtonLink
                            url={step.cta.url}
                            btnColor="blue"
                            btnVariant="link"
                            btnSize="md"
                            icon={<IcnChevronRight />}
                            iconPlacement="right"
                            text={step.cta.text}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>

              <div className="c-pre-approval-section-status-grid-item-next-steps-footer">
                <small>
                  <Trans>
                    Haven’t received our email yet? Check your spam folder or
                    for live support, contact us <Link to="#">here</Link>.
                  </Trans>
                </small>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default PreApprovalSectionStatusGrid
function formatLocalTime(ibv_timestamp: string): string | undefined {
  throw new Error("Function not implemented.")
}
