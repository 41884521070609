import clsx from "clsx"
import {useMediaQuery} from "usehooks-ts"
import { useAuth } from "../contexts/AuthContext"
import {responsiveBreakpoints} from "../constant/constants"
import DashboardLayout from "../layouts/DashboardLayout"
import PublicPageLayout from "../layouts/PublicPageLayout"
import CustomSectionBlueBg from "../components/common/CustomSectionBlueBg"
import SupportSection from "../components/SupportSection"
import SupportForm from "../components/forms/SupportForm"
import styles from "../styles/Support.module.scss"

function Support() {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  const { isAuthenticated } = useAuth()

  const sectionHeaderTitle = "How can we help you?"
  const sectionHeaderParagraph = "Use the form below to contact us with any questions you may have"

  return (
    <>
      {isAuthenticated?(
        <DashboardLayout
          pageTitle="Get Support"
          pageClassName={clsx(styles.cPageSupport, "c-page-support", "authenticated")}
          navbarTitle="Get Support"
          navbarHasBackLink={isMobileBreakpoint}
          navbarBackLinkUrl="/dashboard"
          navbarBackLinkText={isMobileBreakpoint? "" : "Dashboard"}
          navbarBackLinkTitle="Back to Dashboard"
          navbarBackLinkVariant="icon"
        >
          <SupportSection/>

        </DashboardLayout>
      ):(
        <PublicPageLayout
          pageTitle="Get Support"
          pageClassName={clsx(styles.cPageSupport, "c-page-support", "unauthenticated")}
        >
          <CustomSectionBlueBg
            extraClassName="c-support"
            title={sectionHeaderTitle}
            paragraph={sectionHeaderParagraph}
          >
            <SupportForm/>
          </CustomSectionBlueBg>
        </PublicPageLayout>
      )}
    </>
  )
}

export default Support
