import React, {ChangeEvent, InputHTMLAttributes, ReactNode} from "react"
import clsx from "clsx"
import {useTranslation} from "react-i18next"
import {Controller} from "react-hook-form"

interface CustomInputPostalCodeProps extends InputHTMLAttributes<HTMLInputElement> {
  extraClassName?: string
  isLabelHidden?: boolean
  name: string
  defaultValue?: any
  placeholder?: string
  control: any
  helpText?: ReactNode
  transformValue?: (value: string) => string
}

function CustomInputPostalCode(
  {
    extraClassName,
    isLabelHidden,
    name,
    defaultValue,
    placeholder,
    helpText,
    control,
    transformValue,
    ...rest
  }: CustomInputPostalCodeProps) {
  const { t } = useTranslation()
  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\s+/g, '')

    if (value.length > 3) {
      value = value.slice(0, 3) + ' ' + value.slice(3)
    }

    return value.toUpperCase()
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <div className={clsx("c-form-group", extraClassName && extraClassName)}>
          <div className="c-form-group-input-wrapper">
            <input
              type="text"
              id={`id_${name}`}
              placeholder={placeholder ? t(placeholder) : " "}
              aria-invalid={!!error}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                }
              }}
              {...field}
              onChange={(e) => {
                const value = handlePostalCodeChange(e)
                field.onChange(transformValue ? transformValue(value) : value)
              }}
              minLength={7}
              maxLength={7}
              {...rest}
            />

            <label
              htmlFor={`id_${name}`}
              className={clsx(isLabelHidden && "c-visually-hidden")}
            >
              {t("Postal code")}
            </label>
          </div>

          {error && (
            <div className="c-form-error-feedback">
              {t(error.message || "")}
            </div>
          )}

          {helpText && <div className="c-form-help-text">{helpText}</div>}
        </div>
      )}
    />
  )
}

export default CustomInputPostalCode