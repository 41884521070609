// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegalPage_cPageLegal__nwoLD.c-page-legal.unauthenticated .c-page-section-content > section {
  flex: unset;
}
.LegalPage_cPageLegal__nwoLD.c-page-legal.unauthenticated .c-legal .c-section-blue-bg-content {
  max-height: 56.875em;
  overflow-y: auto;
}
.LegalPage_cPageLegal__nwoLD.c-page-legal.unauthenticated .c-legal .c-section-blue-bg-content-wrapper {
  padding-bottom: var(--px64);
}
@media (max-width: 991px) {
  .LegalPage_cPageLegal__nwoLD.c-page-legal.unauthenticated .c-legal .c-section-blue-bg-content {
    max-height: 25.625em;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/LegalPage.module.scss"],"names":[],"mappings":"AAKU;EACE,WAAA;AAJZ;AASU;EACE,oBAAA;EACA,gBAAA;AAPZ;AASY;EACE,2BAAA;AAPd;AAWU;EACE;IACE,oBAAA;EATZ;AACF","sourcesContent":[".cPageLegal {\n  &:global {\n    &.c-page-legal {\n      &.unauthenticated {\n        .c-page-section-content {\n          > section {\n            flex: unset;\n          }\n        }\n\n        .c-legal {\n          .c-section-blue-bg-content {\n            max-height: 56.875em; //910px\n            overflow-y: auto;\n\n            &-wrapper {\n              padding-bottom: var(--px64);\n            }\n          }\n\n          @media(max-width: 991px) {\n            .c-section-blue-bg-content {\n              max-height: 25.625em; //410px\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cPageLegal": `LegalPage_cPageLegal__nwoLD`
};
export default ___CSS_LOADER_EXPORT___;
