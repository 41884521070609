import {useTranslation} from "react-i18next"
import clsx from "clsx"

interface CopyrightProps {
  extraClassName?: string;
}

function Copyright({extraClassName}:CopyrightProps) {
  const { t } = useTranslation()
  return (
    <span className={clsx("c-copyright", extraClassName && extraClassName)}>
      © 2024 Leasyfi {t("All rights reserved")}
    </span>
  )
}

export default Copyright
