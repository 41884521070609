import React from "react"
import ButtonLink from "./ButtonLink"
import {ReactComponent as IcnChevronLeft} from "../../assets/icons/icn-chevron-left.svg"
import clsx from "clsx"
interface BackButtonProps {
  extraClassName?: string;
  backLinkUrl?: string;
  backLinkText?: string;
  backLinkTitle?: string;
  backLinkVariant? : "link" | "icon"
  backLinkSize?: "md" | "sm" | "xs"
}

function BackButton(
  {
    extraClassName,
    backLinkUrl,
    backLinkTitle,
    backLinkText,
    backLinkVariant,
    backLinkSize,
  }: BackButtonProps) {
  return (
    <ButtonLink
      extraClassName={clsx("c-back-link-button", extraClassName && extraClassName)}
      url={backLinkUrl || "/"}
      title={backLinkTitle}
      text={backLinkText}
      btnVariant={backLinkVariant || "link"}
      btnColor="ghost"
      btnSize={backLinkSize || "md"}
      iconSize="sm"
      icon={<IcnChevronLeft/>}
    />
  )
}

export default BackButton
