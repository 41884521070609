import { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useAppContext } from "../../contexts/AppContext"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { loginUser } from "../../services/api/userService"
import { loginCredentials } from "../../types/user"
import { getCookie } from "../../utils/utils"
import CustomInput from "../form-elements/CustomInput"
import CustomInputPasswordShowHide from "../form-elements/CustomInputPasswordShowHide"
import CustomInputRadioCheckbox from "../form-elements/CustomInputRadioCheckbox"
import ButtonLink from "../common/ButtonLink"
import Button from "../common/Button"
import { useAuth } from "../../contexts/AuthContext"
import CustomAlert from "../common/CustomAlert"

interface FormData {
  email: string
  password: string
}

const defaultValues: FormData = {
  email: "",
  password: "",
}

const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
})

function LoginForm() {
  const { setUserEmail } = useAppContext()
  const { login } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loginError, setLoginError] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { isValid },
    // setError,
  } = useForm<FormData>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (formData: FormData) => {
    const apiData: loginCredentials = {
      email: formData.email,
      password: formData.password,
    }

    try {
      setLoginError(false)
      await loginUser(apiData)
      const userID = getCookie("user_id")
      login(userID)
      setUserEmail(apiData.email)
      navigate("/dashboard")
    } catch (error) {
      setLoginError(true)
      console.error("Login error", error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loginError && (
        <CustomAlert
          extraClassName="c-form-alert"
          alertType="danger"
          alertText="Incorrect email or password"
        />
      )}

      <fieldset>
        <legend className="c-visually-hidden">
          {t("Enter email and password")}
        </legend>

        <CustomInput labelText="Email" name="email" control={control} />

        <CustomInputPasswordShowHide
          labelText="Password"
          name="password"
          control={control}
        />
      </fieldset>

      <div className="c-flexbox">
        <CustomInputRadioCheckbox
          type="checkbox"
          labelText="Remember me"
          id="id_remember"
          name="remember"
          control={control}
        />

        <ButtonLink
          url="/forgot-password"
          btnVariant="link"
          btnSize="md"
          btnColor="blue"
          text="Forgot password"
        />
      </div>

      <Button type="submit" btnColor="blue" text="Login" disabled={!isValid} />
    </form>
  )
}

export default LoginForm
