import { useEffect } from "react"
import * as Sentry from "@sentry/react"
import { BrowserRouter } from "react-router-dom"
import AppRoutes from "./Routes"
import SpinnerOverlay from "./components/SpinnerOverlay"
import { toast, ToastContainer } from "react-toastify"
import { LanguageProvider, useLanguage } from "./contexts/LanguageContext"
import { AuthProvider } from "./contexts/AuthContext"
import { AppProvider } from "./contexts/AppContext"
import "react-toastify/dist/ReactToastify.css"

const isLocalhost = window.location.hostname === "localhost"

if (!isLocalhost) {
  Sentry.init({
    dsn: "https://d705d1d63450d95493547750fc5b74d7@o68125.ingest.us.sentry.io/4507426485370880",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysOnErrorSampleRate: 1.0,
  })
}

const App = () => {
  const { setLanguage } = useLanguage()

  useEffect(() => {
    const browserLanguage = navigator.language.split("-")[0]
    const supportedLanguages = ["en", "fr"]
    if (supportedLanguages.includes(browserLanguage)) {
      setLanguage(browserLanguage)
    }
  }, [setLanguage])

  useEffect(() => {
    const badCredentialsError = (event: any) => {
      toast.error(`Email or password are incorrect`)
    }
    const handleUnauthorizedError = (event: any) => {
      toast.error(`${event.detail} Something went wrong! Please try again.`)
    }

    const handleUnsubscribedError = (event: any) => {
      toast.error(`${event.detail} Something went wrong! Please try again.`)
    }

    const handleGeneralError = (event: any) => {
      toast.error(`${event.detail} Something went wrong! Please try again.`)
    }

    window.addEventListener("bad-credentials-error", badCredentialsError)
    window.addEventListener("unauthorized-error", handleUnauthorizedError)
    window.addEventListener("unsubscibed-error", handleUnsubscribedError)
    window.addEventListener("notfound-error", handleUnsubscribedError)
    window.addEventListener("methodNotAllowed-error", handleUnsubscribedError)
    window.addEventListener("alreadyExists-error", handleUnsubscribedError)
    window.addEventListener("contentTooLarge-error", handleUnsubscribedError)
    window.addEventListener("invalidQuery-error", handleUnsubscribedError)
    window.addEventListener("internalServer-error", handleUnsubscribedError)
    window.addEventListener("serverDown-error", handleUnsubscribedError)
    window.addEventListener("general-error", handleGeneralError)

    return () => {
      window.removeEventListener("unauthorized-error", handleUnauthorizedError)
      window.removeEventListener("unsubscibed-error", handleUnsubscribedError)
      window.removeEventListener("notfound-error", handleUnsubscribedError)
      window.removeEventListener(
        "methodNotAllowed-error",
        handleUnsubscribedError,
      )
      window.removeEventListener("alreadyExists-error", handleUnsubscribedError)
      window.removeEventListener(
        "contentTooLarge-error",
        handleUnsubscribedError,
      )
      window.removeEventListener("invalidQuery-error", handleUnsubscribedError)
      window.removeEventListener(
        "internalServer-error",
        handleUnsubscribedError,
      )
      window.removeEventListener("serverDown-error", handleUnsubscribedError)
      window.removeEventListener("general-error", handleGeneralError)
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <AuthProvider>
        <BrowserRouter>
          <AppProvider>
            <LanguageProvider>
              <SpinnerOverlay />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <AppRoutes />
            </LanguageProvider>
          </AppProvider>
        </BrowserRouter>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
