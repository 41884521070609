import {ReactNode, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import clsx from "clsx"
import styles from "../../styles/CustomTabs.module.scss"

interface CustomTabProps {
  id: string;
  name: string;
  component: ReactNode;
}

interface CustomTabsProps {
  extraClassName?: string;
  tabs?: CustomTabProps[];
  initialTabIndex?: number;
}

function CustomTabs(
  {
    extraClassName,
    tabs,
    initialTabIndex
  }: CustomTabsProps) {
  const { t } = useTranslation()

  const initialActiveTab = initialTabIndex !== undefined && tabs
    ? tabs[initialTabIndex]?.id
    : tabs?.length
      ? tabs[0].id
      : ''

  const [activeTab, setActiveTab] = useState(initialActiveTab)

  useEffect(() => {
    if (initialTabIndex !== undefined && tabs && tabs[initialTabIndex]) {
      setActiveTab(tabs[initialTabIndex].id)
    }
  }, [initialTabIndex, tabs])

  const handleChange = (tabId: string) => {
    setActiveTab(tabId)
  }

  return (
    <div className={clsx(styles.cTabs, "c-tabs", extraClassName && extraClassName)}>

      <ul className="c-tabs-tab-list">

        {tabs?.map((tab) => (

          <li key={tab.id}  className={clsx("c-tabs-tab-list-item", activeTab === tab.id && 'is-active')}>

            <button
              className={clsx("c-tabs-tab-list-item-btn", tab.id === activeTab && "is-active")}
              title={t(tab.name)}
              onClick={() => handleChange(tab.id)}
            >
              {t(tab.name)}
            </button>

          </li>
        ))}

      </ul>

      <div className="c-tabs-tab-content">
        {tabs?.find((tab) => tab.id === activeTab)?.component}
      </div>

    </div>
  )
}

export default CustomTabs