import { useTranslation } from "react-i18next"
import CustomInputPattern from "../../form-elements/CustomInputPattern"
import Button from "../../common/Button"

interface FormStep6Props {
  control: any
}

function FormStep6({ control }: FormStep6Props) {
  const { t } = useTranslation()

  return (
    <fieldset className="c-fieldset">
      <legend>
        {t("We sent a code to your phone number, please input the code below.")}
      </legend>

      <CustomInputPattern
        labelText="SMS Code"
        name="smsValidationCode"
        placeholder=" "
        helpText={t("Note: This code expires in 5 minutes.")}
        control={control}
        format={"######"}
      />

      <Button btnVariant="link" btnColor="blue" text="Resend code" />
    </fieldset>
  )
}

export default FormStep6
