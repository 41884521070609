import { useLocation } from "react-router-dom"
import clsx from "clsx"
import { useMediaQuery } from "usehooks-ts"
import {useAppContext} from "../contexts/AppContext"
import { responsiveBreakpoints } from "../constant/constants"
import { LoanLayoutProps } from "../types/types"
import DashboardLayout from "./DashboardLayout"
import styles from "../styles/LoanLayout.module.scss"

function LoanPendingLayout(
  {
    subPageTitle,
    subPageExtraClassName,
    sectionExtraClassName,
    children,
  }: LoanLayoutProps) {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  const location = useLocation()
  const isLoanPendingPage = location.pathname === `/loan/pending`

  const {activeLoan } = useAppContext()
  const id = activeLoan.message

  return (
    <DashboardLayout
      pageClassName={clsx(styles.cPageLoan, "c-page-loan",!isLoanPendingPage && "subpage", subPageExtraClassName && subPageExtraClassName)}
      pageTitle={isLoanPendingPage? `Loan | ${id}` : `Loan | ${id} | ${subPageTitle}`}

      navbarHasBackLink={isLoanPendingPage && isMobileBreakpoint ? true : !isLoanPendingPage}
      navbarBackLinkUrl={isLoanPendingPage && isMobileBreakpoint ? '/dashboard': `/loan/pending`}
      navbarBackLinkText={isLoanPendingPage && isMobileBreakpoint ? "" : `Return to loan overview / ${id}`}
      navbarBackLinkTitle={isLoanPendingPage? "Back to Dashboard" : `Back to ${id}`}
      navbarBackLinkVariant={isLoanPendingPage && isMobileBreakpoint? "icon" : "link"}
      navbarTitle={isLoanPendingPage ? `${id}` : ""}
    >
      <section className={clsx("c-loan", sectionExtraClassName)}>
        {children}
      </section>

    </DashboardLayout>
  )
}

export default LoanPendingLayout