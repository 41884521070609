import LoanNewLayout from "../layouts/LoanNewLayout"
import LoanContent from "../components/LoanContent"
import clsx from "clsx"
import styles from "../styles/Loan.module.scss"

function LoanNew() {
  return (
    <LoanNewLayout sectionExtraClassName={clsx(styles.cLoanMain,"c-loan-main", "c-loan-new")}>

      <LoanContent/>

    </LoanNewLayout>
  )
}

export default LoanNew