import { useTranslation } from "react-i18next"
import CustomInputPattern from "../../form-elements/CustomInputPattern"
import CustomInput from "../../form-elements/CustomInput"
import CustomAlert from "../../common/CustomAlert"

interface FormStep5Props {
  control: any
  emailInUse: boolean
}

function FormStep5({ control, emailInUse }: FormStep5Props) {
  const { t } = useTranslation()

  return (
    <fieldset className="c-fieldset contact-details">
      <legend>{t("Alright, now how can we reach you?")}</legend>

      {emailInUse && (
        <CustomAlert alertType="danger" alertText="User account already exists."/>
      )}

      <div className="c-fieldset-form-grid">
        <CustomInput
          labelText="Email"
          type="email"
          name="email"
          aria-invalid={emailInUse}
          helpText={t( "Note: We'll need a valid email that you can verify immediately.")}
          control={control}
        />

        <CustomInputPattern
          labelText="Mobile Number"
          id="id_mobileNumber"
          name="mobileNumber"
          placeholder=" "
          helpText={t(
            "Note: We'll need a valid phone number that you can verify immediately.",
          )}
          control={control}
          format={"+1 (###) ### ####"}
        />
      </div>
    </fieldset>
  )
}

export default FormStep5
