import clsx from "clsx"
import {useMediaQuery} from "usehooks-ts"
import { useAuth } from "../contexts/AuthContext"
import {responsiveBreakpoints} from "../constant/constants"
import DashboardLayout from "../layouts/DashboardLayout"
import PublicPageLayout from "../layouts/PublicPageLayout"
import CustomSectionBoxed from "../components/common/CustomSectionBoxed"
import CustomSectionBlueBg from "../components/common/CustomSectionBlueBg"
import TermsContent from "../components/TermsContent"
import styles from "../styles/LegalPage.module.scss"

function Terms() {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const { isAuthenticated } = useAuth()

  const sectionHeaderTitle = "Terms & Conditions"
  const sectionHeaderParagraph = "Please review and accept the terms and conditions below to proceed with your application"

  return (
    <>
      {isAuthenticated?(
        <DashboardLayout
          pageTitle="Terms & Conditions"
          pageClassName={clsx(styles.cPageLegal, "c-page-legal", "authenticated", "terms")}
          navbarTitle="Terms & Conditions"
          navbarHasBackLink={isMobileBreakpoint}
          navbarBackLinkUrl="/"
          navbarBackLinkText={isMobileBreakpoint ? "" : "Dashboard"}
          navbarBackLinkVariant="icon"
        >
          <CustomSectionBoxed
            classname="c-legal"
            headerContentType="title"
            headerTitle={sectionHeaderTitle}
            headerTitleParagraph={sectionHeaderParagraph}
          >
            <TermsContent/>
          </CustomSectionBoxed>

        </DashboardLayout>

      ):(
        <PublicPageLayout
          pageClassName={clsx(styles.cPageLegal, "c-page-legal", "unauthenticated", "terms")}
          pageTitle="Terms & Conditions"
        >
          <CustomSectionBlueBg
            extraClassName="c-legal"
            title={sectionHeaderTitle}
            paragraph={sectionHeaderParagraph}
          >
            <TermsContent/>
          </CustomSectionBlueBg>

        </PublicPageLayout>
      )}
    </>
  )
}

export default Terms