import { axiosClient } from "./axiosClient"
import {
  vehicleDetailsResponse,
  vehicleDetails,
} from "../../types/vehicleDetails"
import { BasicApiResponse } from "../../types/types"
import { fetch } from "./fetchWrapper"

export const getVehicleDetails = async (
  lease_id: string,
  cookies?: string[],
): Promise<vehicleDetailsResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, vehicleDetailsResponse>({
    endpoint: "/get-lease-vehicle-information",
    method: "get",
    headers,
    options: {
      params: { lease_id },
    },
  })

  return response.data
}

export const updateVehicleDetails = async (
  lease_id: string,
  details: vehicleDetails,
  cookies?: string[],
): Promise<BasicApiResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<vehicleDetails, BasicApiResponse>({
    endpoint: "/update-lease-vehicle-information",
    method: "post",
    payload: details,
    headers,
    options: {
      params: { lease_id },
    },
  })

  return response.data
}
