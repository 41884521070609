import clsx from "clsx"
import LoanNewLayout from "../layouts/LoanNewLayout"
import LoanConsentForm from "../components/forms/LoanConsentForm"
import styles from "../styles/LoanSubpageConsent.module.scss"

function LoanPendingConsent() {

  return (
    <LoanNewLayout
      subPageTitle="Consent for Equifax Credit Check"
      subPageExtraClassName={clsx(styles.cPageConsent, "c-page-consent")}
      sectionExtraClassName="c-loan-consent"
    >
      <LoanConsentForm/>

    </LoanNewLayout>
  )
}

export default LoanPendingConsent