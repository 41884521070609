// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form-content {
  padding-bottom: calc(var(--px32) - var(--px8));
}
.LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form fieldset {
  margin-bottom: var(--px24);
}
.LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form .c-form-group {
  max-width: var(--px312);
  margin-bottom: 0;
}
.LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form-total {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1em;
  width: 100%;
  max-width: var(--px312);
  padding: var(--px12);
  border-radius: var(--px4);
  letter-spacing: -0.01em;
}
.LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form-total-label {
  font-weight: 500;
}
.LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form-total-value {
  color: var(--medium-grey);
}
.LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form .c-button-container {
  --btn-container-justify: flex-end;
}
@media (max-width: 991px) {
  .LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form legend {
    margin-bottom: 0.666em;
  }
  .LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form fieldset:first-child {
    margin-bottom: 1em;
  }
  .LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS .c-loan-lease-details-section-form .c-loan-lease-details-form fieldset:nth-child(2) {
    margin-bottom: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/LoanSubpageLeaseValue.module.scss"],"names":[],"mappings":"AAIM;EACE,8CAAA;AAHR;AAOQ;EACE,0BAAA;AALV;AAQQ;EACE,uBAAA;EACA,gBAAA;AANV;AASQ;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EACA,WAAA;EACA,uBAAA;EACA,oBAAA;EACA,yBAAA;EACA,uBAAA;AAPV;AASU;EACE,gBAAA;AAPZ;AAUU;EACE,yBAAA;AARZ;AAYQ;EACE,iCAAA;AAVV;AAcM;EAEI;IACE,sBAAA;EAbV;EAiBU;IACE,kBAAA;EAfZ;EAkBU;IACE,kBAAA;EAhBZ;AACF","sourcesContent":[".cPageLeaseDetails {\n  :global {\n    .c-loan-lease-details-section-form {\n\n      &-content {\n        padding-bottom: calc(var(--px32) - var(--px8));\n      }\n\n     .c-loan-lease-details-form {\n        fieldset {\n          margin-bottom: var(--px24);\n        }\n\n        .c-form-group {\n          max-width: var(--px312);\n          margin-bottom: 0;\n        }\n\n        &-total {\n          display: flex;\n          align-items: center;\n          flex-wrap: wrap;\n          column-gap: 1em;\n          width: 100%;\n          max-width: var(--px312);\n          padding: var(--px12);\n          border-radius: var(--px4);\n          letter-spacing: -0.01em;\n\n          &-label {\n            font-weight: 500;\n          }\n\n          &-value {\n            color : var(--medium-grey)\n          }\n        }\n\n        .c-button-container {\n          --btn-container-justify: flex-end;\n        }\n      }\n\n      @media (max-width: 991px) {\n        .c-loan-lease-details-form {\n          legend {\n            margin-bottom: 0.666em; //16px @24px\n          }\n\n          fieldset {\n            &:first-child {\n              margin-bottom: 1em;\n            }\n\n            &:nth-child(2) {\n              margin-bottom: 1em;\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cPageLeaseDetails": `LoanSubpageLeaseValue_cPageLeaseDetails__1kMhS`
};
export default ___CSS_LOADER_EXPORT___;
