// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomSectionBoxed_cSectionBoxed__-sEtH.c-section-boxed {
  display: flex;
  flex-direction: column;
  padding: var(--px8);
  background-color: var(--body-bg-color);
  border: var(--border);
  border-radius: var(--px8);
  width: 100%;
  max-width: 100%;
}
.CustomSectionBoxed_cSectionBoxed__-sEtH.c-section-boxed .c-section-boxed-header {
  padding: var(--px24);
  background-color: var(--light-grey);
  border-radius: var(--px5);
}
.CustomSectionBoxed_cSectionBoxed__-sEtH.c-section-boxed .c-section-boxed-header h2:not(.c-subheading) {
  margin-bottom: 0.166em;
}
.CustomSectionBoxed_cSectionBoxed__-sEtH.c-section-boxed .c-section-boxed-header small {
  display: block;
  font-size: var(--px12);
  line-height: 1.2;
  color: var(--medium-grey);
}
.CustomSectionBoxed_cSectionBoxed__-sEtH.c-section-boxed .c-section-boxed-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 1em 1em var(--px24);
}
.CustomSectionBoxed_cSectionBoxed__-sEtH.c-section-boxed .c-section-boxed-content-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .CustomSectionBoxed_cSectionBoxed__-sEtH.c-section-boxed .c-section-boxed-header {
    padding: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/CustomSectionBoxed.module.scss"],"names":[],"mappings":"AAEI;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,sCAAA;EACA,qBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;AADN;AAGM;EACE,oBAAA;EACA,mCAAA;EACA,yBAAA;AADR;AAGQ;EACE,sBAAA;AADV;AAIQ;EACE,cAAA;EACA,sBAAA;EACA,gBAAA;EACA,yBAAA;AAFV;AAMM;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,4BAAA;AAJR;AAMQ;EACE,SAAA;EACA,aAAA;EACA,sBAAA;AAJV;AAQM;EACE;IACE,YAAA;EANR;AACF","sourcesContent":[".cSectionBoxed {\n  &:global {\n    &.c-section-boxed {\n      display: flex;\n      flex-direction: column;\n      padding: var(--px8);\n      background-color: var(--body-bg-color);\n      border: var(--border);\n      border-radius: var(--px8);\n      width: 100%;\n      max-width: 100%;\n\n      .c-section-boxed-header {\n        padding: var(--px24);\n        background-color: var(--light-grey);\n        border-radius: var(--px5);\n\n        h2:not(.c-subheading) {\n          margin-bottom: 0.166em;// 4px @24px\n        }\n\n        small {\n          display: block;\n          font-size: var(--px12);\n          line-height: 1.2;\n          color: var(--medium-grey);\n        }\n      }\n\n      .c-section-boxed-content {\n        flex: 1;\n        display: flex;\n        flex-direction: column;\n        padding: 1em 1em var(--px24);\n\n        &-wrapper {\n          flex: 1;\n          display: flex;\n          flex-direction: column;\n        }\n      }\n\n      @media (max-width: 991px) {\n        .c-section-boxed-header {\n          padding: 1em;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cSectionBoxed": `CustomSectionBoxed_cSectionBoxed__-sEtH`
};
export default ___CSS_LOADER_EXPORT___;
