import { Triangle } from "react-loader-spinner"
import { useAppContext } from "../contexts/AppContext"

const SpinnerBackground = () => {
  return (
    <div
      className="spinner-overlay-container"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 500,
        background: "rgba(255,255,255, 0.5)",
        backdropFilter: "blur(8px)",
      }}
    >
      <style>
        {`
          .spinner-overlay-container svg path {
            stroke-width: 20px; /* Target SVG paths within the container directly */
          }
        `}
      </style>
      <Triangle
        visible={true}
        height="20"
        width="20"
        color="#00A4FF"
        ariaLabel="triangle-loading"
        wrapperStyle={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vh",
        }}
        wrapperClass="spinner-svg-stroke"
      />
    </div>
  )
}

const SpinnerOverlay = () => {
  const { isLoading } = useAppContext()
  return isLoading ? <SpinnerBackground /> : <></>
}

export default SpinnerOverlay
