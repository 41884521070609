import clsx from "clsx"
import PreApprovalLayout from "../layouts/PreApprovalLayout"
import PreApprovalSectionStatusGrid from "../components/PreApprovalSectionStatusGrid"
import { ReactComponent as IcnPending } from "../assets/icons/icn-loading.svg"
import styles from "../styles/PreApprovalStatus.module.scss"
import { IPreApprovalStatusComponent } from "../types/preApproval"

const PreApprovalStatusPending = (props: IPreApprovalStatusComponent) => {
  return (
    <PreApprovalLayout
      pageExtraClassName={clsx(
        styles.cPreApprovalStatus,
        "c-page-pre-approval-status",
        "pending",
      )}
      pageExtraTitle="Pending"
      navbarUserFirstName={props.firstName}
      navbarBadgeType="default"
      navbarBadgeText="Pending"
    >
      <PreApprovalSectionStatusGrid
        icon={<IcnPending />}
        iconType="info"
        preApprovalStatusData={props}
        title="Pre-Approval under review"
        subtitle="Your pre-approval application is under review. The Leasyfi team will be in contact with you shortly."
      />
    </PreApprovalLayout>
  )
}

export default PreApprovalStatusPending
