import {useTranslation} from 'react-i18next'
import CustomInput from '../../form-elements/CustomInput'

interface FormStep0Props {
  control: any;
}

function FormStep0({control}: FormStep0Props) {
  const { t } = useTranslation()

  return (
    <fieldset className="c-fieldset personal-info">

      <legend>
        {t("Tell us, what should we call you?")}
      </legend>


      <CustomInput
        labelText="Legal First Name"
        name="firstName"
        control={control}
        helpText={t("Note: Please provide your legal first name as indicated on any government provided identification.")}
      />

      <CustomInput
        labelText="Legal Last Name"
        name="lastName"
        control={control}
        helpText={t("Note: Please provide your legal last name as indicated on any government provided identification.")}
      />

    </fieldset>
  )
}

export default FormStep0