import {useState} from "react"
import clsx from "clsx"
import {useTranslation} from "react-i18next"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import Button from "../common/Button"
import {ReactComponent as IcnShow} from "../../assets/icons/icn-password-show.svg"
import {ReactComponent as IcnHide} from "../../assets/icons/icn-password-hide.svg"
import {ReactComponent as IcnValid} from "../../assets/icons/icn-check.svg"
import {ReactComponent as IcnInvalid} from "../../assets/icons/icn-close.svg"

interface FormData {
  new_password: string;
}

const defaultValues: FormData = {
  new_password: "",
}

const validationSchema = yup.object({
  new_password: yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one symbol')
    .matches(/\d/, 'Password must contain at least one number'),
})

function ResetPasswordForm() {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  const {
    register,
    watch,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues,
    criteriaMode: "all",
    mode: "onChange",
    resolver: yupResolver(validationSchema)
  })

  const password = watch("new_password")
  const isPasswordModified = !!dirtyFields.new_password

  const onSubmit = (data: FormData) => console.log(data)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleBlur = () => {
    setShowPassword(false)
  }

  const hasMinLength = password.length >= 8
  const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password)
  const hasNumber = /\d/.test(password)

  const getPasswordValidationClass = (condition: boolean) =>
    !isPasswordModified ? "" : condition ? "valid" : "error"

  const getPasswordValidationIcon = (condition: boolean) =>
    !isPasswordModified ? "" : condition ? <IcnValid /> : <IcnInvalid />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <fieldset>

        <legend className="c-visually-hidden">
          {t("Create a new password")}
        </legend>

        <div className={clsx("c-form-group", "c-form-group-password")}>

          <div className="c-form-group-input-wrapper">

            <input
              type={showPassword ? "text" : "password"}
              id="id_password"
              placeholder=""
              {...register("new_password", { required: true })}
              aria-invalid={!!errors.new_password }
              onBlur={handleBlur}
            />

            <Button
              extraClassName="show-hide-password-btn"
              btnVariant="icon"
              btnColor="ghost"
              title={showPassword ? "Hide password" : "Show Password"}
              icon={showPassword ? <IcnShow/> : <IcnHide/>}
              onClick={toggleShowPassword}
            />

            <label htmlFor="id_password">
              {t("Create new password")}
            </label>

          </div>

          <div className="c-form-help-text">

            <ul>

              <li className={clsx(getPasswordValidationClass(hasMinLength))}>

                <i>
                  {getPasswordValidationIcon(hasMinLength)}
                </i>

                <span>
                  {t("Minimum 8 characters")}
                </span>

              </li>

              <li className={clsx(getPasswordValidationClass(hasSymbol))}>

                <i>
                  {getPasswordValidationIcon(hasSymbol)}
                </i>

                <span>
                  {t("At least 1 symbol")}
                </span>

              </li>

              <li className={clsx(getPasswordValidationClass(hasNumber))}>

                <i>
                  {getPasswordValidationIcon(hasNumber)}
                </i>

                <span>
                  {t("At least 1 number")}
                </span>

              </li>

            </ul>

          </div>

        </div>

      </fieldset>

      <Button
        type="submit"
        btnColor="blue"
        text="Set new password"
        disabled={!isValid}
      />

    </form>
  )
}

export default ResetPasswordForm