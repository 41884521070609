import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "usehooks-ts"
import { responsiveBreakpoints } from "../constant/constants"
import { useAppContext } from "../contexts/AppContext"
import { useEffect, useState } from "react"
import { leaseOverview } from "../types/dashboard"
import { getLeaseOverview } from "../services/api/dashboardService"
import { submitLease } from "../services/api/leaseSubmissionService"
import { NumericFormat } from "react-number-format"
import { formatPercentage } from "../utils/utils"
import CustomSectionBoxed from "./common/CustomSectionBoxed"
import LoanInlineConsentForm from "./forms/LoanInlineConsentForm"
import CustomBadge from "./common/CustomBadge"
import ButtonLink from "./common/ButtonLink"
import Button from "./common/Button"
import { ReactComponent as IcnChevronRight } from "../assets/icons/icn-chevron-right.svg"

function getBadgeProperties(status: string | null) {
  let badgeType: "success" | "warning" | "danger" | "default" | "info",
    badgeText: string
  switch (status) {
    case "completed":
      badgeType = "success"
      badgeText = "Completed"
      break
    case "partially completed":
      badgeType = "warning"
      badgeText = "Partially completed"
      break
    default:
      badgeType = "default"
      badgeText = "To do"
  }
  return { badgeType, badgeText }
}

function LoanContent() {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  const { fullName, activeLoan, address } = useAppContext()
  const [formComplete, setFormComplete] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [leaseOverviewData, setLeaseOverviewData] = useState<
    leaseOverview | undefined
  >(undefined)

  useEffect(() => {
    const fetchLeaseOverview = async () => {
      try {
        const overview = await getLeaseOverview(activeLoan.message)
        setLeaseOverviewData(overview.message)
      } catch (error) {
        console.error("Error fetching lease overview:", error)
      }
    }

    fetchLeaseOverview()
    setIsSubmitted(leaseOverviewData?.lease_progression_status === "Submitted")
  }, [
    activeLoan.message,
    isSubmitted,
    leaseOverviewData?.lease_progression_status,
  ])

  const subPageList = [
    {
      id: "vehicle-details",
      name: "Vehicle Details",
      url: "vehicle-information",
      status:
        leaseOverviewData?.vehicle_details_status.toLowerCase() || "to do",
    },
    {
      id: "lease-details",
      name: "Lease Details",
      url: "lease-value",
      status: leaseOverviewData?.lease_details_status.toLowerCase() || "to do",
    },
    {
      id: "leasing-options",
      name: "Leasing Options",
      url: "leasing-options",
      status: leaseOverviewData?.leasing_option.toLowerCase() || "to do",
    },
    {
      id: "consent",
      name: "Read Equifax Consent",
      url: "consent",
      status: leaseOverviewData?.equifax_consent.toLowerCase() || "to do",
    },
    {
      id: "documents",
      name: "Additional Documents",
      url: "additional-documents",
      status: leaseOverviewData?.lease_docs.toLowerCase() || "to do",
    },
  ]

  const getFormattedMonetaryValue = (
    leaseOverviewItem: string | number,
  ): JSX.Element => {
    return typeof leaseOverviewItem === "number" ? (
      <NumericFormat
        displayType="text"
        value={leaseOverviewItem || 0}
        prefix="$"
        decimalScale={2}
        thousandSeparator=","
        decimalSeparator="."
        fixedDecimalScale
        valueIsNumericString
      />
    ) : (
      <>{leaseOverviewItem}</>
    )
  }

  const getFormatterLoanRate = (): JSX.Element => {
    return typeof leaseOverviewData?.interest_rate === "number" ? (
      <>{formatPercentage(leaseOverviewData?.interest_rate)}</>
    ) : (
      <>{leaseOverviewData?.interest_rate}</>
    )
  }

  const onSubmit = () => {
    const onSubmitLease = async () => {
      try {
        await submitLease(activeLoan.message)
        setIsSubmitted(true)
      } catch (e: any) {
        console.log(e)
      }
    }
    onSubmitLease()
  }

  useEffect(() => {
    const isAllCompletedExceptDocuments = (): boolean => {
      return subPageList.every((subpage) => {
        return subpage.id === "documents" || subpage.status === "completed"
      })
    }
    setFormComplete(isAllCompletedExceptDocuments())
  }, [subPageList])

  return (
    <>
      <CustomSectionBoxed
        classname="c-loan-main-section-details"
        headerContentType="custom"
        headerContent={
          <>
            <div className="c-loan-main-section-details-header-title c-section-boxed-header-title">
              {/* <h2 className="c-subheading">{t("Application progress")}</h2>

              <ul className="c-loan-main-section-details-header-title-list">
                <li className="success">
                  <span>{t("Application submitted")}</span>
                </li>

                <li className="failed">
                  <span>{t("Failed")}</span>
                </li>

                <li className="pending">
                  <span>{t("Pending additional documents...")}</span>
                </li>
        </ul> **/}
            </div>

            <Button
              type="submit"
              btnColor="blue"
              text="Submit application"
              onClick={onSubmit}
              disabled={!formComplete || isSubmitted}
            />
          </>
        }
      >
        <div className="c-loan-main-section-details-grid">
          <article className="c-loan-main-section-details-grid-item">
            <p className="c-subheading">{t("Lease approval terms")}</p>

            <small>
              {t(
                "Below are the terms set out by you when submitting your information for this loan.",
              )}
            </small>
          </article>

          <article className="c-loan-main-section-details-grid-item">
            <p className="c-subheading">{t("Individual")}</p>

            <small>{fullName}</small>
          </article>

          <article className="c-loan-main-section-details-grid-item">
            <p className="c-subheading">{t("Address")}</p>

            <small>{address}</small>
          </article>

          <article className="c-loan-main-section-details-grid-item">
            <p className="c-subheading">{t("Loan amount")}</p>

            <small>
              {leaseOverviewData?.lease_amount &&
                getFormattedMonetaryValue(leaseOverviewData?.lease_amount)}
            </small>
          </article>

          <article className="c-loan-main-section-details-grid-item">
            <p className="c-subheading">{t("Est. Monthly Payment")}</p>

            <small>
              {leaseOverviewData?.monthly_payments &&
                getFormattedMonetaryValue(leaseOverviewData?.monthly_payments)}
            </small>
          </article>

          <article className="c-loan-main-section-details-grid-item">
            <p className="c-subheading">{t("Est. Interest Payment")}</p>

            <small>
              {leaseOverviewData?.interest_rate && getFormatterLoanRate()}
            </small>
          </article>

          <article className="c-loan-main-section-details-grid-item">
            <p className="c-subheading">{t("Term")}</p>

            <small>{leaseOverviewData?.lease_term}</small>
          </article>
        </div>
      </CustomSectionBoxed>

      <ul className="c-loan-main-section-list">
        {subPageList.map((subpage, subPageIdx) => {
          const { badgeType, badgeText } = getBadgeProperties(subpage.status)

          return (
            <li key={subPageIdx} className="c-loan-main-section-list-item">
              <article>
                <Link
                  to={`${subpage.url}`}
                  title={`Open ${subpage.name}`}
                  className="h6 c-loan-main-section-list-item-title"
                >
                  {t(subpage.name)}
                </Link>

                <span className="c-loan-main-section-list-item-badge">
                  {subpage.id !== "documents" && (
                    <CustomBadge badgeType={badgeType} badgeText={badgeText} />
                  )}
                </span>

                {subPageIdx === 3 && subpage.status !== "completed" && (
                  <LoanInlineConsentForm />
                )}

                <ButtonLink
                  extraClassName="c-loan-main-section-list-item-link"
                  url={`${subpage.url}`}
                  btnVariant={isMobileBreakpoint ? "icon" : "link"}
                  btnColor={isMobileBreakpoint ? "ghost" : undefined}
                  btnSize="md"
                  text={
                    !isMobileBreakpoint && subPageIdx === 3
                      ? "Read terms"
                      : !isMobileBreakpoint && subPageIdx !== 3
                      ? "Open"
                      : undefined
                  }
                  icon={<IcnChevronRight />}
                  iconSize="sm"
                  iconPlacement="right"
                />
              </article>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default LoanContent
