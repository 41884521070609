import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import CustomInput from "../form-elements/CustomInput"
import Button from "../common/Button"

interface FormData {
  email: string
}

const defaultValues: FormData = {
  email: "",
}

const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
})

function ForgotPasswordForm() {
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<FormData>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: FormData) => console.log(data)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend className="c-visually-hidden">{t("Enter your email")}</legend>

        <CustomInput labelText="Email" name="email" control={control} />

        <Button
          type="submit"
          btnColor="blue"
          text="Reset password"
          disabled={!isValid || !isDirty}
        />
      </fieldset>
    </form>
  )
}

export default ForgotPasswordForm
