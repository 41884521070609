import {ReactNode} from 'react'
import {useTranslation} from "react-i18next"
import clsx from "clsx"
import {ReactComponent as IcnDefault} from "../../assets/icons/icn-circle-info-i.svg";
import {ReactComponent as IcnInfo} from "../../assets/icons/icn-circle-info-i.svg";
import {ReactComponent as IcnSuccess} from "../../assets/icons/icn-circle-check.svg";
import {ReactComponent as IcnWarning} from "../../assets/icons/icn-circle-warning.svg";
import {ReactComponent as IcnDanger} from "../../assets/icons/icn-circle-danger.svg";
import styles from "../../styles/CustomAlert.module.scss"
interface CustomAlertProps {
  extraClassName?: string;
  alertType: "info" | "success" | "warning" | "danger" | "default";
  alertText?: string;
  alertContent?: ReactNode;
  alertSize?: "sm"
  removeIcon?: boolean;
}

function switchAlertIcon(icon?: ReactNode) {
  switch (icon) {
    case "info":
      return <IcnInfo/>
    case "success":
      return <IcnSuccess/>
    case "warning":
      return <IcnWarning/>
    case "danger":
      return <IcnDanger/>
    default:
      return <IcnDefault/>
  }
}

function CustomAlert(
  {
    extraClassName,
    alertType,
    alertText,
    alertContent,
    removeIcon
  }:CustomAlertProps) {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.cAlert, "c-alert", alertType, extraClassName && extraClassName)}>
      {!removeIcon && (
        <i className="c-alert-icon">
          {switchAlertIcon(alertType)}
        </i>
      )}

      {alertText? (
        <span className="c-alert-text">
          {t(alertText)}
        </span>
      ):(
        <div className="c-alert-content">
          {alertContent}
        </div>
      )}

    </div>
  )
}

export default CustomAlert