import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import "tippy.js/animations/shift-toward.css"
import "tippy.js/dist/tippy.css"
import "../src/styles/index.scss"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const demoApp =
  window.location.origin.includes("demo.leasyfi.com") ||
  window.location.origin.includes("localhost:")

root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

!demoApp && reportWebVitals()
