import { Trans, useTranslation } from "react-i18next"
import CustomInputPattern from "../../form-elements/CustomInputPattern"

interface FormStep1Props {
  control: any
  firstName: string
}

function FormStep1({ control, firstName }: FormStep1Props) {
  const { t } = useTranslation()

  return (
    <fieldset>
      <legend>
        <Trans>
          Thanks {{ firstName }}, let’s get your birthday to make sure
          everything lines up.
        </Trans>
      </legend>

      <CustomInputPattern
        labelText="Date of birth"
        name="birthDate"
        control={control}
        format="##/##/####"
        helpText={t("Note: Please use this format: MM/DD/YYYY")}
      />

    </fieldset>
  )
}

export default FormStep1
