import React, { useRef, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useFormContext, useWatch } from "react-hook-form"
import { useLoadScript, Autocomplete } from "@react-google-maps/api"
import { provinces } from "../../../constant/constants"
import CustomInput from "../../form-elements/CustomInput"
import CustomSelect from "../../form-elements/CustomSelect"
import Button from "../../common/Button"
import CustomInputPostalCode from "../../form-elements/CustomInputPostalCode"

const libraries: ("places" | "geometry")[] = ["places"]

interface FormStep2Props {
  control: any
}

function FormStep2({ control }: FormStep2Props) {
  const { t } = useTranslation()

  const [showManualEntry, setShowManualEntry] = useState(false)
  const { setValue, watch, trigger } = useFormContext()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB-QjsJ3LzffiFH9UZ86zUXv9SX_Lz5crk",
    libraries: libraries,
  })

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)

  const parseAddress = (addressString: string) => {
    const parts = addressString?.split(",").map((part) => part.trim())

    const address = parts[0]
    const city = parts[1]
    const provinceAndPostal = parts[2]?.split(" ")
    const provinceAcronym = provinceAndPostal[0]
    const postal_code =
      provinceAndPostal[1] && provinceAndPostal[2]
        ? provinceAndPostal[1] + " " + provinceAndPostal[2]
        : ""

    const provinceFullName =
      provinces.find(
        (province) =>
          province.label.toLowerCase() === provinceAcronym.toLowerCase(),
      )?.value || ""

    return {
      addressField: address,
      city,
      province: provinceFullName,
      postal_code,
    }
  }

  const handleAddressSelect = () => {
    const place = autocompleteRef.current?.getPlace()
    if (place && place.formatted_address) {
      setValue("address_google", place.formatted_address, {
        shouldValidate: false,
      })
      setValue("address", parseAddress(place.formatted_address), {
        shouldValidate: true,
      })
      trigger("address") // Trigger validation on address field
    }
  }

  const toggleManualEntry = () => {
    setShowManualEntry(!showManualEntry)
    if (!showManualEntry) {
      setValue("address_google", "", { shouldValidate: true })
    }
  }

  // Watch for changes to the address_google field and re-trigger validation
  const watchedAddressGoogle = watch("address_google")
  useEffect(() => {
    trigger("address")
  }, [watchedAddressGoogle, trigger])

  if (!isLoaded) return null
  if (loadError) return <div>Error loading maps</div>

  return (
    <fieldset>
      <legend>{t("Alright, next we need your address.")}</legend>

      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handleAddressSelect}
        options={{ componentRestrictions: { country: "ca" } }}
      >
        <CustomInput
          type="text"
          labelText="Address"
          name="address_google"
          defaultValue=""
          control={control}
          disabled={showManualEntry}
          helpText={t(
            "Note: We only offer our services to Canada for the time being.",
          )}
        />
      </Autocomplete>

      <div className="c-button-container manual-address">
        <Button
          btnVariant="link"
          btnColor="blue"
          text="Enter manually"
          onClick={toggleManualEntry}
        />
      </div>

      {showManualEntry && (
        <>
          <CustomInput
            labelText={t("Address")}
            name="address.addressField"
            control={control}
          />

          <div className="c-form-grid">
            <CustomInput
              labelText={t("City")}
              name="address.city"
              control={control}
            />

            <CustomSelect
              labelText={t("Province")}
              name="address.province"
              options={provinces}
              control={control}
            />

            <CustomInputPostalCode
              name="address.postal_code"
              control={control}
            />
          </div>
        </>
      )}
    </fieldset>
  )
}

export default FormStep2
