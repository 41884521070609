import { useTranslation } from "react-i18next"
import { ReactComponent as IcnLogo } from "../../../assets/images/leasify-brandmark.svg"
import { ReactComponent as IcnLink } from "../../../assets/icons/icn-link.svg"
import { ReactComponent as IcnPlaid } from "../../../assets/icons/icn-plaid.svg"

function FormStep8() {
  const { t } = useTranslation()

  return (
    <section className="c-pre-approval-section-form-bank-verification">

      <div className="c-pre-approval-section-form-bank-verification-header">

        <div className="c-pre-approval-section-form-bank-verification-header-inner">
          <span>
            <IcnLogo />
          </span>
          <i>
            <IcnLink />
          </i>
          <span>
            <IcnPlaid />
          </span>
        </div>

      </div>

      <div className="c-pre-approval-section-form-bank-verification-content">

        <div className="c-pre-approval-section-form-bank-verification-content-wrapper">

          <h2 className="h5">
            {t("Connect To Your Bank")}
          </h2>

          <p>
            {t("This helps us offer the best rate and fastest approval time. We use IBV state-of-the art security encryption preventing access to your login information to anyone but you.",)}
          </p>
        </div>

      </div>

    </section>
  )
}

export default FormStep8
