import { ReactComponent as IcnDashboard } from "../assets/icons/icn-dashboard.svg"
import { ReactComponent as IcnDocuments } from "../assets/icons/icn-file.svg"
import { ReactComponent as IcnSupport } from "../assets/icons/icn-circle-info.svg"
import { ReactComponent as IcnApplication } from "../assets/icons/icn-plus.svg"
import { ReactComponent as IcnUser } from "../assets/icons/icn-user.svg"
import { ReactComponent as IcnOffice } from "../assets/icons/icn-newspaper.svg"
import { ReactComponent as IcnSparkle } from "../assets/icons/icn-sparkle.svg"
import { ReactComponent as IcnBook } from "../assets/icons/icn-book.svg"
import { ReactComponent as IcnList } from "../assets/icons/icn-list.svg"
import { ReactComponent as IcnInfo } from "../assets/icons/icn-circle-info-i.svg"
import { ReactComponent as IcnClipboardCheck } from "../assets/icons/icn-clipboard-check.svg"
import { ReactComponent as IcnTeam } from "../assets/icons/icn-team.svg"
import { ReactComponent as IcnBlog } from "../assets/icons/icn-grid.svg"
import { ReactComponent as IcnSheet } from "../assets/icons/icn-sheet.svg"
import { ReactComponent as IcnCalculator } from "../assets/icons/icn-calculator.svg"
import { ReactComponent as IcnYoutube } from "../assets/icons/icn-social-youtube.svg"
import { ReactComponent as IcnFacebook } from "../assets/icons/icn-social-facebook-f.svg"
import { ReactComponent as IcnInstagram } from "../assets/icons/icn-social-instagram.svg"
import { ReactComponent as IcnLinkedIn } from "../assets/icons/icn-social-linked-in.svg"
import { ReactComponent as IcnCart } from "../assets/icons/icn-cart.svg"
import { ReactComponent as IcnChartBar } from "../assets/icons/icn-chart-bar.svg"
import { ReactComponent as IcnLike } from "../assets/icons/icn-like.svg"
import HomeSectionGetApprovedStep1 from "../assets/images/home-section-get-approved-step1.svg"
import HomeSectionGetApprovedStep2 from "../assets/images/home-section-get-approved-step2.webp"
import HomeSectionGetApprovedStep3 from "../assets/images/home-section-get-approved-step3.webp"
import HomeSectionDealerships1 from "../assets/images/home-section-dealerships-x2-img-1.webp"
import HomeSectionDealerships2 from "../assets/images/home-section-dealerships-x2-img-2.webp"
import HomeSectionDealerships2Mobile from "../assets/images/home-section-dealerships-x2-img-2-mobile.webp"
import HomeSectionDealerships3 from "../assets/images/home-section-dealerships-x2-img-3.webp"
import HomeSectionDealerships3Mobile from "../assets/images/home-section-dealerships-x2-img-3-full.webp"
import HomeSectionDealerships4 from "../assets/images/home-section-dealerships-x2-img-4.webp"
import HomeSectionDealerships5 from "../assets/images/home-section-dealerships-x2-img-5.webp"

// Nav items
export const PublicPageDesktopNavItems = [
  { title: "How does it work?", url: "#" },
  { title: "Business", url: "/commercial-leasing" },
  { title: "Contact", url: "/support" },
  { title: "Get app", url: "#" },
]

export const PublicPageSocialNavItems = [
  { title: "Youtube", url: "https://www.google.com", icon: <IcnYoutube /> },
  { title: "Facebook", url: "https://www.google.com", icon: <IcnFacebook /> },
  { title: "Instagram", url: "https://www.google.com", icon: <IcnInstagram /> },
  { title: "LinkedIn", url: "https://www.google.com", icon: <IcnLinkedIn /> },
]

export const PublicPageNavItems = [
  {
    category: "Help",
    categoryId: "help",
    isSubFooterList: true,
    navItems: [
      {
        title: "How does it work?",
        url: "#",
        isComingSoon: false,
        icon: <IcnBook />,
      },
      {
        title: "FAQ's",
        url: "#",
        isComingSoon: false,
        icon: <IcnList />,
      },
      {
        title: "Support",
        url: "/support",
        isComingSoon: false,
        icon: <IcnSupport />,
      },
    ],
  },
  {
    category: "Company",
    categoryId: "company",
    isSubFooterList: true,
    navItems: [
      {
        title: "About us",
        url: "#",
        isComingSoon: false,
        icon: <IcnInfo />,
      },
      {
        title: "Commercial leasing",
        url: "/commercial-leasing",
        isComingSoon: false,
        icon: <IcnClipboardCheck />,
      },
      {
        title: "Careers",
        url: "#",
        isComingSoon: true,
        icon: <IcnTeam />,
      },
    ],
  },
  {
    category: "Resources",
    categoryId: "resources",
    isSubFooterList: true,
    navItems: [
      {
        title: "Blog posts",
        url: "#",
        isComingSoon: false,
        icon: <IcnBlog />,
      },
      {
        title: "Press releases",
        url: "#",
        isComingSoon: false,
        icon: <IcnSheet />,
      },
      {
        title: "Finance calculator",
        url: "#",
        isComingSoon: true,
        icon: <IcnCalculator />,
      },
    ],
  },
  {
    category: "Legal",
    categoryId: "legal",
    isSubFooterList: false,
    navItems: [
      {
        title: "Terms & conditions",
        url: "/terms",
        icon: <IcnOffice />,
        isComingSoon: false,
      },
      {
        title: "Privacy policy",
        url: "/privacy-policy",
        icon: <IcnOffice />,
        isComingSoon: false,
      },
    ],
  },
]

export const LegalNavItems = [
  { title: "Privacy policy", url: "/privacy-policy" },
  { title: "Terms & conditions", url: "/terms" },
]

export const DashboardNavItems = [
  {
    id: "dashboard",
    title: "Dashboard",
    mobileTitle: "Dashboard",
    url: "/dashboard",
    icon: <IcnDashboard />,
  },
  {
    id: "documents",
    title: "Documents",
    mobileTitle: "Documents",
    url: "/documents",
    icon: <IcnDocuments />,
  },
  {
    id: "new-loan",
    title: "New Loan",
    mobileTitle: "New Loan",
    url: "/loan/new",
    icon: <IcnApplication />,
  },
  {
    id: "support",
    title: "Support",
    mobileTitle: "Support",
    url: "/support",
    icon: <IcnSupport />,
  },
]

export const DashboardMobileMenuNavItems = [
  {
    category: "Account",
    links: [
      {
        id: "account",
        title: "Edit account",
        isExternalLink: false,
        url: "/account-settings",
        icon: <IcnUser />,
      },
    ],
  },
  {
    category: "About Leasyfi",
    links: [
      {
        id: "support",
        title: "Get support",
        isExternalLink: false,
        url: "/support",
        icon: <IcnSupport />,
      },
      {
        id: "terms",
        title: "Terms & conditions",
        isExternalLink: false,
        url: "/terms",
        icon: <IcnOffice />,
      },
      {
        id: "privacy",
        title: "Privacy policy",
        isExternalLink: false,
        url: "/privacy-policy",
        icon: <IcnOffice />,
      },
      {
        id: "rate",
        title: "Download the Leasyfi App",
        isExternalLink: true,
        url: "www.google.com",
        icon: <IcnSparkle />,
      },
    ],
  },
]

// Components
export const HomeSectionGetApprovedList = [
  {
    title: "Apply Online",
    paragraph:
      "Fill out our simple online application. Find out if you're pre-approved in just 60 seconds, without impacting your credit score.",
    image: HomeSectionGetApprovedStep1,
  },
  {
    title: "Purchase Your Car",
    paragraph:
      "Once approved, visit any dealership to purchase your new or used car. Leasyfi makes the process seamless and hassle-free.",
    image: HomeSectionGetApprovedStep2,
  },
  {
    title: "Enjoy Transparency and Ease",
    paragraph:
      "Use our user-friendly app to manage your loan. Enjoy clear and direct information about your payments and balance",
    image: HomeSectionGetApprovedStep3,
  },
]

export const HomeSectionDealershipsGridItems = [
  {
    image: HomeSectionDealerships1,
    imageAlt: "Dealership",
    mobileImage: null,
  },
  {
    image: HomeSectionDealerships2,
    imageAlt: "Approved loans",
    mobileImage: HomeSectionDealerships2Mobile,
  },
  {
    image: HomeSectionDealerships3,
    imageAlt: "Dealership transaction",
    mobileImage: HomeSectionDealerships3Mobile,
  },
  {
    image: HomeSectionDealerships4,
    imageAlt: "Car model 2",
    mobileImage: null,
  },
  {
    image: HomeSectionDealerships5,
    imageAlt: "Car model 1",
    mobileImage: null,
  },
]

export const CommercialLeasingList = [
  {
    title: "Inventory requirement",
    text: "Minimum of 20 vehicles",
    icon: <IcnCart />,
  },
  {
    title: "Financial stability",
    text: "6 months of bank statements",
    icon: <IcnChartBar />,
  },
  {
    title: "Customer satisfaction",
    text: "4+ star average rating",
    icon: <IcnLike />,
  },
]

// Selects options
export const LeaseDetailsFormTermList = [
  { label: "12 months", value: "12 Months" },
  { label: "24 months", value: "24 Months" },
  { label: "36 months", value: "36 Months" },
  { label: "48 months", value: "48 Months" },
]

export const provinces = [
  { label: "AB", value: "Alberta" },
  { label: "BC", value: "British Columbia" },
  { label: "MB", value: "Manitoba" },
  { label: "NB", value: "New Brunswick" },
  { label: "NL", value: "Newfoundland and Labrador" },
  { label: "NS", value: "Nova Scotia" },
  { label: "ON", value: "Ontario" },
  { label: "PE", value: "Prince Edward Island" },
  { label: "QC", value: "Quebec" },
  { label: "SK", value: "Saskatchewan" },
  { label: "NT", value: "Northwest Territories" },
  { label: "NU", value: "Nunavut" },
  { label: "YT", value: "Yukon" },
]

// Media Breakpoints
export const responsiveBreakpoints = {
  isMobile: "(max-width: 991px)",
  isLaptopSm: "(max-width: 1199px)",
}

// Helpers
export const bytesToMB = (bytes: number): string => {
  if (bytes === 0) {
    return "0.00"
  }
  const sizeInMB = bytes / (1024 * 1024)
  return sizeInMB < 0.01 ? "0.01" : sizeInMB.toFixed(2)
}
