import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import styles from "../../styles/CustomSectionBlueBg.module.scss"

interface CustomSectionLegalPageProps {
  extraClassName?: string;
  title: string;
  paragraph?: string;
  children?: ReactNode;
}
function CustomSectionBlueBg(
  {
    extraClassName,
    title,
    paragraph,
    children
  }: CustomSectionLegalPageProps) {
  const { t } = useTranslation()
  return (
    <section className={clsx(styles.cSectionBlueBg,"c-section-blue-bg", "c-with-blue-bg", extraClassName && extraClassName)}>

      <div className="c-section-blue-bg-wrapper">

        <div className="c-section-blue-bg-header">

          <h1 className="h2 c-font-staatliches">
            {t(title)}
          </h1>

          {paragraph && (
            <p className="c-text-px18-responsive">
              {t(paragraph)}
            </p>
          )}

        </div>

        {children && (
          <div className="c-section-blue-bg-content">
            <div className="c-section-blue-bg-content-wrapper">
              {children}
            </div>
          </div>
        )}

      </div>

    </section>
  )
}

export default CustomSectionBlueBg