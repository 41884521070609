import React, { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link"
import moment from "moment"
import { useAppContext } from "../contexts/AppContext"
import Button from "../components/common/Button"
import { signup } from "../services/api/userService"
import { submitOnLinkSuccess } from "../services/api/preApprovalService"
import { use } from "i18next"

interface PlaidLinkComponentProps {
  email: string
  public_token: string
  user_token: string
  firstName: string
  lastName: string
  birthDate: string
  languagePreference: string
}

const PlaidLinkComponent = ({
  email,
  firstName,
  lastName,
  birthDate,
  user_token,
  languagePreference,
  public_token,
}: PlaidLinkComponentProps) => {
  const navigate = useNavigate()
  const { isLoading, setIsLoading } = useAppContext()

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token) => {
      try {
        setIsLoading(true)
        await signup({
          email: email,
          first_name: firstName,
          last_name: lastName,
          birth_date: birthDate,
          language_preference: languagePreference,
        })
        await submitOnLinkSuccess({
          email,
          public_token,
          user_token,
          application_status: "In Progress",
        })
        setIsLoading(false)
        navigate("/application-status")
      } catch (error) {
        console.error("Error exchanging public token:", error)
      }
    },
    [
      birthDate,
      email,
      firstName,
      languagePreference,
      lastName,
      navigate,
      setIsLoading,
      user_token,
    ],
  )

  useEffect(() => {
    return () => {
      if (isLoading) {
        setIsLoading(false)
      }
    }
  }, [isLoading, setIsLoading])

  const config: PlaidLinkOptions = {
    token: public_token,
    onSuccess,
  }

  const { open, ready } = usePlaidLink(config)

  return (
    <>
      <Button
        extraClassName="c-pre-approval-section-btn-next"
        btnColor="blue"
        text="Connect your bank"
        onClick={() => open()}
        disabled={!ready}
      />
    </>
  )
}

export default PlaidLinkComponent
