import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import CustomInput from "../form-elements/CustomInput"
import CustomInputPasswordShowHide from "../form-elements/CustomInputPasswordShowHide"
import Button from "../common/Button"
import CustomAlert from "../common/CustomAlert"

interface AccountSettingsEditEmailFormProps {
  onCloseModal: () => void
}

interface FormData {
  email: string
  password: string
}

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
})

function AccountSettingsEditEmailForm({
  onCloseModal,
}: AccountSettingsEditEmailFormProps) {
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const onSubmit = (data: FormData) => {
    console.log(data)
  }

  return (
    <form
      className="c-account-settings-section-edit-email-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset>
        <legend>
          <span>{t("Please type in your new email address")}</span>
          <small>
            {t(
              "Ensure you are correctly typing your email address as this change will require time to validate its accuracy.",
            )}
          </small>
        </legend>

        <CustomInput
          type="email"
          labelText="Email address"
          name="email"
          control={control}
        />

        <CustomInputPasswordShowHide
          labelText="Type in your password"
          name="password"
          control={control}
        />
      </fieldset>

      <CustomAlert alertType="warning" alertText="Your password is required." />

      <div className="c-button-container">
        <Button text="Cancel" onClick={onCloseModal} />

        <Button
          type="submit"
          btnColor="blue"
          text="Save changes"
          disabled={!isValid || !isDirty}
        />
      </div>
    </form>
  )
}

export default AccountSettingsEditEmailForm
