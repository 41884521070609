import axios from "axios"

const baseURL =
  process.env.NODE_ENV === "development"
    ? "/api"
    : "https://leasyfi-middleware.webisoft.org/"

export const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Vary: "*",
    Pragma: "no-cache",
    Expires: "0",
  },
  responseType: "json",
  withCredentials: true,
})
