import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PreApprovalFormJobStatus } from "../../../mock-data/dummyData"
import CustomInput from "../../form-elements/CustomInput"
import CustomSelect from "../../form-elements/CustomSelect"

interface FormStep3Props {
  control: any
  employmentStatus?: string | null
}

function FormStep3({ control, employmentStatus }: FormStep3Props) {
  const { t } = useTranslation()
  const [showJobTitle, setShowJobTitle] = useState(false)
  const [showEmployerName, setShowEmployerName] = useState(false)

  useEffect(() => {
    const status = employmentStatus ? employmentStatus.toLowerCase() : ""

    switch (status) {
      case "self-employed":
      case "contractor":
        setShowJobTitle(true)
        setShowEmployerName(false)
        break
      case "retired":
      case "unemployed":
        setShowJobTitle(false)
        setShowEmployerName(false)
        break
      default:
        setShowJobTitle(status.length > 0)
        setShowEmployerName(status.length > 0)
    }
  }, [employmentStatus])

  return (
    <fieldset>
      <legend>{t("Alright, next we need your employment status.")}</legend>

      <CustomSelect
        labelText="Employment Status"
        name="employmentStatus"
        options={PreApprovalFormJobStatus}
        control={control}
      />

      {showJobTitle && (
        <CustomInput labelText="Job Title" name="jobTitle" control={control} />
      )}

      {showEmployerName && (
        <CustomInput
          labelText="Employer Name"
          name="employerName"
          control={control}
          helpText={t("Note: As displayed on bank statement")}
        />
      )}
    </fieldset>
  )
}

export default FormStep3
