import { useMediaQuery } from "usehooks-ts"
import { responsiveBreakpoints } from "../../constant/constants"
import Logo from "./Logo"
import ButtonLink from "./ButtonLink"
import NavItemsPublicDesktop from "./NavItemsPublicDesktop"
import MobileMenuPublic from "./PublicMobileMenu"
import { ReactComponent as IcnUser } from "../../assets/icons/icn-user.svg"

function NavbarPublic() {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  return (
    <>
      {isMobileBreakpoint && <MobileMenuPublic />}

      <Logo />

      {!isMobileBreakpoint && (
        <NavItemsPublicDesktop extraClassName="c-desktop" />
      )}

      <div className="c-button-container">
        <ButtonLink
          url="/login"
          text={isMobileBreakpoint ? undefined : "Sign in"}
          title="Sign in"
          btnSize="md"
          btnColor={isMobileBreakpoint ? "ghost" : undefined}
          btnVariant={isMobileBreakpoint ? "icon" : undefined}
          icon={isMobileBreakpoint ? <IcnUser /> : undefined}
        />

        {!isMobileBreakpoint && (
          <ButtonLink
            url="/apply"
            text="Get pre-approved"
            btnSize="md"
            btnColor="blue"
          />
        )}
      </div>
    </>
  )
}

export default NavbarPublic
