import { useCallback, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { PatternFormat } from "react-number-format"
import { useMediaQuery } from "usehooks-ts"
import { useAppContext } from "../contexts/AppContext"
import { responsiveBreakpoints } from "../constant/constants"
import { getUserData } from "../services/api/dashboardService"
import DashboardLayout from "../layouts/DashboardLayout"
import CustomSectionBoxed from "../components/common/CustomSectionBoxed"
import CustomAlert from "../components/common/CustomAlert"
import AccountsSettingsEditAddressModal from "../components/modals/AccountsSettingsEditAddressModal"
import AccountsSettingsEditEmailModal from "../components/modals/AccountsSettingsEditEmailModal"
import AccountsSettingsEditPhoneModal from "../components/modals/AccountsSettingsEditPhoneModal"
import ButtonLink from "../components/common/ButtonLink"
import styles from "../styles/AccountSettings.module.scss"

function AccountSettings() {
  const { t } = useTranslation()
  const { fullName, address, userEmail, phoneNumber, birthDate } =
    useAppContext()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  const [modals, setModals] = useState({
    addressModalIsOpen: false,
    emailModalIsOpen: false,
    phoneModalIsOpen: false,
  })

  const handleOpenModal = useCallback((modalType: string) => {
    setModals((prevState) => ({
      ...prevState,
      [`${modalType}ModalIsOpen`]: true,
    }))
  }, [])

  const handleCloseModal = useCallback((modalType: string) => {
    setModals((prevState) => ({
      ...prevState,
      [`${modalType}ModalIsOpen`]: false,
    }))
  }, [])

  const handleOpenAddressModal = () => handleOpenModal("address")
  const handleCloseAddressModal = () => handleCloseModal("address")
  const handleOpenEmailModal = () => handleOpenModal("email")
  const handleCloseEmailModal = () => handleCloseModal("email")
  const handleOpenPhoneModal = () => handleOpenModal("phone")
  const handleClosePhoneModal = () => handleCloseModal("phone")

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await getUserData()
      } catch (error) {
        console.error("Error fetching leases:", error)
      }
    }

    fetchUserData()
  }, [])

  console.log("PHONE", phoneNumber)
  return (
    <DashboardLayout
      pageTitle="Account Settings"
      pageClassName={clsx(styles.cPageAccountSettings, "c-page-account-settings")}
      navbarTitle="Account Settings"
      navbarHasBackLink={isMobileBreakpoint}
      navbarBackLinkText={isMobileBreakpoint ? "" : "Dashboard"}
      navbarBackLinkTitle="Back to Dashboard"
      navbarBackLinkVariant="icon"
    >
      <section className="c-account-settings">

        <div className="c-account-settings-wrapper">

          <CustomSectionBoxed
            classname="c-account-settings-section-personal-info"
            headerContentType="title"
            headerTitle="Personal Information"
            headerTitleParagraph="Please note due to legal regulations you must confirm your identity once again with a valid matching ID."
          >
            <ul className="c-account-settings-section-list">
              <li>
                <article>
                  <em>{t("Legal name")}</em>
                  <span>{fullName}</span>
                </article>
              </li>

              <li>
                <article>
                  <em>{t("Date of birth")}</em>
                  <span>
                    <time>{birthDate}</time>
                  </span>
                </article>
              </li>

              <li className="address">
                <article>
                  <em>{t("Address")}</em>
                  <span>{address}</span>
                </article>

                <AccountsSettingsEditAddressModal
                  isModalOpen={modals.addressModalIsOpen}
                  onClickOpen={handleOpenAddressModal}
                  onClickClose={handleCloseAddressModal}
                />
              </li>
            </ul>
          </CustomSectionBoxed>

          <CustomAlert
            alertType="warning"
            alertContent={
              <>
                {t("Changing personal information requires a valid form of identification for verification.")}
                &nbsp;
                <ButtonLink
                  url="/support"
                  btnColor="blue"
                  btnVariant="link"
                  text="Please contact us"
                />
              </>
            }
          />

          <CustomSectionBoxed
            classname="c-account-settings-section-contact-info"
            headerContentType="title"
            headerTitle="Contact Information"
            headerTitleParagraph="Please note due to legal regulations you must confirm your identity once again with a valid matching ID."
          >
            <ul className="c-account-settings-section-list">
              <li>
                <article>
                  <em>{t("Email address")}</em>
                  <span>{userEmail}</span>
                </article>

                <AccountsSettingsEditEmailModal
                  isModalOpen={modals.emailModalIsOpen}
                  onClickOpen={handleOpenEmailModal}
                  onClickClose={handleCloseEmailModal}
                />
              </li>

              <li>
                <article>
                  <em>{t("Phone number")}</em>
                  <PatternFormat
                    displayType="text"
                    format={"+1 (###) ### ####"}
                    value={phoneNumber}
                  />
                </article>

                <AccountsSettingsEditPhoneModal
                  isModalOpen={modals.phoneModalIsOpen}
                  onClickOpen={handleOpenPhoneModal}
                  onClickClose={handleClosePhoneModal}
                />
              </li>
            </ul>
          </CustomSectionBoxed>
        </div>

      </section>
    </DashboardLayout>
  )
}

export default AccountSettings
