import React, {ReactNode} from 'react'
import clsx from "clsx"
import {useTranslation} from "react-i18next"
import styles from "../../styles/CustomBadge.module.scss"

interface CustomBadgeProps {
  extraClassName?: string;
  badgeType: "info" | "success" | "warning" | "danger" | "default";
  badgeSize?: "sm"
  badgeText?: string;
  badgeContent?: ReactNode;
}

function CustomBadge(
  {
    extraClassName,
    badgeType,
    badgeSize,
    badgeText,
    badgeContent
  }: CustomBadgeProps) {
  const { t } = useTranslation()

  return (
    <span
      className={clsx(styles.cBadge, "c-badge", badgeType, extraClassName && extraClassName)}
      data-size={badgeSize}
    >
      {badgeText? (
        <span className="c-badge-text">
          {t(badgeText)}
        </span>
      ):(
        <>{badgeContent}</>
      )}
    </span>
  )
}

export default CustomBadge