import React from 'react'
import clsx from "clsx"
import PublicPageLayout from "../layouts/PublicPageLayout"
import HomeSectionHero from "../components/HomeSectionHero"
import HomeSectionGetApproved from "../components/HomeSectionGetApproved"
import HomeSectionControl from "../components/HomeSectionControl"
import HomeSectionDealerships from "../components/HomeSectionDealerships"
import styles from "../styles/Home.module.scss"

function Home() {
  return (
    <PublicPageLayout
      pageTitle="Home"
      pageClassName={clsx(styles.cPageHome, "c-page-home")}
    >
      <section className="c-home">

        <HomeSectionHero/>
        <HomeSectionGetApproved/>
        <HomeSectionControl/>
        <HomeSectionDealerships/>

      </section>

    </PublicPageLayout>
  )
}

export default Home