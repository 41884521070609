import { useCallback, useState, useEffect } from "react"
import moment from "moment/moment"
import PreApprovalStatusValid from "./PreApprovalStatusValid"
import PreApprovalStatusPending from "./PreApprovalStatusPending"
import PreApprovalStatusFailed from "./PreApprovalStatusFailed"
import { getPreApprovalStatus } from "../services/api/preApprovalService"
import { useAppContext } from "../contexts/AppContext"

const PreApprovalStatus = (): JSX.Element => {
  const { activeLoan, firstName } = useAppContext()
  const [preApprovalStatus, setPreApprovalStatus] = useState("")
  const [preApprovalDate, setPreApprovalDate] = useState("")
  const [ibvDate, setIbvDate] = useState("")
  const formatedID = parseInt(activeLoan.message)

  const fetchPreApprovalStatus = useCallback(async () => {
    try {
      const status = await getPreApprovalStatus(activeLoan.message)
      if (status && typeof status.message.status === "string") {
        const normalizedStatus = status.message.status.toLowerCase()
        setPreApprovalStatus(normalizedStatus)
        setIbvDate(
          moment(status.message.ibv_timestamp).format(
            "MMMM Do, YYYY, HH:mm:ss",
          ),
        )
        setPreApprovalDate(
          moment(status.message.pre_approval_timestamp).format(
            "MMMM Do, YYYY, HH:mm:ss",
          ),
        )
      } else {
        console.error("Invalid status format:", status)
      }
    } catch (error) {
      console.error("Error fetching pre-approval status:", error)
    }
  }, [activeLoan.message])

  useEffect(() => {
    fetchPreApprovalStatus()
  }, [fetchPreApprovalStatus])

  const renderLoanStatusComponent = () => {
    switch (preApprovalStatus) {
      case "passed":
        return (
          <PreApprovalStatusValid
            preApprovalId={formatedID}
            firstName={firstName}
            pre_approval_timestamp={preApprovalDate}
            ibv_timestamp={ibvDate}
          />
        )
      case "failed":
        return (
          <PreApprovalStatusFailed
            preApprovalId={formatedID}
            firstName={firstName}
            pre_approval_timestamp={preApprovalDate}
            ibv_timestamp={ibvDate}
          />
        )
      case "under review":
        return (
          <PreApprovalStatusPending
            preApprovalId={formatedID}
            firstName={firstName}
            pre_approval_timestamp={preApprovalDate}
            ibv_timestamp={ibvDate}
          />
        )
      default:
        return null
    }
  }

  return <>{renderLoanStatusComponent()}</>
}

export default PreApprovalStatus
