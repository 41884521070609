import ButtonLink from "./ButtonLink"
import {useMediaQuery} from "usehooks-ts"
import {responsiveBreakpoints} from "../../constant/constants"
import {ReactComponent as IcnInfo} from "../../assets/icons/icn-circle-info.svg"
import { CustomButtonProps } from "../../types/types"
interface GetSupportLinkProps extends CustomButtonProps{
  hasMobileVersion?: boolean;
}

function GetSupportLink(
  {
    hasMobileVersion,
    btnVariant,
    btnColor,
  }: GetSupportLinkProps) {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  return (
    <>
      {isMobileBreakpoint && hasMobileVersion ? (
        <ButtonLink
          extraClassName="c-navbar-support-btn"
          url="/support"
          btnVariant="icon"
          btnSize="md"
          btnColor="ghost"
          title="Get support"
          icon={<IcnInfo/>}
        />
      ):(
        <ButtonLink
          extraClassName="c-navbar-support-btn"
          url="/support"
          btnVariant={btnVariant? "link" : undefined}
          btnColor={btnColor? btnColor : undefined}
          btnSize="md"
          text="Get support"
          icon={<IcnInfo/>}
        />
      )}
    </>
  )
}

export default GetSupportLink