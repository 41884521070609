// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  gap: 1em;
  width: 100%;
}
.LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group {
  margin-bottom: 0;
}
.LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group:first-child, .LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group:nth-child(2), .LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group:nth-child(5) {
  grid-column: span 2;
}
.LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group.c-form-group-vin input {
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid {
    grid-template-columns: repeat(5, 1fr);
    max-width: 44em;
  }
  .LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group:first-child, .LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group:nth-child(2), .LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group:nth-child(4) {
    grid-column: span 2;
  }
  .LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group:nth-child(3) {
    grid-column: span 1;
  }
  .LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc .c-loan-vehicle-details-form-grid > .c-form-group:nth-child(5) {
    grid-column: span 3;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/LoanSubpageVehicleDetails.module.scss"],"names":[],"mappings":"AAIQ;EACE,aAAA;EACA,qCAAA;EACA,uBAAA;EACA,QAAA;EACA,WAAA;AAHV;AAMU;EACE,gBAAA;AAJZ;AAMY;EAGE,mBAAA;AANd;AAUc;EACE,yBAAA;AARhB;AAaU;EAxBF;IAyBI,qCAAA;IACA,eAAA;EAVV;EAaY;IAGE,mBAAA;EAbd;EAgBY;IACE,mBAAA;EAdd;EAiBY;IACE,mBAAA;EAfd;AACF","sourcesContent":[".cPageVehicleDetails {\n  :global {\n    .c-loan-vehicle-details {\n      &-form {\n        &-grid {\n          display: grid;\n          grid-template-columns: repeat(2, 1fr);\n          align-items: flex-start;\n          gap: 1em;\n          width: 100%;\n\n\n          > .c-form-group {\n            margin-bottom: 0;\n\n            &:first-child,\n            &:nth-child(2),\n            &:nth-child(5) {\n              grid-column: span 2;\n            }\n\n            &.c-form-group-vin {\n              input {\n                text-transform: uppercase;\n              }\n            }\n          }\n\n          @media (min-width: 992px) {\n            grid-template-columns: repeat(5, 1fr);\n            max-width: 44em; //704px\n\n            > .c-form-group {\n              &:first-child,\n              &:nth-child(2),\n              &:nth-child(4) {\n                grid-column: span 2;\n              }\n\n              &:nth-child(3) {\n                grid-column: span 1;\n              }\n\n              &:nth-child(5) {\n                grid-column: span 3;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cPageVehicleDetails": `LoanSubpageVehicleDetails_cPageVehicleDetails__IAUbc`
};
export default ___CSS_LOADER_EXPORT___;
