import clsx from "clsx"
import React from "react"
import Button from "./Button"
import { useAuth } from "../../contexts/AuthContext"
import { ReactComponent as IcnLogout } from "../../assets/icons/icn-logout.svg"

interface LogoutButtonProps {
  extraClassName?: string
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ extraClassName }) => {
  const { logout } = useAuth()
  return (
    <Button
      extraClassName={clsx(extraClassName)}
      btnColor="ghost"
      btnVariant="link"
      icon={<IcnLogout />}
      text="Logout"
      onClick={logout}
    />
  )
}

export default LogoutButton
