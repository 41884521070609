import {Trans, useTranslation} from "react-i18next"
import clsx from "clsx"
import {useMediaQuery} from "usehooks-ts"
import {responsiveBreakpoints} from "../../constant/constants"
import CustomBadge from "./CustomBadge"
import styles from "../../styles/UserStatus.module.scss"

interface HeaderUserStatusProps {
  extraClassName?: string;
  userFirstName?: string;
  removeBadge?: boolean
  badgeType?: "info" | "success" | "warning" | "danger" | "default";
  badgeText?: string;
}
function UserStatus(
  {
    extraClassName,
    userFirstName,
    removeBadge,
    badgeType,
    badgeText
  }: HeaderUserStatusProps) {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  return (
    <div
      className={clsx(styles.cUserStatus, "c-user-status", extraClassName && extraClassName)}
      title={`Pre-Approval Application${!removeBadge && ": " + badgeText}`}
    >

      <h1 className="h5">
        {isMobileBreakpoint? (
          <>{userFirstName ? t("Your Pre-Approval Loan"): t("Pre-Approval Loan")}</>
        ):(
          <Trans>
            {userFirstName && `${userFirstName}'s`} Pre-Approval Application
          </Trans>
        )}
      </h1>

      {!removeBadge && (
        <CustomBadge
          badgeType={badgeType? badgeType : "default"}
          badgeText={badgeText}
        />
      )}

    </div>
  )
}

export default UserStatus