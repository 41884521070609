import clsx from "clsx"
import LoanNewLayout from "../layouts/LoanNewLayout"
import LoanLeasingOptionsForm from "../components/forms/LoanLeasingOptionsForm"
import styles from "../styles/LoanSubpageLeasingOptions.module.scss"

function LoanPendingLeasingOptions() {

  return (
    <LoanNewLayout
      subPageTitle="Leasing options"
      subPageExtraClassName={clsx(styles.cPageLeasingOptions, "c-page-leasing-options")}
      sectionExtraClassName="c-loan-leasing-options"
    >

      <LoanLeasingOptionsForm/>

    </LoanNewLayout>
  )
}

export default LoanPendingLeasingOptions