import { useTranslation } from "react-i18next"
import CustomAlert from "../../common/CustomAlert"
import CustomNumberInput from "../../form-elements/CustomInputNumber"

interface FormStep4Props {
  control: any
}

function FormStep4({ control }: FormStep4Props) {
  const { t } = useTranslation()

  return (
    <fieldset>
      <legend>
        {t(
          "Alright, next we need to know how much you earn and spend per month.",
        )}
      </legend>

      <CustomAlert
        alertType="info"
        alertText="In most cases, a minimum downpayment will be required for leasing or financing."
      />

      <CustomNumberInput
        labelText="Total monthly income"
        name="monthlyIncome"
        placeholder=" "
        prefix="$ "
        control={control}
      />

      <CustomNumberInput
        labelText="Total monthly expenses"
        name="monthlyExpenses"
        placeholder=" "
        prefix="$ "
        control={control}
        helpText={
          <>
            <p>{t("Example of expenses:")}</p>
            <ul>
              <li>{t("Rent")}</li>
              <li>{t("Phone")}</li>
              <li>{t("Internet")}</li>
              <li>{t("Electricity")}</li>
              <li>{t("Subscriptions")}</li>
            </ul>
          </>
        }
      />
    </fieldset>
  )
}

export default FormStep4
