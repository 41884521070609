import { useTranslation } from "react-i18next"
import { useMediaQuery } from "usehooks-ts"
import { responsiveBreakpoints } from "../constant/constants"
import CustomSectionBoxed from "./common/CustomSectionBoxed"
import SupportForm from "./forms/SupportForm"

function SupportSection() {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  const SupportCallLink = () => (
    <a
      href="tel:15146666666"
      rel="noopener noreferrer"
      target="_blank"
      className="c-button"
      data-color="blue"
    >
      <span>{t("Call us")}</span>
    </a>
  )

  return (
    <section className="c-support">

      <div className="c-support-wrapper">

        <div className="c-support-section-grid">

          <CustomSectionBoxed
            classname="c-support-section-grid-item form"
            headerContentType="title"
            headerTitle="How can we help you?"
            headerTitleParagraph="Use the form below to contact us with any questions you may have"
          >
            {isMobileBreakpoint && (
              <>
                <SupportCallLink />
                <div className="c-separator">
                  <span>{t("Or")}</span>
                </div>
              </>
            )}

            <SupportForm />
          </CustomSectionBoxed>

          {!isMobileBreakpoint && (
            <CustomSectionBoxed
              classname="c-support-section-grid-item cta"
              headerContentType="title"
              headerTitle="Quick Support"
              headerTitleParagraph="Fast response via phone number"
            >
              <SupportCallLink />
            </CustomSectionBoxed>
          )}
        </div>
      </div>
    </section>
  )
}

export default SupportSection
