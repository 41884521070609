import { useState } from "react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { useNavigate } from "react-router-dom"
import { resetPassword } from "../../services/api/userService"
import { resetPasswordData } from "../../types/user"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "../common/Button"
import { ReactComponent as IcnShow } from "../../assets/icons/icn-password-show.svg"
import { ReactComponent as IcnHide } from "../../assets/icons/icn-password-hide.svg"
import { ReactComponent as IcnValid } from "../../assets/icons/icn-check.svg"
import { ReactComponent as IcnInvalid } from "../../assets/icons/icn-close.svg"

interface FormData {
  password: string
}

interface SignupFormProps {
  email: string
  authKey: string
}

const defaultValues: FormData = {
  password: "",
}

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one symbol",
    )
    .matches(/\d/, "Password must contain at least one number"),
})

function SignupForm(props: SignupFormProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  const {
    register,
    watch,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues,
    criteriaMode: "all",
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  })

  const password = watch("password")
  const isPasswordModified = !!dirtyFields.password

  const onSubmit = async (formData: FormData) => {
    const apiData: resetPasswordData = {
      password: formData.password,
      token: props.authKey,
      email: props.email,
    }

    try {
      const response = await resetPassword(apiData)
      navigate("/login")
      console.log("Signup successful", response)
    } catch (error) {
      console.error("Login error", error)
    }
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleBlur = () => {
    setShowPassword(false)
  }

  const hasMinLength = password.length >= 8
  const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password)
  const hasNumber = /\d/.test(password)

  const getPasswordValidationClass = (condition: boolean) =>
    !isPasswordModified ? "" : condition ? "valid" : "error"

  const getPasswordValidationIcon = (condition: boolean) =>
    !isPasswordModified ? "" : condition ? <IcnValid /> : <IcnInvalid />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend className="c-visually-hidden">
          {t("Create a new password")}
        </legend>

        <div className={clsx("c-form-group", "c-form-group-password")}>
          <div className="c-form-group-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              id="id_password"
              placeholder=""
              {...register("password", { required: true })}
              aria-invalid={!!errors.password}
              onBlur={handleBlur}
            />

            <Button
              extraClassName="show-hide-password-btn"
              btnVariant="icon"
              btnColor="ghost"
              title={showPassword ? "Hide password" : "Show Password"}
              icon={showPassword ? <IcnShow /> : <IcnHide />}
              onClick={toggleShowPassword}
            />

            <label htmlFor="id_password">{t("Create password")}</label>
          </div>

          <div className="c-form-help-text">
            <ul>
              <li className={clsx(getPasswordValidationClass(hasMinLength))}>
                <i>{getPasswordValidationIcon(hasMinLength)}</i>

                <span>{t("Minimum 8 characters")}</span>
              </li>

              <li className={clsx(getPasswordValidationClass(hasSymbol))}>
                <i>{getPasswordValidationIcon(hasSymbol)}</i>

                <span>{t("At least 1 symbol")}</span>
              </li>

              <li className={clsx(getPasswordValidationClass(hasNumber))}>
                <i>{getPasswordValidationIcon(hasNumber)}</i>

                <span>{t("At least 1 number")}</span>
              </li>
            </ul>
          </div>
        </div>
      </fieldset>

      <Button type="submit" btnColor="blue" text="Login" disabled={!isValid} />
    </form>
  )
}

export default SignupForm
