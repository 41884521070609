function TermsContent() {
  return (
    <>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis egestas integer eget aliquet nibh praesent tristique magna sit. Leo vel orci porta non. Interdum consectetur libero id faucibus nisl. Sed vulputate odio ut enim blandit volutpat maecenas volutpat blandit. Sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. At imperdiet dui accumsan sit amet nulla facilisi. Aliquet porttitor lacus luctus accumsan tortor. Magna sit amet purus gravida quis blandit turpis. Quis eleifend quam adipiscing vitae proin. Diam maecenas ultricies mi eget mauris pharetra et. Cras pulvinar mattis nunc sed blandit libero.

        At tempor commodo ullamcorper a lacus. Pharetra massa massa ultricies mi quis. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus. Risus viverra adipiscing at in tellus integer feugiat scelerisque varius. Feugiat pretium nibh ipsum consequat nisl. Sed id semper risus in hendrerit gravida rutrum quisque non. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum. Ac placerat vestibulum lectus mauris. Pharetra convallis posuere morbi leo urna molestie at elementum eu. Ut sem viverra aliquet eget sit amet tellus. Fringilla est ullamcorper eget nulla facilisi etiam. Curabitur vitae nunc sed velit dignissim sodales. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Fermentum iaculis eu non diam phasellus vestibulum lorem sed. Quam nulla porttitor massa id neque aliquam vestibulum morbi. Nibh nisl condimentum id venenatis. Nisl tincidunt eget nullam non nisi est sit. Non pulvinar neque laoreet suspendisse interdum consectetur libero id. Diam maecenas ultricies mi eget mauris pharetra et ultrices neque.

        Dictum sit amet justo donec enim diam vulputate ut. Magna ac placerat vestibulum lectus. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Nulla facilisi morbi tempus iaculis urna id volutpat lacus. Integer feugiat scelerisque varius morbi enim nunc faucibus a. Fringilla ut morbi tincidunt augue. Nunc faucibus a pellentesque sit amet porttitor eget. Morbi non arcu risus quis varius quam quisque id diam. Viverra ipsum nunc aliquet bibendum enim facilisis gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus. Praesent elementum facilisis leo vel fringilla. Urna molestie at elementum eu. Elementum sagittis vitae et leo duis. Fusce id velit ut tortor. Fusce ut placerat orci nulla pellentesque. Nibh sit amet commodo nulla. Senectus et netus et malesuada fames. Ornare arcu dui vivamus arcu. Tristique risus nec feugiat in.

        Eros in cursus turpis massa tincidunt dui ut. Scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada. Risus feugiat in ante metus dictum at tempor commodo. Eu sem integer vitae justo. Dignissim cras tincidunt lobortis feugiat vivamus at. Nulla at volutpat diam ut venenatis tellus. Gravida rutrum quisque non tellus orci. Purus non enim praesent elementum facilisis leo vel. Pretium aenean pharetra magna ac placerat. Volutpat maecenas volutpat blandit aliquam etiam erat. Id nibh tortor id aliquet lectus proin. Convallis posuere morbi leo urna molestie. Velit aliquet sagittis id consectetur purus ut. Aliquam etiam erat velit scelerisque. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Sit amet luctus venenatis lectus magna. Duis convallis convallis tellus id interdum velit laoreet id. Fermentum et sollicitudin ac orci phasellus egestas tellus. Dictum sit amet justo donec enim diam vulputate. Ut tortor pretium viverra suspendisse potenti nullam.

        Nec dui nunc mattis enim ut tellus elementum sagittis. Metus dictum at tempor commodo ullamcorper a. Turpis nunc eget lorem dolor sed. Elementum integer enim neque volutpat. Neque vitae tempus quam pellentesque. Aliquet nibh praesent tristique magna sit amet. Turpis tincidunt id aliquet risus feugiat in ante metus. Pellentesque sit amet porttitor eget dolor morbi. Nunc scelerisque viverra mauris in aliquam sem fringilla ut. Commodo viverra maecenas accumsan lacus.

        Donec ac odio tempor orci. Cras semper auctor neque vitae tempus quam. Faucibus vitae aliquet nec ullamcorper sit amet risus nullam. Nunc vel risus commodo viverra maecenas. Semper feugiat nibh sed pulvinar proin. Sapien et ligula ullamcorper malesuada proin libero nunc consequat. Turpis egestas pretium aenean pharetra magna ac placerat. Tristique sollicitudin nibh sit amet commodo nulla. Tellus elementum sagittis vitae et leo. Eu mi bibendum neque egestas congue quisque egestas. Nunc vel risus commodo viverra maecenas accumsan lacus. Nec feugiat nisl pretium fusce id velit ut. Ac tincidunt vitae semper quis lectus nulla at volutpat diam. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum. Vitae nunc sed velit dignissim sodales ut eu. Vitae purus faucibus ornare suspendisse sed nisi. Sit amet cursus sit amet.

        Natoque penatibus et magnis dis parturient montes nascetur ridiculus mus. In pellentesque massa placerat duis ultricies lacus sed. Id diam maecenas ultricies mi. Id consectetur purus ut faucibus pulvinar. Egestas congue quisque egestas diam in. Consectetur purus ut faucibus pulvinar elementum integer enim neque volutpat. Ultrices eros in cursus turpis massa tincidunt dui. In fermentum et sollicitudin ac. Auctor elit sed vulputate mi sit amet. Pharetra et ultrices neque ornare aenean euismod. Vestibulum lorem sed risus ultricies tristique. Neque egestas congue quisque egestas diam in arcu cursus. Euismod elementum nisi quis eleifend. Viverra mauris in aliquam sem fringilla ut morbi. Ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Cras semper auctor neque vitae tempus. Volutpat commodo sed egestas egestas fringilla. Erat nam at lectus urna duis convallis convallis. Feugiat sed lectus vestibulum mattis ullamcorper.

        Quis vel eros donec ac odio tempor. Mauris ultrices eros in cursus turpis massa tincidunt. Quis auctor elit sed vulputate mi sit. Sed velit dignissim sodales ut eu sem integer vitae. Sit amet cursus sit amet dictum. Egestas congue quisque egestas diam in. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum. Donec ac odio tempor orci dapibus ultrices in iaculis. Dui faucibus in ornare quam viverra orci. Vel orci porta non pulvinar neque laoreet suspendisse. Suspendisse sed nisi lacus sed viverra. Porta lorem mollis aliquam ut. Sit amet mattis vulputate enim nulla. Morbi enim nunc faucibus a.

        Nisl condimentum id venenatis a. Mauris a diam maecenas sed enim ut sem viverra aliquet. Amet mauris commodo quis imperdiet. Mattis molestie a iaculis at erat pellentesque adipiscing commodo elit. Eget est lorem ipsum dolor sit. Scelerisque in dictum non consectetur a erat. Viverra nam libero justo laoreet sit. Adipiscing diam donec adipiscing tristique risus nec feugiat. Ridiculus mus mauris vitae ultricies. Morbi tristique senectus et netus et malesuada fames ac turpis. Quam adipiscing vitae proin sagittis nisl rhoncus mattis. Massa sed elementum tempus egestas sed sed. In nibh mauris cursus mattis molestie a iaculis. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Vestibulum sed arcu non odio euismod lacinia at. Commodo ullamcorper a lacus vestibulum sed arcu.

        Dui ut ornare lectus sit amet est placerat in. Gravida quis blandit turpis cursus. Enim sed faucibus turpis in eu mi bibendum. Mollis nunc sed id semper risus in hendrerit gravida. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec. Faucibus scelerisque eleifend donec pretium vulputate. Sagittis nisl rhoncus mattis rhoncus urna. Cum sociis natoque penatibus et magnis dis parturient. Turpis egestas pretium aenean pharetra magna ac placerat. Orci porta non pulvinar neque laoreet suspendisse interdum. Et ligula ullamcorper malesuada proin libero nunc. Ut aliquam purus sit amet luctus venenatis. Pretium quam vulputate dignissim suspendisse in. Magna sit amet purus gravida quis blandit turpis.

    </>
  )
}

export default TermsContent