import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { ReactComponent as MainLogo } from "../../assets/images/leasify-logo.svg"
import { ReactComponent as BrandMarkLogo } from "../../assets/icons/icn-logo-brandmark.svg"
import styles from "../../styles/Logo.module.scss"
interface LogoProps {
  extraClassName?: string
  useLogoBrandmark?: boolean
}

function Logo({ extraClassName, useLogoBrandmark }: LogoProps) {
  const { t } = useTranslation()

  return (
    <Link
      className={clsx(styles.cLogo, "c-logo", extraClassName && extraClassName)}
      to="/"
      title={t("Go to main page")}
    >
      {useLogoBrandmark ? <BrandMarkLogo /> : <MainLogo />}
    </Link>
  )
}

export default Logo
