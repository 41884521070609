import clsx from "clsx"
import {useMediaQuery} from "usehooks-ts"
import { useAuth } from "../contexts/AuthContext"
import {responsiveBreakpoints} from "../constant/constants"
import DashboardLayout from "../layouts/DashboardLayout"
import PublicPageLayout from "../layouts/PublicPageLayout"
import CustomSectionBoxed from "../components/common/CustomSectionBoxed"
import CustomSectionBlueBg from "../components/common/CustomSectionBlueBg"
import PrivacyContent from "../components/PrivacyContent"
import styles from "../styles/LegalPage.module.scss"

function PrivacyPolicy() {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const { isAuthenticated } = useAuth()

  const sectionHeaderTitle = "Privacy policy"
  const sectionHeaderParagraph = "Please review the privacy policies below"

  return (
    <>
      {isAuthenticated ? (
        <DashboardLayout
          pageTitle="Privacy Policy"
          pageClassName={clsx(styles.cPageLegal, "c-page-legal", "authenticated", "privacy")}
          navbarTitle="Privacy Policy"
          navbarHasBackLink={isMobileBreakpoint}
          navbarBackLinkUrl="/dashboard"
          navbarBackLinkText={isMobileBreakpoint ? "" : "Dashboard"}
          navbarBackLinkVariant="icon"
        >
          <CustomSectionBoxed
            classname="c-legal"
            headerContentType="title"
            headerTitle={sectionHeaderTitle}
            headerTitleParagraph={sectionHeaderParagraph}
          >
            <PrivacyContent/>
          </CustomSectionBoxed>

        </DashboardLayout>
      ):(
        <PublicPageLayout
          pageTitle="Privacy Policy"
          pageClassName={clsx(styles.cPageLegal, "c-page-legal", "unauthenticated", "privacy")}
        >
          <CustomSectionBlueBg
            extraClassName="c-legal"
            title={sectionHeaderTitle}
            paragraph={sectionHeaderParagraph}
          >
            <PrivacyContent/>
          </CustomSectionBlueBg>
        </PublicPageLayout>
      )}
    </>
  )
}

export default PrivacyPolicy