import { useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { updatePhoneNumber } from "../../services/api/userService"
import CustomInputPattern from "../form-elements/CustomInputPattern"
import CustomInputPasswordShowHide from "../form-elements/CustomInputPasswordShowHide"
import CustomAlert from "../common/CustomAlert"
import Button from "../common/Button"
import { useAppContext } from "../../contexts/AppContext"

interface AccountSettingsEditPhoneFormProps {
  onCloseModal: () => void
}

interface FormData {
  mobileNumber: string
  password: string
}

const schema = yup.object().shape({
  mobileNumber: yup.string().required("Mobile number is required"),
  password: yup.string().required("Password is required"),
})

function AccountSettingsEditPhoneForm({
  onCloseModal,
}: AccountSettingsEditPhoneFormProps) {
  const { t } = useTranslation()
  const { setPhoneNumber } = useAppContext()
  const [unauthorized, setUnauthorized] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      mobileNumber: "",
      password: "",
    },
  })

  const onSubmit = async (data: FormData) => {
    try {
      setUnauthorized(false)
      await updatePhoneNumber(data.mobileNumber, data.password)
      setPhoneNumber(data.mobileNumber)
      onCloseModal()
    } catch (error: any) {
      if (error.request.status === 401) {
        setUnauthorized(true)
      } else console.log("error updating phone numbner", error.message)
    }
  }

  return (
    <form
      className="c-account-settings-section-edit-phone-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {unauthorized && (
        <CustomAlert
          extraClassName="c-form-alert"
          alertType="danger"
          alertText="Invalid Password"
        />
      )}

      <fieldset>
        <legend>
          <span>{t("Please type in your new phone number")}</span>
          <small>
            {t(
              "Ensure you are correctly typing your phone number as this change will require time to validate its accuracy.",
            )}
          </small>
        </legend>

        <CustomInputPattern
          labelText="Mobile Number"
          name="mobileNumber"
          control={control}
          format={"+1 (###) ### ####"}
        />

        <CustomInputPasswordShowHide
          labelText="Type in your password"
          name="password"
          control={control}
        />
      </fieldset>

      <CustomAlert alertType="warning" alertText="Your password is required." />

      <div className="c-button-container">
        <Button text="Cancel" onClick={onCloseModal} />

        <Button
          type="submit"
          btnColor="blue"
          text="Save changes"
          disabled={!isValid || !isDirty}
        />
      </div>
    </form>
  )
}

export default AccountSettingsEditPhoneForm
