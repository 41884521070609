import {ReactNode} from "react"
import clsx from "clsx"
import BaseLayout from "./BaseLayout"
import Header from "../components/common/Header"
import NavbarDashboard from "../components/common/NavbarDashboard"
import Main from "../components/common/Main"
import DashboardSidebar from "../components/DashboardSidebar"
import styles from "../styles/DashboardLayout.module.scss"
interface DashboardLayoutProps {
  pageClassName?: string;
  pageTitle: string;
  navbarHasBackLink?: boolean;
  navbarBackLinkUrl?: string;
  navbarBackLinkText?: string;
  navbarBackLinkTitle?: string;
  navbarBackLinkVariant?: "link" | "icon"
  navbarTitle?: string;
  children?: ReactNode;
}

function DashboardLayout(
  {
    pageTitle,
    pageClassName,
    navbarHasBackLink,
    navbarBackLinkUrl,
    navbarBackLinkText,
    navbarBackLinkTitle,
    navbarBackLinkVariant,
    navbarTitle,
    children
  }: DashboardLayoutProps) {
  return (
    <BaseLayout
      bodyClassName="c-body-dashboard"
      pageClassName={clsx(styles.cPageDashboard, "c-page-dashboard", pageClassName && pageClassName)}
      pageTitle={pageTitle}
    >
      <Header>
        <NavbarDashboard
          navbarBackLinkVariant={navbarBackLinkVariant}
          navbarHasBackLink={navbarHasBackLink}
          navbarBackLinkUrl={navbarBackLinkUrl}
          navbarBackLinkText={navbarBackLinkText}
          navbarBackLinkTitle={navbarBackLinkTitle}
          navbarTitle={navbarTitle}
        />
      </Header>

      <Main>
        {children}
      </Main>

      <DashboardSidebar/>

    </BaseLayout>

  )
}

export default DashboardLayout
