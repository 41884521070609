import Button from "../common/Button"
import CustomModal from "../common/CustomModal"
import clsx from "clsx"
import AccountSettingsEditAddressForm from "../forms/AccountSettingsEditAddressForm"
import {ReactComponent as IcnChevronRight} from "../../assets/icons/icn-chevron-right.svg"
import styles from "../../styles/AccountSettingsModal.module.scss"

interface AccountsSettingsEditAddressModalProps {
  isModalOpen: boolean;
  onClickOpen: () => void;
  onClickClose: () => void;
}

function AccountsSettingsEditAddressModal(
  {
    isModalOpen,
    onClickOpen,
    onClickClose
  }: AccountsSettingsEditAddressModalProps) {
  return (
    <>
      <Button
        btnVariant="link"
        btnSize="md"
        text="Edit"
        icon={<IcnChevronRight/>}
        iconPlacement="right"
        iconSize="sm"
        onClick={onClickOpen}
      />

      <CustomModal
        extraClassName={clsx(styles.cSectionAccountSettingsModal, "c-account-settings-section-modal","edit-address")}
        isModalOpen={isModalOpen}
        onCloseModal={onClickClose}
        addModalHeader
        modalHeaderTitle="Change Address"
        modalHeaderParagraph="Please provide a valid form of identification to confirm the change."
      >

        <AccountSettingsEditAddressForm
          onCloseModal={onClickClose}
        />

      </CustomModal>

    </>
  )
}

export default AccountsSettingsEditAddressModal
