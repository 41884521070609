import clsx from "clsx"
import { PublicPageSocialNavItems } from "../../constant/constants"
import styles from "../../styles/PublicPageNavItemsSocial.module.scss"

function NavItemsPublicSocial() {
  return (
    <ul className={clsx(styles.cPublicNavListSocial, "c-public-nav-list-social")}>
      {PublicPageSocialNavItems.map((link, socialIdx) => (
        <li key={socialIdx}>
          <a
            href={link.url}
            target="_blank"
            rel="noreferrer noopener"
            title={link.title}
          >
            {link.icon}
          </a>
        </li>
      ))}
    </ul>
  )
}

export default NavItemsPublicSocial