import clsx from "clsx"
import { LegalNavItems } from "../../constant/constants"
import Copyright from "./Copyright"
import LanguageSwitcher from "./LanguageSwitcher"
import ButtonLink from "./ButtonLink"
import styles from "../../styles/Footer.module.scss"
function Footer() {

  return (
    <footer className={clsx(styles.cFooter, "c-footer")}>

      <div className="c-footer-wrapper">

        <Copyright
          extraClassName="c-footer-copyright"
        />

        <nav className="c-footer-sub-nav">
          <LanguageSwitcher
            extraClassName="c-footer-language-switcher"
            hideIcon
          />

          {LegalNavItems.map((link, idx) => (
            <ButtonLink
              key={idx}
              url={link.url}
              text={link.title}
              btnVariant="link"
              btnSize="sm"
              btnColor="ghost"
            />
          ))}

        </nav>
      </div>
    </footer>
  )
}

export default Footer
