import { useMemo } from "react"
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react"
import { useTranslation } from "react-i18next"

interface LanguageContextState {
  language: string
  setLanguage: (language: string) => void
}

const LanguageContext = createContext<LanguageContextState>({
  language: "en",
  setLanguage: () => null,
})

const getBrowserLanguage = () => {
  const lang = navigator.languages ? navigator.languages[0] : null
  return (
    lang ||
    navigator.language ||
    (navigator as any).userLanguage ||
    (navigator as any).browserLanguage ||
    "en"
  )?.split("-")[0]
}

export const useLanguage = () => useContext(LanguageContext)

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation()
  const browserLang = getBrowserLanguage()
  const supportedLanguages = ["en", "fr"]
  const defaultLang = supportedLanguages.includes(browserLang)
    ? browserLang
    : "en"

  const [language, setLanguage] = useState(defaultLang)

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language, i18n])

  const value = useMemo(
    () => ({ language, setLanguage }),
    [language, setLanguage],
  )

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}
