import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { NumericFormat } from "react-number-format"
import CustomSectionBoxed from "../common/CustomSectionBoxed"
import Button from "../common/Button"
import {
  getLeaseOptions,
  updateLeaseOptions,
} from "../../services/api/leaseOptionsService"
import { useAppContext } from "../../contexts/AppContext"

const applicationLeasingOptionList = [
  {
    id: "Finance",
    title: "Finance",
    description:
      "This option allows you to finance the purchase of your vehicle through monthly payments. Recommended for those looking to own their car.",
    scenarios: [
      { label: "Term", value: "36", suffix: "months" },
      { label: "Value of vehicle", value: "19500", prefix: "$" },
      { label: "Downpayment of 5%", value: "975", prefix: "$" },
    ],
  },
  {
    id: "Lease with buy-back",
    title: "Lease with buy-back",
    description:
      "A leasing option that provides the opportunity to purchase the vehicle at a predetermined price at the end of the lease term.",
    scenarios: [
      { label: "Term", value: "36", suffix: "months" },
      { label: "Value of vehicle", value: "19500", prefix: "$" },
      { label: "Downpayment of 5%", value: "975", prefix: "$" },
      { label: "Est. Residual", value: "5000", prefix: "$" },
    ],
  },
]

interface FormData {
  lease_option: string
}

const validationSchema = yup.object().shape({
  lease_option: yup.string().required("Please select an option"),
})

function LoanLeasingOptionsForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeLoan } = useAppContext()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      lease_option: "",
    },
  })

  const onSubmit = async (data: FormData) => {
    try {
      await updateLeaseOptions(activeLoan.message, data.lease_option)
      navigate(-1)
    } catch (error) {
      console.error("Failed to update lease options:", error)
    }
  }

  const onReset = () => {
    reset()
    navigate(-1)
  }

  useEffect(() => {
    const fetchLeaseOptions = async () => {
      try {
        const response = await getLeaseOptions(activeLoan.message)
        if (response.status === 200 && response.message) {
          console.log(response.message)
          reset({
            lease_option: response.message ?? "",
          })
        }
      } catch (error) {
        console.error("Failed to fetch lease options:", error)
      }
    }
    fetchLeaseOptions()
  }, [activeLoan.message, reset])

  return (
    <CustomSectionBoxed
      classname="c-loan-leasing-options-section-form"
      headerContentType="title"
      headerTitle="Leasing Options"
      headerTitleParagraph="Below outlines the available leasing options"
    >
      <form
        className="c-loan-leasing-options-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset>
          <legend className="c-visually-hidden">{t("Select an option")}</legend>

          <div className="c-loan-leasing-options-form-grid">
            {applicationLeasingOptionList.map((option, idx) => (
              <div key={idx} className="c-loan-leasing-options-form-grid-item">
                <Controller
                  name="lease_option"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        className="c-visually-hidden"
                        type="radio"
                        value={option.id}
                        id={option.id}
                        checked={field.value === option.id}
                      />
                      <label
                        htmlFor={option.id}
                        className="c-loan-leasing-options-form-label"
                      >
                        <span className="c-loan-leasing-options-form-label-header">
                          <span className="c-loan-leasing-options-form-label-header-title">
                            <span className="c-loan-leasing-options-form-label-header-title-content">
                              {t(option.title)}
                            </span>

                            <span className="c-loan-leasing-options-form-label-header-title-check" />
                          </span>

                          <span className="c-loan-leasing-options-form-label-header-paragraph">
                            {option.description}
                          </span>
                        </span>

                        <span className="c-loan-leasing-options-form-label-body">
                          <span className="c-loan-leasing-options-form-label-body-title">
                            {t("Example Scenario")}
                          </span>

                          <span className="c-loan-leasing-options-form-label-body-list">
                            {option.scenarios.map((scenario, idx) => (
                              <span
                                key={idx}
                                className="c-loan-leasing-options-form-label-body-list-item"
                              >
                                <em>{scenario.label}:</em>
                                <NumericFormat
                                  displayType="text"
                                  prefix={
                                    scenario.prefix
                                      ? scenario.prefix + " "
                                      : undefined
                                  }
                                  suffix={
                                    scenario.suffix
                                      ? " " + scenario.suffix
                                      : undefined
                                  }
                                  value={scenario.value}
                                  thousandSeparator=","
                                  decimalSeparator="."
                                  decimalScale={2}
                                  fixedDecimalScale={!!scenario.prefix}
                                />
                              </span>
                            ))}

                            <span className="c-loan-leasing-options-form-label-body-list-item total">
                              <em>{t("Est. Payment per month")}</em>
                              <NumericFormat
                                displayType="text"
                                prefix="$ "
                                value={0}
                                thousandSeparator=","
                                decimalSeparator="."
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            </span>
                          </span>
                        </span>
                      </label>
                    </>
                  )}
                />
              </div>
            ))}
          </div>
        </fieldset>

        {errors.lease_option && (
          <small className="c-form-error-feedback">
            {errors.lease_option.message}
          </small>
        )}

        <div className="c-button-container">
          <Button text="Cancel" onClick={onReset} />

          <Button
            type="submit"
            btnColor="blue"
            text="Save & close"
            disabled={!isValid || !isDirty}
          />
        </div>
      </form>
    </CustomSectionBoxed>
  )
}

export default LoanLeasingOptionsForm
