// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomAlert_cAlert__naBj0.c-alert {
  --alert-bg-color: #FAFBFB;
  --alert-border-color: var(--medium-light-grey);
  --alert-icon-color: var(--medium-blue-grey);
  display: inline-flex;
  align-items: center;
  gap: var(--px12);
  min-height: var(--px40);
  padding: var(--px8) 1em var(--px8) var(--px8);
  border: var(--px1) solid var(--alert-border-color);
  background-color: var(--alert-bg-color);
  border-radius: var(--px8);
}
.CustomAlert_cAlert__naBj0.c-alert i {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  color: var(--alert-icon-color);
}
.CustomAlert_cAlert__naBj0.c-alert.info {
  --alert-bg-color: #F5FBFD;
  --alert-border-color: var(--light-blue);
  --alert-icon-color: var(--blue-alt);
}
.CustomAlert_cAlert__naBj0.c-alert.success {
  --alert-bg-color: #F8FDF5;
  --alert-border-color: #A6EB83;
  --alert-icon-color: #016908;
}
.CustomAlert_cAlert__naBj0.c-alert.warning {
  --alert-bg-color: #FDFCF5;
  --alert-border-color: #EAC771;
  --alert-icon-color: #C86F32;
}
.CustomAlert_cAlert__naBj0.c-alert.danger {
  --alert-bg-color: #FDF5F5;
  --alert-border-color: #EB8383;
  --alert-icon-color: #690101;
}
.CustomAlert_cAlert__naBj0.c-alert .c-alert-text,
.CustomAlert_cAlert__naBj0.c-alert .c-alert-content {
  font-size: var(--px12);
  line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/styles/CustomAlert.module.scss"],"names":[],"mappings":"AAEI;EACE,yBAAA;EACA,8CAAA;EACA,2CAAA;EAEA,oBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,6CAAA;EACA,kDAAA;EACA,uCAAA;EACA,yBAAA;AAFN;AAIM;EACE,cAAA;EACA,UAAA;EACA,WAAA;EACA,8BAAA;AAFR;AAKM;EACE,yBAAA;EACA,uCAAA;EACA,mCAAA;AAHR;AAMM;EACE,yBAAA;EACA,6BAAA;EACA,2BAAA;AAJR;AAOM;EACE,yBAAA;EACA,6BAAA;EACA,2BAAA;AALR;AAQM;EACE,yBAAA;EACA,6BAAA;EACA,2BAAA;AANR;AASM;;EAIE,sBAAA;EACA,gBAAA;AATR","sourcesContent":[".cAlert {\n  &:global {\n    &.c-alert {\n      --alert-bg-color: #FAFBFB;\n      --alert-border-color: var(--medium-light-grey);\n      --alert-icon-color: var(--medium-blue-grey);\n\n      display: inline-flex;\n      align-items: center;\n      gap: var(--px12);\n      min-height: var(--px40);\n      padding: var(--px8) 1em var(--px8) var(--px8);\n      border: var(--px1) solid var(--alert-border-color);\n      background-color: var(--alert-bg-color);\n      border-radius: var(--px8);\n\n      i {\n        flex-shrink: 0;\n        width: 1em;\n        height: 1em;\n        color: var(--alert-icon-color);\n      }\n\n      &.info {\n        --alert-bg-color: #F5FBFD;\n        --alert-border-color: var(--light-blue);\n        --alert-icon-color: var(--blue-alt);\n      }\n\n      &.success {\n        --alert-bg-color: #F8FDF5;\n        --alert-border-color: #A6EB83;\n        --alert-icon-color: #016908;\n      }\n\n      &.warning {\n        --alert-bg-color: #FDFCF5;\n        --alert-border-color: #EAC771;\n        --alert-icon-color: #C86F32;\n      }\n\n      &.danger {\n        --alert-bg-color: #FDF5F5;\n        --alert-border-color: #EB8383;\n        --alert-icon-color: #690101;\n      }\n\n      .c-alert-text,\n      .c-alert-content {\n        //display: inline-flex;\n        //align-items: center;\n        font-size: var(--px12);\n        line-height: 1.4;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cAlert": `CustomAlert_cAlert__naBj0`
};
export default ___CSS_LOADER_EXPORT___;
