import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import * as yup from "yup"
import CustomInputRadioCheckbox from "../form-elements/CustomInputRadioCheckbox"

interface FormData {
  application_consent?: boolean;
}

const schema = yup.object().shape({
  application_consent: yup.boolean(),
  // application_consent: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
})

function LoanInlineConsentForm() {
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    formState: { isSubmitted }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      application_consent: false, // Set default value for the checkbox
    },
  })

  const onSubmit = (data:FormData) => {
    console.log(data)
  }

  const handleClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onClick={handleClick}>
      <fieldset>
        <legend className="c-visually-hidden">
          {t("Agree to terms & conditions")}
        </legend>

        <CustomInputRadioCheckbox
          type="checkbox"
          labelText={isSubmitted ? "Agreed to credit check" : "Agree to terms"}
          labelIsButton
          btnColor="blue"
          btnSize="md"
          id="id_application_consent"
          name="application_consent"
          control={control}
          disabled={isSubmitted}
        />

      </fieldset>

    </form>
  )
}

export default LoanInlineConsentForm