import {Fragment, ReactNode, useState} from "react"
import {useTranslation} from "react-i18next"
import clsx from "clsx"
import type { ColumnDef } from '@tanstack/react-table'
import {useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, PaginationState} from '@tanstack/react-table'
import Button from "./Button"
import {ReactComponent as IcnPrevious} from "../../assets/icons/icn-chevron-left.svg"
import {ReactComponent as IcnNext} from "../../assets/icons/icn-chevron-right.svg"

interface TableProps<T extends object> {
  extraClassName?: string;
  data: T[];
  columns: ColumnDef<T>[];
  tableHasCaption?: boolean;
  tableCaptionTitle?: string;
  customDefaultEmpty?: boolean;
  defaultEmptyHeading?: string;
  defaultEmptyTitle?: string;
  defaultEmptyExtraContent?: ReactNode;
  removePagination?: boolean;
  pageSize?: number;
}

function CustomTable<T extends object>(
  {
    extraClassName,
    data,
    columns,
    tableHasCaption,
    tableCaptionTitle,
    customDefaultEmpty,
    defaultEmptyHeading,
    defaultEmptyTitle,
    defaultEmptyExtraContent,
    removePagination,
    pageSize = 5,
  }: TableProps<T>) {
  const { t } = useTranslation()

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  })

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  })

  const totalPageCount = Math.ceil(table.getCoreRowModel().rows.length / pagination.pageSize)

  const allCellsEmpty = table
    .getRowModel()
    .rows.every((row) =>
      row.getVisibleCells().every((cell) => {
        const cellValue = flexRender(
          cell.column.columnDef.cell,
          cell.getContext()
        )
        return !cellValue || cellValue === ""
      })
    )

  const repeatedTfootRows = Array.from({ length: 5 }, (_, index) => (
    <Fragment key={index}>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <td key={header.id}>
              <span className="c-table-tbody-td">
                {header.isPlaceholder
                  ? null
                  : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              </span>
            </td>
          ))}
        </tr>
      ))}
    </Fragment>
  ))

  return (
    <>
      <div className={clsx("c-table-wrapper", allCellsEmpty && "default-empty")}>

        <table className={clsx("c-table", extraClassName && extraClassName)}>

          {tableHasCaption && (
            <caption>
              <div>{tableCaptionTitle}</div>
            </caption>
          )}

          <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <span className="c-table-thead-th">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          </thead>

          <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <div className="c-table-tbody-td">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
          </tbody>


          {allCellsEmpty && (
            <tfoot>
            <tr className="c-table-tfoot-tr-default-empty">

              <td colSpan={columns.length}>

                <div className="c-table-tfoot-tr-default-empty-wrapper">

                  <div className="c-table-tfoot-tr-default-empty-text">
                    {customDefaultEmpty ? (
                      <>
                        {defaultEmptyHeading && (<small>{t(defaultEmptyHeading)}</small>)}
                        {defaultEmptyTitle && (<p className="h5">{t(defaultEmptyTitle)}</p>)}
                        {defaultEmptyExtraContent && defaultEmptyExtraContent}
                      </>
                    ):(
                      <>
                        <small>{t("No data found")}</small>
                        <h3 className="h5">{t("There is no data available at this moment")}</h3>
                      </>
                    )}
                  </div>

                  <table className={clsx("c-table-skeleton")}>
                    <tbody>
                    {repeatedTfootRows}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            </tfoot>
          )}

        </table>

      </div>

      {!removePagination && (
        <div className="c-table-pagination">

          <div className="c-button-container">
            {[...Array(totalPageCount)].map((_, pageIndex) => (
              <Button
                key={pageIndex}
                extraClassName={clsx("c-table-pagination-btn index", table.getState().pagination.pageIndex === pageIndex && "is-active",)}
                btnVariant="icon"
                btnSize="sm"
                icon={pageIndex + 1}
                onClick={() => table.setPageIndex(pageIndex)}
                disabled={totalPageCount < 2}
              />
            ))}

          </div>

          <div className="c-button-container">

            <Button
              extraClassName="c-table-pagination-btn previous"
              btnVariant="icon"
              title="Previous"
              btnSize="sm"
              icon={<IcnPrevious/>}
              iconSize="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            />

            <Button
              extraClassName="c-table-pagination-btn next"
              btnVariant="icon"
              btnSize="sm"
              title="Next"
              icon={<IcnNext/>}
               iconSize="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />

          </div>

        </div>

      )}
    </>
  )
}

export default CustomTable
