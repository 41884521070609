import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { NumericFormat } from "react-number-format"
import {
  getLeaseDetails,
  updateLeaseDetails,
} from "../../services/api/leaseDetails"
import { LeaseDetails } from "../../types/leaseDetails"
import { LeaseDetailsFormTermList } from "../../constant/constants"
import CustomSectionBoxed from "../common/CustomSectionBoxed"
import CustomNumberInput from "../form-elements/CustomInputNumber"
import CustomSelect from "../form-elements/CustomSelect"
import Button from "../common/Button"
import { useAppContext } from "../../contexts/AppContext"

const schema = yup.object().shape({
  value_of_lease: yup.number().required("Car value amount is required"),
  lease_term: yup.string().required("Term is required"),
  down_payment: yup
    .number()
    .required("Downpayment amount is required")
    .test(
      "is-greater-than-five-percent-of-car-value",
      "Downpayment must be equal to or greater than 5% of the car's value",
      function (value) {
        const { value_of_lease } = this.parent
        return value >= value_of_lease * 0.05
      },
    )
    .test(
      "is-less-than-seventy-percent-of-car-value",
      "Downpayment must not exceed 70% of the car's value",
      function (value) {
        const { value_of_lease } = this.parent
        return value <= value_of_lease * 0.7
      },
    ),
  requested_lease_amount: yup.number(),
})

function LoanLeaseDetailsForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeLoan } = useAppContext()

  const [requestedLeaseAmount, setRequestedLeaseAmount] = useState(0)

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      value_of_lease: 0,
      lease_term: "",
      down_payment: 0,
      requested_lease_amount: 0,
    },
  })

  const carValue = watch("value_of_lease")
  const downPayment = watch("down_payment")

  useEffect(() => {
    const fetchLeaseDetails = async () => {
      try {
        const response = await getLeaseDetails(activeLoan.message)
        if (response.status === 200 && response.message) {
          reset({
            value_of_lease: response.message.value_of_lease,
            lease_term: response.message.lease_term,
            down_payment: response.message.down_payment,
            requested_lease_amount: response.message.requested_lease_amount,
          })
        }
      } catch (error) {
        console.error("Failed to fetch lease details:", error)
      }
    }
    fetchLeaseDetails()
  }, [activeLoan.message, reset])

  useEffect(() => {
    setRequestedLeaseAmount(carValue - downPayment)
  }, [carValue, downPayment])

  const onSubmit = async (data: LeaseDetails) => {
    console.log("Form data:", data)
    try {
      await updateLeaseDetails(activeLoan.message, data)
      navigate(-1)
    } catch (error) {
      console.error("Failed to update lease details:", error)
    }
  }

  const onReset = () => {
    reset()
    navigate(-1)
  }

  return (
    <CustomSectionBoxed
      classname="c-loan-lease-details-section-form"
      headerContentType="title"
      headerTitle="Value of Lease"
      headerTitleParagraph="Below outlines the estimated lease value terms"
    >
      <form
        className="c-loan-lease-details-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset>
          <legend>
            <span>{t("What is the value of the car?")}</span>
            <small>{t("Please list the car's estimated valuation.")}</small>
          </legend>

          <CustomNumberInput
            labelText="Value of car"
            name="value_of_lease"
            prefix="$"
            control={control}
            helpText="The total cost you expect to pay."
          />
        </fieldset>

        <fieldset>
          <legend>
            <span>{t("What is your preferred loan term?")}</span>
            <small>
              {t("Please select one of the following terms options.")}
            </small>
          </legend>

          <CustomSelect
            labelText={t("What is your preferred loan term?")}
            name="lease_term"
            control={control}
            options={LeaseDetailsFormTermList}
            placeholder={t("Select a term")}
            defaultValue=""
          />
        </fieldset>

        <fieldset>
          <legend>
            <span>{t("Downpayment")}</span>
            <small>{t("A minimum downpayment of 5% is necessary.")}</small>
          </legend>

          <CustomNumberInput
            labelText="Downpayment"
            name="down_payment"
            prefix="$"
            control={control}
          />
        </fieldset>

        <div className="c-loan-lease-details-form-total c-with-dashed-border">
          <span className="c-loan-lease-details-form-total-label">
            {t("Requested lease amount")}
          </span>

          <NumericFormat
            className="c-loan-lease-details-form-total-value"
            displayType="text"
            prefix="$ "
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale
            value={requestedLeaseAmount}
            readOnly
          />
        </div>

        <div className="c-button-container">
          <Button text="Cancel" onClick={onReset} />

          <Button
            type="submit"
            btnColor="blue"
            text="Save & close"
            disabled={!isValid || !isDirty}
          />
        </div>
      </form>
    </CustomSectionBoxed>
  )
}

export default LoanLeaseDetailsForm
