import clsx from "clsx"
import LoanPendingLayout from "../layouts/LoanPendingLayout"
import LoanLeaseDetailsForm from "../components/forms/LoanLeaseDetailsForm"
import styles from "../styles/LoanSubpageLeaseValue.module.scss"

function LoanPendingLeaseDetails() {

  return (
    <LoanPendingLayout
      subPageTitle="Lease Details"
      subPageExtraClassName={clsx(styles.cPageLeaseDetails, "c-page-lease-details")}
      sectionExtraClassName="c-loan-lease-details"
    >

        <LoanLeaseDetailsForm/>

    </LoanPendingLayout>
  )
}

export default LoanPendingLeaseDetails