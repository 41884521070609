import {useTranslation} from "react-i18next"
import clsx from "clsx"
import { useMediaQuery } from "usehooks-ts"
import { responsiveBreakpoints } from "../constant/constants"
import ButtonLink from "./common/ButtonLink"
import HomeHeroImage from "../assets/images/home-section-hero-img-x2.webp"
import HomeHeroImageMobile from "../assets/images/home-section-hero-img-x2-mobile.webp"
import styles from "../styles/HomeSectionHero.module.scss"

function HomeSectionHero() {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  return (
    <section className={clsx(styles.cHomeSectionHero, "c-home-section-hero")}>

      <div className="c-home-section-hero-header">

        <div className="c-home-section-hero-header-text">

          <div className="c-home-section-hero-header-text-wrapper">

            <span>
              {/* TODO: francisca - trust pilot comes here */}
            </span>

            <h1 className="c-font-staatliches">
              {t("The modern way to finance new or used cars")}
            </h1>

            <p className="c-text-px18-responsive">
              {t("Leasyfi offers quick and flexible car financing. Check your eligibility in just 60 seconds with no impact to your credit score.")}
            </p>

            <div className="c-button-container home-section-button-container">

              <ButtonLink
                url="/apply"
                text="Get pre-approved"
                btnColor="blue"
                btnSize="md"
              />

              <small>{t("No initial credit check")}</small>

            </div>

          </div>

        </div>


        <div className="c-home-section-hero-header-image">

          <div className="c-home-section-hero-header-image-wrapper">
            <img src={isMobileBreakpoint? HomeHeroImageMobile: HomeHeroImage} alt="Leasyfi"/>
          </div>

        </div>

      </div>

      <div className="c-home-section-hero-footer">

        <div className="c-home-section-hero-footer-wrapper">

          <h2 className="c-font-staatliches">
            {t("It takes long to get approved at a dealership")}
          </h2>

          <p className="c-text-px18-responsive">
            {t("At Leasyfi, obtain near-instant online pre-approval, allowing you to arrive at the dealership with a cheque ready in hand to purchase your car.")}
          </p>

        </div>

      </div>

    </section>
  )
}

export default HomeSectionHero