import clsx from "clsx"
import { IPreApprovalStatusComponent } from "../types/preApproval"
import PreApprovalLayout from "../layouts/PreApprovalLayout"
import PreApprovalSectionStatusGrid from "../components/PreApprovalSectionStatusGrid"
import { ReactComponent as IcnApproved } from "../assets/icons/icn-clipboard-check.svg"
import styles from "../styles/PreApprovalStatus.module.scss"

const PreApprovalStatusValid = (props: IPreApprovalStatusComponent) => {
  return (
    <PreApprovalLayout
      pageExtraClassName={clsx(
        styles.cPreApprovalStatus,
        "c-page-pre-approval-status",
        "approved",
      )}
      pageExtraTitle="Approved"
      navbarUserFirstName={props.firstName}
      navbarBadgeType="success"
      navbarBadgeText="Approved"
    >
      <PreApprovalSectionStatusGrid
        icon={<IcnApproved />}
        preApprovalStatusData={props}
        iconType="info"
        title="Passed Pre-Approval!"
        subtitle="Your pre-approval application has been accepted, and we're excited to have you join Leasyfi!"
      />
    </PreApprovalLayout>
  )
}

export default PreApprovalStatusValid
