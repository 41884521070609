export const DummyOptionList = [
  { label: "Chocolate", value: "chocolate" },
  { label: "Vanilla", value: "vanilla" },
  { label: "Strawberry", value: "strawberry" },
  { label: "Mango", value: "mango" },
]

export const PreApprovalFormJobStatus = [
  { label: "Full-Time", value: "Full-Time" },
  { label: "Part-Time", value: "Part-Time" },
  { label: "Contractor", value: "Contractor" },
  { label: "Self-Employed", value: "Self-Employed" },
  { label: "Retired", value: "Retired" },
  { label: "Unemployed", value: "Unemployed" },
  { label: "Other", value: "Other" },
]

export const ApplicationVehicleInformationModelOptions = [
  { label: "Some Model 1", value: "model-1" },
  { label: "Some Model 2", value: "model-2" },
]

export const DummyDocumentsList = [
  {
    id: "1",
    name: "file_example.jpg",
    size: 123456,
    status: "pending",
    source: "user",
    date: "2024-02-26",
    relatedTo: "2023 audi Q8 E-Tron",
  },
  {
    id: "2",
    size: 456956,
    name: "file_example_2.jpg",
    status: "approved",
    source: "user",
    date: "2024-02-27",
    relatedTo: "User details",
  },
  {
    id: "3",
    size: 456956,
    name: "file_example_3.jpg",
    status: "official",
    source: "admin",
    date: "2024-03-20",
    relatedTo: "2023 audi Q8 E-Tron",
  },
  {
    id: "4",
    size: 289568,
    name: "file_example_4.jpg",
    status: "declined",
    source: "admin",
    date: "2024-03-20",
    relatedTo: "User details",
  },
]

export const DummyLoanList = [
  {
    id: "185715",
    vehicle: "2021 Ford F-150 XLT",
    submittedDate: "2024-04-12",
    modificationDate: "",
    modificationTime: "",
    status: "pending",
    loanAmount: 35000,
    loanPeriod: 48,
    loanBalance: null,
    upcomingPayment: "",
    upcomingAmountDue: null,
    history: [],
  },
  {
    id: "185714",
    vehicle: "2019 Chevrolet Malibu LT",
    submittedDate: "2024-04-12",
    modificationDate: "2024-04-14",
    modificationTime: "10:23 AM",
    status: "pending",
    loanAmount: 28000,
    loanPeriod: 48,
    loanBalance: null,
    upcomingPayment: "",
    upcomingAmountDue: null,
    history: [],
  },
  {
    id: "185713",
    vehicle: "2022 Honda Civic Sport",
    submittedDate: "2024-04-12",
    modificationDate: "",
    modificationTime: "",
    status: "pending",
    loanAmount: 35000,
    loanPeriod: 48,
    loanBalance: null,
    upcomingPayment: "",
    upcomingAmountDue: null,
    history: [],
  },
  {
    id: "185712",
    vehicle: "2020 Toyota Camry SE",
    submittedDate: "2024-04-12",
    modificationDate: "",
    modificationTime: "",
    status: "pending",
    loanAmount: 35000,
    loanPeriod: 48,
    loanBalance: null,
    upcomingPayment: "",
    upcomingAmountDue: null,
    history: [],
  },
  {
    id: "185711",
    vehicle: "2023 Audi Q5 Premium Plus",
    submittedDate: "2024-04-12",
    modificationDate: "",
    modificationTime: "",
    status: "pending",
    loanAmount: 35000,
    loanPeriod: 48,
    loanBalance: null,
    upcomingPayment: "",
    upcomingAmountDue: null,
    history: [],
  },
  {
    id: "185710",
    vehicle: "2023 Audi Q5 Premium Plus",
    submittedDate: "2024-04-12",
    modificationDate: "",
    modificationTime: "",
    status: "pending",
    loanAmount: 35000,
    loanPeriod: 48,
    loanBalance: null,
    upcomingPayment: "",
    upcomingAmountDue: null,
    history: [],
  },
  {
    id: "185709",
    vehicle: "2023 Audi Q8 E-tron",
    submittedDate: "2024-04-12",
    modificationDate: "",
    modificationTime: "",
    status: "approved",
    loanAmount: 35000,
    loanPeriod: 48,
    loanBalance: 33541.68,
    upcomingPayment: "2024-07-14",
    upcomingAmountDue: 729.16,
    history: [
      {
        id: "1",
        paymentNumber: "1",
        date: "2024-04-14",
        status: "paid",
        amountDue: 729.16,
      },
      {
        id: "2",
        paymentNumber: "2",
        date: "2024-05-14",
        status: "incomplete",
        amountDue: 729.16,
      },
      {
        id: "3",
        paymentNumber: "2",
        date: "2024-05-15",
        status: "paid",
        amountDue: 729.16,
      },
      {
        id: "4",
        paymentNumber: "3",
        date: "2024-06-14",
        status: "paid",
        amountDue: 729.16,
      },
      {
        id: "5",
        paymentNumber: "4",
        date: "2024-07-14",
        status: "paid",
        amountDue: 729.16,
      },
      {
        id: "6",
        paymentNumber: "5",
        date: "2024-08-14",
        status: "paid",
        amountDue: 729.16,
      },
      // {
      //   id: "7",
      //   paymentNumber: "6",
      //   date: "2024-09-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "8",
      //   paymentNumber: "7",
      //   date: "2024-10-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "9",
      //   paymentNumber: "8",
      //   date: "2024-11-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "10",
      //   paymentNumber: "9",
      //   date: "2024-12-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "11",
      //   paymentNumber: "10",
      //   date: "2025-01-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "12",
      //   paymentNumber: "11",
      //   date: "2025-02-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "13",
      //   paymentNumber: "12",
      //   date: "2025-03-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "14",
      //   paymentNumber: "13",
      //   date: "2025-04-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "15",
      //   paymentNumber: "14",
      //   date: "2025-05-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "16",
      //   paymentNumber: "15",
      //   date: "2025-06-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "17",
      //   paymentNumber: "16",
      //   date: "2025-07-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "18",
      //   paymentNumber: "17",
      //   date: "2025-08-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "19",
      //   paymentNumber: "18",
      //   date: "2025-09-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "20",
      //   paymentNumber: "19",
      //   date: "2025-10-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "21",
      //   paymentNumber: "20",
      //   date: "2025-11-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "22",
      //   paymentNumber: "21",
      //   date: "2025-12-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "23",
      //   paymentNumber: "22",
      //   date: "2026-01-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "24",
      //   paymentNumber: "23",
      //   date: "2026-02-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "25",
      //   paymentNumber: "24",
      //   date: "2026-03-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "26",
      //   paymentNumber: "25",
      //   date: "2026-04-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "27",
      //   paymentNumber: "26",
      //   date: "2026-05-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "28",
      //   paymentNumber: "27",
      //   date: "2026-06-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "29",
      //   paymentNumber: "28",
      //   date: "2026-07-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "30",
      //   paymentNumber: "29",
      //   date: "2026-08-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "31",
      //   paymentNumber: "30",
      //   date: "2026-09-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "32",
      //   paymentNumber: "31",
      //   date: "2026-10-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "33",
      //   paymentNumber: "32",
      //   date: "2026-11-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "34",
      //   paymentNumber: "33",
      //   date: "2026-12-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "35",
      //   paymentNumber: "34",
      //   date: "2027-01-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "36",
      //   paymentNumber: "35",
      //   date: "2027-02-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "37",
      //   paymentNumber: "36",
      //   date: "2027-03-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "38",
      //   paymentNumber: "37",
      //   date: "2027-04-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "39",
      //   paymentNumber: "38",
      //   date: "2027-05-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "40",
      //   paymentNumber: "39",
      //   date: "2027-06-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "41",
      //   paymentNumber: "40",
      //   date: "2027-07-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "42",
      //   paymentNumber: "41",
      //   date: "2027-08-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "43",
      //   paymentNumber: "42",
      //   date: "2027-09-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "44",
      //   paymentNumber: "43",
      //   date: "2027-10-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "45",
      //   paymentNumber: "44",
      //   date: "2027-11-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "46",
      //   paymentNumber: "45",
      //   date: "2027-12-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "47",
      //   paymentNumber: "46",
      //   date: "2028-01-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
      // {
      //   id: "48",
      //   paymentNumber: "47",
      //   date: "2028-02-14",
      //   status: "paid",
      //   amountDue: 729.16,
      // },
    ],
  },
  {
    id: "185708",
    vehicle: "2019 Chevrolet Malibu LT",
    submittedDate: "2024-04-12",
    modificationDate: "",
    modificationTime: "",
    status: "approved",
    loanAmount: 28500,
    loanPeriod: 48,
    loanBalance: 28500,
    upcomingPayment: "2024-06-14",
    upcomingAmountDue: null,
    history: [],
  },
]
