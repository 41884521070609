// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_cLogo__0tzhy {
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: var(--px162);
}`, "",{"version":3,"sources":["webpack://./src/styles/Logo.module.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;EACA,mBAAA;EACA,WAAA;EACA,uBAAA;AAAJ","sourcesContent":[".cLogo {\n  :global {\n    display: inline-flex;\n    align-items: center;\n    width: 100%;\n    max-width: var(--px162);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cLogo": `Logo_cLogo__0tzhy`
};
export default ___CSS_LOADER_EXPORT___;
