import {useTranslation} from "react-i18next"
import {NavLink} from "react-router-dom"
import {useMediaQuery} from "usehooks-ts"
import {DashboardNavItems, responsiveBreakpoints} from "../constant/constants"
import DashboardMobileMenu from "./DashboardMobileMenu"

function DashboardSidebarNavItems() {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  return (
    <nav className="c-sidebar-navbar">

      <ul className="c-sidebar-navbar-nav">

        {DashboardNavItems.map((item, idx) => (

          <li key={idx} className={item.id}>

            <NavLink
              className="c-sidebar-navbar-nav-link"
              to={item.url}
              title={t(item.title)}
            >
              <i>{item.icon}</i>

              <span>
                {isMobileBreakpoint? t(item.mobileTitle): t(item.title)}
              </span>

            </NavLink>

          </li>
        ))}

        {isMobileBreakpoint && (
          <li className="menu">
            <DashboardMobileMenu/>
          </li>
        )}

      </ul>

    </nav>
  )
}

export default DashboardSidebarNavItems