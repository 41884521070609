import { ReactNode } from "react"
import clsx from "clsx"
import BaseLayout from "./BaseLayout"
import Header from "../components/common/Header"
import NavbarPublic from "../components/common/NavbarPublic"
import Main from "../components/common/Main"
import SubFooter from "../components/common/SubFooter"
import Footer from "../components/common/Footer"
import styles from "../styles/PublicPageLayout.module.scss"
interface PublicPageLayoutProps {
  bodyClassName?: string;
  pageTitle: string;
  pageClassName?: string;
  headerChildren?: ReactNode;
  children?: ReactNode;
  hideFooter?: boolean;
}
function PublicPageLayout(
  {
    pageTitle,
    pageClassName,
    children,
  }: PublicPageLayoutProps) {

  return (
    <BaseLayout
      bodyClassName="c-body-home"
      pageTitle={pageTitle}
      pageClassName={clsx(styles.cPagePublic, "c-page-public", pageClassName && pageClassName)}
    >
      <Header>
        <NavbarPublic/>
      </Header>

      <Main>
        {children}
      </Main>

      <SubFooter/>
      <Footer/>

    </BaseLayout>
  )
}

export default PublicPageLayout
