import { Routes, Route, Navigate } from "react-router-dom"
import { useAuth } from "./contexts/AuthContext"
import AccountSettings from "./views/AccountSettings"
import Home from "./views/Home"
import CommercialLeasing from "./views/CommercialLeasing"
import PreApproval from "./views/pre-approval/PreApproval"
import Login from "./views/Login"
import SignUp from "./views/SignUp"
import ForgotPassword from "./views/ForgotPassword"
import ResetPassword from "./views/ResetPassword"
import Support from "./views/Support"
import Terms from "./views/Terms"
import PrivacyPolicy from "./views/PrivacyPolicy"
import Dashboard from "./views/Dashboard"
import Documents from "./views/Documents"
import LoanNew from "./views/LoanNew"
import LoanNewAdditionalDocuments from "./views/LoanNewAdditionalDocuments"
import LoanNewConsent from "./views/LoanNewConsent"
import LoanNewLeaseDetails from "./views/LoanNewLeaseDetails"
import LoanNewVehicleDetails from "./views/LoanNewVehicleDetails"
import LoanNewLeasingOptions from "./views/LoanNewLeasingOptions"
import LoanPending from "./views/LoanPending"
import LoanPendingAdditionalDocuments from "./views/LoanPendingAdditionalDocuments"
import LoanPendingConsent from "./views/LoanPendingConsent"
import LoanPendingLeaseDetails from "./views/LoanPendingLeaseDetails"
import LoanPendingVehicleDetails from "./views/LoanPendingVehicleDetails"
import LoanPendingLeasingOptions from "./views/LoanPendingLeasingOptions"
import LoanApproved from "./views/LoanApproved"
import NotFound from "./views/NotFound"
import PreApprovalStatus from "./views/PreApprovalStatus"
import { ProtectedRoute } from "./ProtectedRoute"

const AppRoutes = () => {
  const { isAuthenticated } = useAuth()

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/commercial-leasing" element={<CommercialLeasing />} />
      <Route
        path="/apply"
        element={
          isAuthenticated ? <Navigate to="/dashboard" /> : <PreApproval />
        }
      />
      <Route path="/application-status" element={<PreApprovalStatus />} />
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route path="/signup" element={<SignUp />} />
      <Route
        path="/forgot-password"
        element={
          isAuthenticated ? <Navigate to="/dashboard" /> : <ForgotPassword />
        }
      />
      <Route
        path="/reset-password"
        element={
          isAuthenticated ? <Navigate to="/dashboard" /> : <ResetPassword />
        }
      />
      <Route path="/support" element={<Support />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFound />} />

      {/* Protected routes */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/documents"
        element={
          <ProtectedRoute>
            <Documents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/account-settings"
        element={
          <ProtectedRoute>
            <AccountSettings />
          </ProtectedRoute>
        }
      />

      {/* Loan Pending Routes */}
      <Route
        path="/loan/pending"
        element={
          <ProtectedRoute>
            <LoanPending />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/pending/additional-documents"
        element={
          <ProtectedRoute>
            <LoanPendingAdditionalDocuments />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/pending/consent"
        element={
          <ProtectedRoute>
            <LoanPendingConsent />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/pending/lease-value"
        element={
          <ProtectedRoute>
            <LoanPendingLeaseDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/pending/vehicle-information"
        element={
          <ProtectedRoute>
            <LoanPendingVehicleDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/pending/leasing-options"
        element={
          <ProtectedRoute>
            <LoanPendingLeasingOptions />
          </ProtectedRoute>
        }
      />

      {/* Loan New Routes */}
      <Route
        path="/loan/new"
        element={
          <ProtectedRoute>
            <LoanNew />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/new/additional-documents"
        element={
          <ProtectedRoute>
            <LoanNewAdditionalDocuments />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/new/consent"
        element={
          <ProtectedRoute>
            <LoanNewConsent />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/new/lease-value"
        element={
          <ProtectedRoute>
            <LoanNewLeaseDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/new/vehicle-information"
        element={
          <ProtectedRoute>
            <LoanNewVehicleDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/loan/new/leasing-options"
        element={
          <ProtectedRoute>
            <LoanNewLeasingOptions />
          </ProtectedRoute>
        }
      />

      <Route
        path="/loan/approved"
        element={
          <ProtectedRoute>
            <LoanApproved />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
