import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { useDropzone } from "react-dropzone"
import moment from "moment"
import { useMediaQuery } from "usehooks-ts"
import { FileItemProps } from "../types/document"
import { useAppContext } from "../contexts/AppContext"
import { bytesToMB, responsiveBreakpoints } from "../constant/constants"
import {
  getAllDocuments,
  addUserDocuments,
} from "../services/api/documentsService"
import DashboardLayout from "../layouts/DashboardLayout"
import CustomTable from "../components/common/CustomTable"
//import CustomBadge from "../components/common/CustomBadge"
import CustomAlert from "../components/common/CustomAlert"
import DropzoneText from "../components/common/DropzoneText"
import Button from "../components/common/Button"
import { ReactComponent as IcnFile } from "../assets/icons/icn-file.svg"
import { ReactComponent as IcnPdfFile } from "../assets/icons/icn-file-pdf.svg"
import { ReactComponent as IcnArrowUp } from "../assets/icons/icn-arrow-up.svg"
import { ReactComponent as IcnDelete } from "../assets/icons/icn-close.svg"
import styles from "../styles/Documents.module.scss"

const generateColumns = ({ t, isMobileBreakpoint, userName }: any) => {
  return [
    {
      accessorKey: "name",
      header: () => <>{t("File name")}</>,
      cell: ({ row }: any) => (
        <>
          {!isMobileBreakpoint && (
            <i>
              <IcnFile />
            </i>
          )}
          <span>
            {" "}
            {row.original.file_name
              ? row.original.file_name
              : row.original.name}
          </span>

          {!isMobileBreakpoint && (
            <small className="c-table-file-details">
              <em>
                {bytesToMB(
                  row.original.file_size
                    ? row.original.file_size
                    : row.original.size,
                )}
                &nbsp;{t("Mb")}
              </em>
              <em>
                {row.original.source === "admin" ? "Leasyfi Team" : userName}
              </em>
            </small>
          )}
        </>
      ),
    },
    {
      accessorKey: "date",
      header: () => <>{t("Date Uploaded")}</>,
      cell: ({ row }: any) => (
        <>
          <time>
            {moment(row.original.date_uploaded || row.original.date).format(
              "MMM D, YYYY",
            )}
          </time>
        </>
      ),
    },
    {
      id: "related",
      header: () => <>{t("Related To")}</>,
      cell: ({ row }: any) => (
        <>{row.original.relatedTo ? row.original.relatedTo : "User details"}</>
      ),
    },
    {
      id: "action",
      header: () => <span className="is-hidden">{t("Action")}</span>,
      cell: ({ row }: any) => (
        <Button
          btnVariant="link"
          text="Download file"
          icon={<IcnArrowUp />}
          iconSize="sm"
          iconPlacement="right"
        />
      ),
    },
  ]
}

const Documents = () => {
  const { t } = useTranslation()
  const { fullName } = useAppContext()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const [files, setFiles] = useState<FileItemProps[]>([])
  const [initialData, setInitialData] = useState<FileItemProps[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<FileItemProps[]>([])
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])

  useEffect(() => {
    const fetchAllDocs = async () => {
      try {
        const response = await getAllDocuments()
        setInitialData(response.message)
      } catch (e: any) {
        console.log(e)
      }
    }
    fetchAllDocs()
  }, [])

  useEffect(() => {
    setUploadedFiles(initialData)
  }, [initialData])

  const { getRootProps, getInputProps, fileRejections, isFocused } =
    useDropzone({
      accept: {
        "image/*": [".jpeg", ".png", ".jpg", ".webp"],
        "application/pdf": [".pdf"],
      },
      maxSize: 4194304, // 4MB in bytes
      onDrop: (acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
          date: new Date().toISOString(),
          status: "pending",
          source: "user",
          relatedTo: "User details",
        }))
        setFiles((prevFiles) => [...prevFiles, ...newFiles])
        setSelectedFiles((prevSelectedFiles) => [
          ...prevSelectedFiles,
          ...acceptedFiles,
        ])
      },
    })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      <span>
        {file.name} - {bytesToMB(file.size)}&nbsp;{t("Mb")}
      </span>
      <ul>
        {errors.map((e) => (
          <li key={e.code}>
            <small>{t(e.message)}</small>
          </li>
        ))}
      </ul>
    </li>
  ))

  const onSubmitDocument = async () => {
    try {
      const formData = new FormData()
      selectedFiles.forEach((file) => {
        formData.append("documents_data", file)
      })

      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1])
      }

      await addUserDocuments(formData)
      setUploadedFiles(
        [...uploadedFiles, ...files].sort(
          (a, b) =>
            moment(b.date_uploaded).valueOf() -
            moment(a.date_uploaded).valueOf(),
        ),
      )
      setFiles([])
      setSelectedFiles([])
    } catch (error) {
      console.error("Error uploading files:", error)
    }
  }

  const handleDelete = (index: number) => {
    setFiles((prevFile) => prevFile.filter((_, i) => i !== index))
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.filter((_, i) => i !== index),
    )
  }

  const columns = generateColumns({ t, isMobileBreakpoint, fullName })

  return (
    <DashboardLayout
      pageTitle="Documents"
      navbarTitle="Documents"
      pageClassName={clsx(styles.cPageDocuments, "c-page-documents")}
      navbarHasBackLink={isMobileBreakpoint}
      navbarBackLinkText={isMobileBreakpoint ? "" : "Dashboard"}
      navbarBackLinkTitle="Back to Dashboard"
      navbarBackLinkVariant="icon"
    >
      <section className="c-documents">
        <section className="c-documents-section-dropzone">
          <div className="c-documents-section-dropzone-wrapper">
            <div
              {...getRootProps({ className: "dropzone" })}
              className={clsx(
                "c-form-group-file-dropzone",
                "c-with-dashed-border",
                isFocused && "is-focused",
              )}
            >
              <input {...getInputProps()} />
              <DropzoneText />
            </div>

            {fileRejections.length > 0 && (
              <CustomAlert
                extraClassName="c-form-group-file-dropzone-alert rejected-files"
                alertType="danger"
                alertContent={
                  <>
                    <p>{t("The following files cannot be uploaded:")}</p>
                    <ul>{fileRejectionItems}</ul>
                  </>
                }
              />
            )}

            {files.length > 0 && (
              <aside className="c-form-group-file-dropzone-list">
                <div className="c-form-group-file-dropzone-list-title">
                  <p className="legend">{t("Selected Files")}</p>
                </div>

                <ul>
                  {files.map((file, index) => (
                    <li key={index}>
                      <i>
                        {file.type === "application/pdf" ? (
                          <IcnPdfFile />
                        ) : (
                          <IcnFile />
                        )}
                      </i>

                      <div>
                        <p title={file.name}>{file.name}</p>

                        <small>
                          {file.size && bytesToMB(file.size)}&nbsp;{t("Mb")}
                        </small>
                      </div>

                      <Button
                        text={isMobileBreakpoint ? undefined : "Delete"}
                        title="Delete"
                        btnVariant={isMobileBreakpoint ? "icon" : undefined}
                        btnColor="white-outline"
                        btnSize={isMobileBreakpoint ? "xs" : "md"}
                        icon={isMobileBreakpoint && <IcnDelete />}
                        iconSize="xs"
                        onClick={() => handleDelete(index)}
                      />
                    </li>
                  ))}
                </ul>

                <div className="c-button-container">
                  <Button text="Clear" />

                  <Button
                    btnColor="blue"
                    text="Upload files"
                    onClick={onSubmitDocument}
                    disabled={files.length === 0}
                  />
                </div>
              </aside>
            )}
          </div>
        </section>

        <section className="c-documents-section-table">
          <CustomTable
            extraClassName={clsx("is-responsive", "c-documents-table")}
            data={uploadedFiles}
            columns={columns}
            tableHasCaption
            tableCaptionTitle="Uploaded documents"
            customDefaultEmpty
            pageSize={5}
            defaultEmptyHeading="No uploaded documents"
            defaultEmptyTitle="All your uploaded documents will appear here"
          />
        </section>
      </section>
    </DashboardLayout>
  )
}

export default Documents
