import React, {ReactNode, useState} from "react"
import {useTranslation} from "react-i18next"
import {Controller} from "react-hook-form"
import clsx from "clsx"
import {ReactComponent as IcnShow} from "../../assets/icons/icn-password-show.svg"
import {ReactComponent as IcnHide} from "../../assets/icons/icn-password-hide.svg"
import Button from "../common/Button"

interface CustomInputPasswordShowHideProps {
  extraClassName?: string;
  labelText: string;
  isLabelHidden?: boolean;
  name: string;
  defaultValue?: any;
  placeholder?: string;
  control: any;
  helpText?: ReactNode,
}
function CustomInputPasswordShowHide(
  {
    extraClassName,
    labelText,
    isLabelHidden,
    name,
    defaultValue,
    placeholder,
    helpText,
    control,
    ...rest
  }:CustomInputPasswordShowHideProps) {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleBlur = () => {
    setShowPassword(false)
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (

        <div className={clsx("c-form-group", "c-form-group-password", extraClassName && extraClassName)}>

          <div className="c-form-group-input-wrapper">

            <input
              type={showPassword ? "text" : "password"}
              id={`id_${name}`}
              placeholder={placeholder ? t(placeholder): " "}
              aria-invalid={!!error}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                }
              }}
              {...field}
              {...rest}
              onBlur={handleBlur}
            />

            <Button
              extraClassName="show-hide-password-btn"
              btnVariant="icon"
              btnColor="ghost"
              title={showPassword ? "Hide password" : "Show Password"}
              icon={showPassword ? <IcnShow/> : <IcnHide/>}
              onClick={toggleShowPassword}
            />

            <label htmlFor={`id_${name}`} className={clsx(isLabelHidden && 'c-visually-hidden')}>
              {t(labelText)}
            </label>

          </div>

          {error && (
            <div className="c-form-error-feedback">
              {t(error.message || "")}
            </div>
          )}

          {helpText && (
            <div className="c-form-help-text">
              {helpText}
            </div>
          )}


        </div>
      )}
    />
  )
}

export default CustomInputPasswordShowHide