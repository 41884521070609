import { ReactNode } from "react"
import clsx from "clsx"
import NavbarAccounts from "../components/common/NavbarAccounts"
import UserStatus from "../components/common/UserStatus"
import AccountsLayout from "./AccountsLayout"

interface PreApprovalLayoutProps {
  pageExtraClassName?: string
  pageExtraTitle?: string
  navbarRemoveBadge?: boolean
  navbarUserFirstName?: string
  navbarBadgeType?: "info" | "success" | "warning" | "danger" | "default"
  navbarBadgeText?: string
  children?: ReactNode
}

function PreApprovalLayout(
  {
    pageExtraClassName,
    pageExtraTitle,
    navbarRemoveBadge,
    navbarUserFirstName,
    navbarBadgeType,
    navbarBadgeText,
    children,
  }: PreApprovalLayoutProps) {
  return (
    <AccountsLayout
      pageExtraClassName={clsx("c-page-pre-approval", pageExtraClassName && pageExtraClassName)}
      pageExtraTitle={`${pageExtraTitle ? "Pre-Approval Form | " + pageExtraTitle : "Pre-Approval Form"}`}
      sectionExtraClassName="c-pre-approval"
      navbar={
        <NavbarAccounts>
          <UserStatus
            extraClassName="c-navbar-page-title"
            removeBadge={navbarRemoveBadge}
            userFirstName={navbarUserFirstName}
            badgeType={navbarBadgeType}
            badgeText={navbarBadgeText}
          />
        </NavbarAccounts>
      }
    >

      {children}

    </AccountsLayout>
  )
}

export default PreApprovalLayout
