import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import moment from "moment"
import Tippy from "@tippyjs/react"
import { NumericFormat } from "react-number-format"
import { useMediaQuery } from "usehooks-ts"
import { getApprovedLoanDetails } from "../services/api/leaseDetails"
import { responsiveBreakpoints } from "../constant/constants"
import { LoanDetails, paymentInterface } from "../types/approvedLoan"
import { useAppContext } from "../contexts/AppContext"
import DashboardLayout from "../layouts/DashboardLayout"
import LoanApprovedTable from "../components/LoanApprovedTable"
import LoanApprovedDetailsModal from "../components/modals/LoanApprovedDetailsModal"
import LoanApprovedVehicleDetailsModal from "../components/modals/LoanApprovedVehicleDetailsModal"
import CustomAlert from "../components/common/CustomAlert"
import CustomBadge from "../components/common/CustomBadge"
import ButtonLink from "../components/common/ButtonLink"
import BackButton from "../components/common/BackButton"
import { ReactComponent as IcnArrowUp } from "../assets/icons/icn-arrow-up.svg"
import styles from "../styles/LoanApproved.module.scss"

function LoanApproved() {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  const { activeLoan } = useAppContext()
  const [leaseOverviewData, setLeaseOverviewData] = useState<
    LoanDetails | undefined
  >(undefined)
  const id = activeLoan.message

  useEffect(() => {
    const fetchApprovedLoanDetail = async () => {
      try {
        const overview = await getApprovedLoanDetails(activeLoan.message)
        setLeaseOverviewData(overview.message)
      } catch (error) {
        console.error("Error fetching loan details:", error)
      }
    }

    fetchApprovedLoanDetail()
  }, [])

  const history = useMemo(
    () => leaseOverviewData?.payment_history ?? ([] as paymentInterface[]),
    [leaseOverviewData],
  )

  const sortedHistory = useMemo(() => {
    const history = leaseOverviewData?.payment_history ?? []
    return history
      .filter((item) => item.date !== undefined && item.date !== null)
      .sort(
        (a, b) =>
          new Date(b.date as string).getTime() -
          new Date(a.date as string).getTime(),
      )
  }, [leaseOverviewData?.payment_history])

  const [stepStates, setStepStates] = useState<
    Array<{ completed: boolean; upcoming: boolean }>
  >([])

  useEffect(() => {
    const filteredAndSortedHistory = history
      .filter(
        (item) =>
          item.status === "Paid" &&
          item.date !== undefined &&
          item.date !== null,
      )
      .sort(
        (a, b) =>
          new Date(a.date as string).getTime() -
          new Date(b.date as string).getTime(),
      )

    const stepStates = Array.from(
      {
        length: leaseOverviewData?.loan_balance.upcoming_payment.due_month ?? 0,
      },
      () => ({
        completed: false,
        upcoming: false,
      }),
    )

    filteredAndSortedHistory.forEach((item) => {
      const stepIndex = history
        .filter((h) => h.status === "Paid")
        .findIndex((h) => h.date === item.date)
      if (stepIndex !== -1 && stepIndex < stepStates.length) {
        stepStates[stepIndex].completed = true
      }
    })

    const lastCompletedIndex = filteredAndSortedHistory.length - 1

    if (lastCompletedIndex + 1 < stepStates.length) {
      stepStates[lastCompletedIndex + 1].upcoming = true
    }

    setStepStates(stepStates)
  }, [history, leaseOverviewData?.loan_balance.upcoming_payment.due_month])

  const totalSteps = stepStates.length
  const completedSteps = stepStates.filter((state) => state.completed).length

  return (
    <DashboardLayout
      pageClassName={clsx(styles.cPageLoanApproved, "c-page-loan-approved")}
      pageTitle={`Loan | ${id}`}
      navbarTitle={`${leaseOverviewData?.vehicle_details.year} ${leaseOverviewData?.vehicle_details.brand} ${leaseOverviewData?.vehicle_details.model}`}
      navbarHasBackLink={isMobileBreakpoint}
      navbarBackLinkUrl="/dashboard"
      navbarBackLinkVariant={isMobileBreakpoint ? "icon" : "link"}
      navbarBackLinkText={isMobileBreakpoint ? undefined : "Dashboard"}
    >
      <section className="c-loan-approved">
        <div className="c-loan-approved-wrapper">
          {!isMobileBreakpoint && (
            <BackButton
              extraClassName="c-loan-approved-back-btn c-desktop"
              backLinkUrl="/dashboard"
              backLinkText="Return to dashboard"
              backLinkVariant="link"
            />
          )}

          <div className="c-loan-approved-header">
            <CustomAlert
              alertType="warning"
              alertContent={
                <>
                  {t(
                    "Finish setting up your loan by providing the vehicle registrations.",
                  )}
                  &nbsp;
                  <ButtonLink
                    url="/documents"
                    btnVariant="link"
                    btnColor="blue"
                    text="Upload file"
                  />
                </>
              }
            />

            <CustomAlert
              alertType="warning"
              alertContent={
                <>
                  {t(
                    "Finish setting up your loan by providing your vehicle insurance policy.",
                  )}
                  &nbsp;
                  <ButtonLink
                    url="/documents"
                    btnVariant="link"
                    btnColor="blue"
                    text="Upload file"
                  />
                </>
              }
            />
          </div>

          <div className="c-loan-approved-grid">
            <div className="c-loan-approved-grid-item details">
              <div className="c-loan-approved-grid-item-details">
                <div className="c-loan-approved-grid-item-details-header">
                  <div className="c-loan-approved-grid-item-details-header-inner">
                    <p>
                      <span className="h6">{t("Application number")}</span>
                      <em>
                        #
                        {`${leaseOverviewData?.loan_details.application_number}`}
                      </em>
                    </p>

                    <ButtonLink
                      url="#"
                      text="View loan agreement"
                      btnVariant="link"
                      btnSize="sm"
                      icon={<IcnArrowUp />}
                      iconSize="sm"
                      iconPlacement="right"
                    />
                  </div>
                </div>

                <LoanApprovedDetailsModal data={leaseOverviewData} />
                <LoanApprovedVehicleDetailsModal />
              </div>
            </div>

            <div className="c-loan-approved-grid-item history">
              <LoanApprovedTable data={sortedHistory} />
            </div>

            <div className="c-loan-approved-grid-item balance">
              <div className="c-loan-approved-grid-item-balance">
                <small>{t("Loan balance")}</small>

                <NumericFormat
                  className="h3"
                  displayType="text"
                  value={leaseOverviewData?.loan_balance.balance}
                  prefix="$"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                />

                <div className="c-loan-approved-steps">
                  <div className="c-loan-approved-steps-progress">
                    {completedSteps > 0 && (
                      <div
                        className="c-loan-approved-steps-list progressbar"
                        style={{
                          gridTemplateColumns: `repeat(${totalSteps}, 1fr)`,
                        }}
                      >
                        <div
                          className="progress-bar-completed"
                          style={{ gridColumn: `span ${completedSteps}` }}
                        ></div>
                      </div>
                    )}

                    <ul className="c-loan-approved-steps-list steps">
                      {stepStates.map((state, index) => (
                        <li
                          key={index}
                          className={clsx(
                            state.upcoming
                              ? "upcoming"
                              : state.completed
                              ? "completed"
                              : "",
                          )}
                        >
                          <em
                            className={clsx(
                              completedSteps > 1 && "completed-hidden",
                            )}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="c-loan-approved-steps-upcoming-step">
                    <ul className="c-loan-approved-steps-list upcoming-step-list">
                      {stepStates.map((state, index) => (
                        <li
                          key={index}
                          className={clsx(
                            state.upcoming && "upcoming",
                            index === 0 && "is-first",
                            index === 1 && "is-first",
                            index + 1 === stepStates.length && "is-last",
                          )}
                        >
                          {state.upcoming ? (
                            <Tippy
                              className="c-loan-approved-steps-index"
                              appendTo={"parent"}
                              placement="bottom-start"
                              offset={[0, 0]}
                              trigger="manual"
                              arrow={false}
                              showOnCreate={true}
                              delay={0}
                              hideOnClick={false}
                              interactive={false}
                              content={
                                <>
                                  <strong>{index + 1}</strong> /{" "}
                                  {stepStates.length}
                                </>
                              }
                            >
                              <span className="upcoming" />
                            </Tippy>
                          ) : (
                            <span />
                          )}
                        </li>
                      ))}
                    </ul>

                    {stepStates.map(
                      (state, index) =>
                        state.upcoming && (
                          <aside
                            key={index}
                            className={clsx(
                              "c-loan-approved-steps-upcoming-step-details",
                              index === 0 && "is-first",
                              index + 1 === stepStates.length && "is-last",
                            )}
                          >
                            <span className="c-loan-approved-steps-upcoming-step-details-payment">
                              <span>{index + 1}</span>
                            </span>

                            <div className="c-loan-approved-steps-upcoming-step-details-content">
                              <span className="c-loan-approved-steps-upcoming-step-details-date">
                                {moment(
                                  leaseOverviewData?.loan_balance
                                    .upcoming_payment.due_date,
                                ).format("MMM D, YYYY")}
                              </span>

                              <div>
                                <NumericFormat
                                  className="c-loan-approved-steps-upcoming-step-details-amount"
                                  displayType="text"
                                  prefix="$"
                                  thousandSeparator=","
                                  decimalSeparator="."
                                  decimalScale={2}
                                  fixedDecimalScale
                                  value={
                                    leaseOverviewData?.loan_balance
                                      .upcoming_payment.amount_owed
                                  }
                                />

                                <CustomBadge
                                  badgeType="default"
                                  badgeText="Upcoming"
                                  badgeSize="sm"
                                />
                              </div>
                            </div>
                          </aside>
                        ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  )
}

export default LoanApproved
