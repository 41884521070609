import {ReactNode} from "react"
import {useTranslation} from "react-i18next"
import clsx from "clsx"
import ReactModal from "react-modal"
import Button from "./Button"
import {ReactComponent as IcnClose} from "../../assets/icons/icn-close.svg"
import styles from "../../styles/CustomModal.module.scss"

interface CustomModalProps {
  extraClassName?: string;
  modalSize?: "xs" |"md" | "lg" | "xl";
  isModalOpen: boolean;
  onCloseModal: () => void;
  addModalHeader?: boolean;
  modalHeaderTitle?: string;
  modalHeaderParagraph?: string;
  modalHeaderParagraphContent?: ReactNode;
  addCloseBtn?: boolean;
  children?: ReactNode;
}

function CustomModal(
  {
    extraClassName,
    modalSize,
    isModalOpen,
    onCloseModal,
    addModalHeader,
    modalHeaderTitle,
    modalHeaderParagraph,
    modalHeaderParagraphContent,
    addCloseBtn,
    children
  }: CustomModalProps) {
  const { t } = useTranslation()

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      closeTimeoutMS={100}
      parentSelector={() => document.body}
      appElement={document.getElementById("root") as HTMLElement}
      portalClassName={clsx(styles.cModal, "c-modal", extraClassName && extraClassName)}
      overlayClassName={"c-modal-overlay"}
      className={clsx("c-modal-content", modalSize || "sm")}
    >
      <div className="c-modal-content-wrapper">

        {addModalHeader && (

          <div className="c-modal-content-header">
            {modalHeaderTitle && (
              <h1 className="h5">
                {t(modalHeaderTitle)}
              </h1>
            )}

            {modalHeaderParagraph? (
              <p>{t(modalHeaderParagraph)}</p>
            ): modalHeaderParagraphContent?
              (<p>{modalHeaderParagraphContent}</p>)
              : null
            }

            {addCloseBtn && (
              <Button
                extraClassName="c-modal-close-btn"
                btnVariant="icon"
                btnColor="ghost"
                btnSize="sm"
                icon={<IcnClose/>}
                onClick={onCloseModal}
              />
            )}

          </div>
        )}

        {addCloseBtn && (
          <Button
            extraClassName="c-modal-close-btn"
            btnVariant="icon"
            btnColor="ghost"
            btnSize="sm"
            icon={<IcnClose/>}
            onClick={onCloseModal}
          />
        )}

        <div className="c-modal-content-body">
          {children}
        </div>

      </div>

    </ReactModal>
  )
}

export default CustomModal
