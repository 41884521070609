import React, {ReactNode} from "react"
import {useTranslation} from "react-i18next"
import clsx from "clsx"
import { PatternFormat, PatternFormatProps } from 'react-number-format'
import { Controller } from 'react-hook-form'

interface CustomNumberPatternProps extends PatternFormatProps {
  extraClassName?: string;
  labelText: string;
  isLabelHidden?: boolean;
  name: string;
  defaultValue?: string | number;
  placeholder?: string
  control: any,
  helpText?: ReactNode,
}

function CustomNumberPattern(
  {
    extraClassName,
    labelText,
    isLabelHidden,
    name,
    defaultValue,
    placeholder,
    helpText,
    control,
    ...rest
  }:CustomNumberPatternProps) {
  const { t } = useTranslation()
  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field: {onChange, onBlur, value, ref}, fieldState: { error } }) => (

          <div className={clsx("c-form-group", extraClassName && extraClassName)}>

            <div className="c-form-group-input-wrapper">

              <PatternFormat
                id={`id_${name}`}
                placeholder={placeholder ? t(placeholder) : " "}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                getInputRef={ref}
                aria-invalid={!!error}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                  }
                }}
                {...rest}
              />

              <label
                htmlFor={`id_${name}`}
                className={clsx(isLabelHidden && 'c-visually-hidden')}
              >
                {t(labelText)}
              </label>

            </div>

            {helpText && (
              <div className="c-form-help-text">
                {helpText}
              </div>
            )}

            {error && (
              <div className="c-form-error-feedback">
                {t(error.message || "")}
              </div>
            )}

          </div>
        )}
      />
    </>
  )
}

export default CustomNumberPattern