import { useMediaQuery } from "usehooks-ts"
import { responsiveBreakpoints } from "../constant/constants"
import LoanPendingLayout from "../layouts/LoanPendingLayout"
import LoanContent from "../components/LoanContent"
import ButtonLink from "../components/common/ButtonLink"
import { ReactComponent as IcnChevronLeft } from "../assets/icons/icn-chevron-left.svg"
import styles from "../styles/Loan.module.scss"
import clsx from "clsx"

function LoanPending() {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  return (
    <LoanPendingLayout
      sectionExtraClassName={clsx(styles.cLoanMain,"c-loan-main", "c-loan-pending")}
    >

      {!isMobileBreakpoint && (
        <ButtonLink
          extraClassName="dashboard-desktop-back-btn c-desktop"
          btnVariant="link"
          btnSize="md"
          btnColor="ghost"
          url="/dashboard"
          text="Return to dashboard"
          icon={<IcnChevronLeft />}
          iconSize="sm"
        />
      )}

      <LoanContent/>

    </LoanPendingLayout>
  )
}

export default LoanPending

