import { ReactNode } from "react"

interface MainProps {
  children?: ReactNode;
}
function Main({children}: MainProps) {
  return (
    <main className="c-main">

      <section className="c-page-section">

        <div className="c-page-section-content">
          {children}
        </div>

      </section>

    </main>
  )
}

export default Main
