import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useEffect,
} from "react"

interface AppContextType {
  activeLoan: ActiveLoanType
  setActiveLoan: React.Dispatch<React.SetStateAction<ActiveLoanType>>
  userEmail: string
  setUserEmail: React.Dispatch<React.SetStateAction<string>>
  firstName: string
  setFirstName: React.Dispatch<React.SetStateAction<string>>
  lastName: string
  setLastName: React.Dispatch<React.SetStateAction<string>>
  fullName: string
  setFullName: React.Dispatch<React.SetStateAction<string>>
  userID: string
  setUserID: React.Dispatch<React.SetStateAction<string>>
  birthDate: string
  setBirthDate: React.Dispatch<React.SetStateAction<string>>
  address: string
  setAddress: React.Dispatch<React.SetStateAction<string>>
  phoneNumber: string
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

interface ActiveLoanType {
  status?: number
  message: string
}

const AppContext = createContext<AppContextType | undefined>(undefined)

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [activeLoan, setActiveLoan] = useState<ActiveLoanType>({
    status: 0,
    message: "",
  })
  const [userEmail, setUserEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [fullName, setFullName] = useState(
    localStorage.getItem("fullName") || "",
  )
  const [birthDate, setBirthDate] = useState("")
  const [address, setAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [userID, setUserID] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    localStorage.setItem("fullName", fullName)
  }, [fullName])

  const value = useMemo(
    () => ({
      activeLoan,
      setActiveLoan,
      userEmail,
      setUserEmail,
      firstName,
      setFirstName,
      lastName,
      setLastName,
      fullName,
      setFullName,
      userID,
      setUserID,
      birthDate,
      setBirthDate,
      address,
      setAddress,
      phoneNumber,
      setPhoneNumber,
      isLoading,
      setIsLoading,
      errorMessage,
      setErrorMessage,
    }),
    [
      activeLoan,
      userEmail,
      firstName,
      lastName,
      fullName,
      userID,
      birthDate,
      address,
      phoneNumber,
      isLoading,
      errorMessage,
    ],
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider")
  }
  return context
}
