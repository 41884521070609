import React, { useEffect, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import moment from "moment"
import { useAppContext } from "../contexts/AppContext"
import { NumericFormat } from "react-number-format"
import {
  getAllLeases,
  getUserData,
  getUpcomingPayment,
} from "../services/api/dashboardService"
import { loanDetails, upcomingPayment } from "../types/dashboard"
import { transformLeasesResponse } from "../utils/utils"
import DashboardLayout from "../layouts/DashboardLayout"
import CustomTabs from "../components/common/CustomTabs"
import DashboardLoansApprovedTable from "../components/DashboardLoansApprovedTable"
import DashboardLoansPendingTable from "../components/DashboardLoansPendingTable"
import DashboardLoansExpiredTable from "../components/DashboardLoansExpiredTable"
import { ReactComponent as IcnVehicle } from "../assets/icons/icn-vehicle.svg"
import { ReactComponent as IcnCalendar } from "../assets/icons/icn-calendar.svg"
import { ReactComponent as IcnChevronRight } from "../assets/icons/icn-chevron-right.svg"
import styles from "../styles/Dashboard.module.scss"

function Dashboard() {
  const { t } = useTranslation()
  const {
    setUserEmail,
    setFullName,
    setFirstName,
    setLastName,
    setAddress,
    setBirthDate,
    setPhoneNumber,
  } = useAppContext()

  const [hasUpcomingPayment, setHasUpcomingPayment] = useState(false)
  const [upcomingPaymentData, setUpcomingPaymentData] = useState<
    upcomingPayment | "null"
  >("null")
  const [leaseData, setLeaseData] = useState<{
    approved: loanDetails[]
    pending: loanDetails[]
    refused: loanDetails[]
  } | null>(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const userDataPromise = getUserData()
        const leaseDataPromise = getAllLeases()
        const upcomingPaymentPromise = getUpcomingPayment()
        const [userDataResponse, leaseDataResponse, upcomingPaymentResponse] =
          await Promise.all([
            userDataPromise,
            leaseDataPromise,
            upcomingPaymentPromise,
          ])

        const userData = userDataResponse.message
        localStorage.setItem("user", JSON.stringify(userData))
        setFullName(`${userData.first_name} ${userData.last_name}`)
        setFirstName(userData.first_name)
        setLastName(userData.last_name)
        setUserEmail(userData.email)
        setBirthDate(userData.birth_date)
        if (userData.phone_number) setPhoneNumber(userData.phone_number)
        if (userData.address) setAddress(userData.address)

        const transformedData = transformLeasesResponse(
          leaseDataResponse.message,
        )
        setLeaseData(transformedData)

        upcomingPaymentResponse.message !== "null" &&
          setUpcomingPaymentData(upcomingPaymentResponse.message)
        upcomingPaymentResponse.message !== "null"
          ? setHasUpcomingPayment(true)
          : setHasUpcomingPayment(false)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [
    setAddress,
    setBirthDate,
    setFirstName,
    setFullName,
    setLastName,
    setPhoneNumber,
    setUserEmail,
  ])

  const tabs = useMemo(() => {
    const allTabs = [
      {
        id: "approved",
        name: t("Approved Loans"),
        component: (
          <DashboardLoansApprovedTable loans={leaseData?.approved || []} />
        ),
      },
      {
        id: "pending",
        name: t("Pending Loans"),
        component: (
          <DashboardLoansPendingTable loans={leaseData?.pending || []} />
        ),
      },
      {
        id: "expired",
        name: t("Expired Loans"),
        component: (
          <DashboardLoansExpiredTable loans={leaseData?.refused || []} />
        ),
      },
    ]
    return allTabs.filter((tab) => tab.component.props.loans.length > 0)
  }, [leaseData, t])

  const shouldRenderTable = useMemo(() => {
    return (
      leaseData &&
      (leaseData.approved.length > 0 ||
        leaseData.pending.length > 0 ||
        leaseData.refused.length > 0)
    )
  }, [leaseData])

  const hasUpcommingPaymentData =
    hasUpcomingPayment && upcomingPaymentData !== "null"

  return (
    <DashboardLayout
      pageTitle="Dashboard"
      pageClassName={clsx(styles.cPageDashboard, "c-page-dashboard")}
      navbarTitle="Dashboard"
    >
      <section className="c-dashboard">
        <div className="c-dashboard-wrapper">
          <div className="c-dashboard-section-grid">
            <div className="c-dashboard-section-grid-item payments">
              <div
                className={clsx(
                  "c-dashboard-section-grid-item-payments",
                  hasUpcomingPayment ? "has-payments" : "default-empty",
                )}
              >
                {!hasUpcomingPayment && (
                  <aside className="c-dashboard-section-grid-item-payments-default-empty-text">
                    <small>{t("No upcoming payment")}</small>

                    <span className="h5">
                      {t("Upcoming payments will appear here")}
                    </span>
                  </aside>
                )}

                <small>{hasUpcomingPayment && t("Upcoming payment")}</small>

                <time>
                  {hasUpcommingPaymentData && (
                    <>
                      {t("Due on")}&nbsp;
                      {moment(upcomingPaymentData?.due_date).format(
                        "MMMM Do, YYYY",
                      )}
                    </>
                  )}
                </time>

                <div className="c-dashboard-section-grid-item-payments-details">
                  <span className="h3">
                    {hasUpcommingPaymentData && (
                      <NumericFormat
                        displayType="text"
                        prefix="$"
                        value={upcomingPaymentData?.amount_owed}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  </span>

                  <div className="c-dashboard-section-grid-item-payments-details-info">
                    <small>
                      {hasUpcommingPaymentData && (
                        <>
                          <i>
                            <IcnVehicle />
                          </i>
                          {upcomingPaymentData?.vehicle_info.name}
                        </>
                      )}
                    </small>

                    <small>
                      {hasUpcommingPaymentData && (
                        <>
                          <i>{<IcnCalendar />}</i>
                          {t("Payment")} #{upcomingPaymentData?.payment_number}
                        </>
                      )}
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div className="c-dashboard-section-grid-item hero">
              <a
                href="http://www.google.com"
                target="_blank"
                rel="noreferrer noopener"
                className="c-dashboard-section-grid-item-hero"
                style={{
                  backgroundImage: `url(https://picsum.photos/id/16/1000/300)`,
                }}
              >
                <div className="c-dashboard-section-grid-item-hero-text">
                  <div>
                    <time>March 28th, 2024</time>
                    <span className="h5">
                      Leasyfi offering lowered interest rates for EV purchases.
                    </span>
                  </div>

                  <small
                    className="c-button"
                    data-variant="link"
                    data-color="white"
                    data-size="sm"
                  >
                    {t("Read more")}
                    <i>
                      <IcnChevronRight />
                    </i>
                  </small>
                </div>
              </a>
            </div>
            {shouldRenderTable && (
              <div className="c-dashboard-section-grid-item table">
                <CustomTabs tabs={tabs} initialTabIndex={0} />
              </div>
            )}
          </div>
        </div>
      </section>
    </DashboardLayout>
  )
}

export default React.memo(Dashboard)
