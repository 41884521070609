import clsx from "clsx"
import LoanPendingLayout from "../layouts/LoanPendingLayout"
import LoanAdditionalDocumentsForm from "../components/forms/LoanAdditionalDocumentsForm"
import styles from "../styles/LoanSubpageAdditionalDocuments.module.scss"

function LoanPendingAdditionalDocuments() {

  return (
    <LoanPendingLayout
      subPageTitle="Additional Documents"
      subPageExtraClassName={clsx(styles.cPageAdditionalDocuments, "c-page-additional-documents")}
      sectionExtraClassName="c-loan-additional-documents"
    >
      <LoanAdditionalDocumentsForm/>

    </LoanPendingLayout>
  )
}

export default LoanPendingAdditionalDocuments
