import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useAppContext } from "../../contexts/AppContext"
import {
  updateEquifaxConsent,
  getEquifaxConsentStatus,
} from "../../services/api/consentService"
import CustomSectionBoxed from "../common/CustomSectionBoxed"
import LoanConsentTerms from "../../components/LoanConsentTerms"
import CustomInputRadioCheckbox from "../form-elements/CustomInputRadioCheckbox"
import Button from "../common/Button"

interface FormData {
  application_consent?: boolean | undefined
}

const schema = yup.object().shape({
  application_consent: yup
    .boolean()
    .oneOf([true], "You must agree to the terms and conditions"),
})

function LoanConsentForm() {
  const { firstName, activeLoan } = useAppContext()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      application_consent: false,
    },
  })

  useEffect(() => {
    const fetchConsentStatus = async () => {
      try {
        const response = await getEquifaxConsentStatus(activeLoan.message)
        if (response) {
          reset({
            application_consent: response,
          })
        }
      } catch (e: any) {
        console.log(e)
      }
    }
    fetchConsentStatus()
  }, [])

  const onSubmit = async (data: FormData) => {
    data.application_consent &&
      (await updateEquifaxConsent(data.application_consent, activeLoan.message))
    navigate(-1)
  }

  return (
    <CustomSectionBoxed
      classname="c-loan-consent-section-form"
      headerContentType="title"
      headerTitle="Terms and Conditions"
      headerTitleParagraph="Please review and accept the terms and conditions below to proceed with your application"
    >
      <LoanConsentTerms />

      <form className="c-loan-consent-form" onSubmit={handleSubmit(onSubmit)}>
        <Button
          type="submit"
          btnColor="blue"
          text="Agree to credit check"
          disabled={!isValid || !isDirty}
        />

        <fieldset>
          <legend className="c-visually-hidden">
            {t("Agree to terms & conditions")}
          </legend>

          <CustomInputRadioCheckbox
            type="checkbox"
            labelText={`I ${firstName}, have read and agree to the terms and conditions set out forth`}
            id="id_application_consent"
            name="application_consent"
            control={control}
          />
        </fieldset>
      </form>
    </CustomSectionBoxed>
  )
}

export default LoanConsentForm
