import { useTranslation } from "react-i18next"
import clsx from "clsx"
import AccountsLayout from "../layouts/AccountsLayout"
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm"
import ButtonLink from "../components/common/ButtonLink"
import { ReactComponent as IcnLogo } from "../assets/icons/icn-logo-brandmark.svg"
import styles from "../styles/AccountsLayout.module.scss"

function ForgotPassword() {
  const { t } = useTranslation()
  return (
    <AccountsLayout
      pageExtraClassName={clsx(styles.cPageForgotPassword, "c-page-forgot-password", "c-page-password")}
      pageExtraTitle="Forgot password"
    >
      <section className="c-accounts-section-form">

        <div className="c-accounts-section-form-wrapper">

          <div className="c-accounts-section-form-inner">

            <div className="c-accounts-section-form-header">

              <i className="c-accounts-section-form-header-icon">
                <IcnLogo />
              </i>

              <h1 className="h4">{t("Forgot your password?")}</h1>
              <p>
                {t(
                  "Enter your email address, and we'll get you back on track in no time.",
                )}
              </p>
            </div>

            <ForgotPasswordForm />
          </div>

          <div className="c-accounts-section-form-footer">
            <span>
              {t("New to Leasyfi?")}&nbsp;
              <ButtonLink
                url="/signup"
                btnColor="blue"
                btnVariant="link"
                text="Get started"
              />
            </span>

          </div>

        </div>

      </section>

    </AccountsLayout>
  )
}

export default ForgotPassword
