import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import moment from "moment/moment"
import { ColumnDef } from "@tanstack/react-table"
import { DummyLoanHistoryProps } from "../mock-data/dummyTypes"
import CustomBadge from "./common/CustomBadge"
import CustomTable from "./common/CustomTable"
import Button from "./common/Button"
import { ReactComponent as IcnArrowUp } from "../assets/icons/icn-arrow-up.svg"
import styles from "../styles/LoanApprovedTable.module.scss"

const generateColumns = (
  t: (key: string) => string,
): ColumnDef<DummyLoanHistoryProps>[] => {
  return [
    {
      id: "payment-number",
      header: () => <>{t("Date")}</>,
      cell: ({ row }) => {
        return (
          <span className={clsx(row.original.status)}>
            <em className="c-desktop">#</em>
            {row.original.paymentNumber}
          </span>
        )
      },
    },
    {
      id: "date",
      header: () => <>{t("Date")}</>,
      cell: ({ row }) => {
        return (
          <span className={clsx(row.original.status)}>
            <time>{moment(row.original.date).format("MMM D, YYYY")}</time>
          </span>
        )
      },
    },
    {
      id: "status",
      header: () => <>{t("Status")}</>,
      cell: ({ row }) => {
        return (
          <CustomBadge
            badgeType={row.original.status === "Paid" ? "success" : "danger"}
            badgeText={
              row.original.status === "Paid"
                ? "Paid"
                : "Partly Paid"
                ? "Partly Paid"
                : "Overdue"
                ? "Overdue"
                : "Incomplete"
            }
          />
        )
      },
    },
    {
      id: "amountDue",
      header: () => <>{t("Amount Due")}</>,
      cell: ({ row }) => {
        return (
          <NumericFormat
            displayType="text"
            className={clsx(row.original.status)}
            value={row.original.amountDue}
            prefix="$"
            decimalScale={2}
            thousandSeparator=","
            decimalSeparator="."
            fixedDecimalScale
            valueIsNumericString
          />
        )
      },
    },
    {
      id: "action",
      header: () => <>{t("Action")}</>,
      cell: ({ row }) => {
        return (
          <Button
            btnVariant="link"
            text="Receipt"
            icon={<IcnArrowUp />}
            iconPlacement="right"
            disabled={row.original.status !== "paid"}
          />
        )
      },
    },
  ]
}

interface LoanApprovedTableProps {
  data?: DummyLoanHistoryProps[]
}

function LoanApprovedTable({ data }: LoanApprovedTableProps) {
  const { t } = useTranslation()
  const columns = generateColumns(t)
  const validatedData = data || []

  return (
    <CustomTable
      extraClassName={clsx(
        styles.cSectionLoanApprovedTable,
        "c-loan-approved-section-table",
        "is-responsive",
      )}
      data={validatedData}
      columns={columns}
      pageSize={12}
      tableHasCaption
      tableCaptionTitle="Payment history"
      customDefaultEmpty
      defaultEmptyHeading="No payment history"
      defaultEmptyTitle="Previous payments will appear here."
    />
  )
}

export default LoanApprovedTable
