import { useRef } from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { DashboardMobileMenuNavItems} from "../constant/constants"
import CustomMobileMenu from "./common/CustomMobileMenu"
import LanguageSwitcher from "./common/LanguageSwitcher"
import LogoutButton from "./common/LogoutButton"
import { ReactComponent as IcnAccount } from "../assets/icons/icn-user.svg"
import { ReactComponent as IcnChevronRight } from "../assets/icons/icn-chevron-right.svg"
import { ReactComponent as IcnArrowUp } from "../assets/icons/icn-arrow-up.svg"
import styles from "../styles/DashboardMobileMenu.module.scss"

function DashboardMobileMenu() {
  const { t } = useTranslation()

  const mobileMenuDashboardRef = useRef<HTMLDivElement>(null)

  return (
    <CustomMobileMenu
      extraClassName={clsx(styles.cDashboardMobileMenu, "c-dashboard-mobile-menu")}
      ref={mobileMenuDashboardRef}
      menuBtnExtraClassName={clsx("c-sidebar-navbar-nav-link", "c-dashboard-mobile-menu-btn")}
      menuBtnIcon={<IcnAccount/>}
      menuBtnText="Account"
      menuBtnVariant="link"
      menuHeaderTitle="Account Settings"
    >
      {DashboardMobileMenuNavItems.map((nav, navDashboardMobileIdx) => {
        return (
          <nav key={navDashboardMobileIdx}>
            <p>{t(nav.category)}</p>

            <ul>
              {nav.links.map((link, linkDashboardMobileIdx) => (

                <li key={linkDashboardMobileIdx}>

                  {link.isExternalLink ? (
                    <a
                      href={link.url}
                      rel="noreferrer noopener"
                      target="_blank"
                      title={t(link.title)}
                    >
                      <i>{link.icon}</i>

                      <span>{t(link.title)}</span>

                      <i><IcnArrowUp /></i>

                    </a>
                  ) : (
                    <NavLink
                      to={link.url}
                      title={t(link.title)}
                    >

                      <i>{link.icon}</i>

                      <span>{t(link.title)}</span>

                      <i><IcnChevronRight /></i>

                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        )
      })}

      <nav>
        <p>{t("App Controls")}</p>

        <ul>
          <li>
            <LanguageSwitcher />
          </li>

          <li>
            <LogoutButton />
          </li>
        </ul>

      </nav>

    </CustomMobileMenu>
  )
}

export default DashboardMobileMenu
