// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PublicPageNavItemsSocial_cPublicNavListSocial__qxpvA.c-public-nav-list-social {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  column-gap: 1em;
  margin: 0;
  padding: 0;
  list-style: none;
}
.PublicPageNavItemsSocial_cPublicNavListSocial__qxpvA.c-public-nav-list-social li {
  display: block;
}
.PublicPageNavItemsSocial_cPublicNavListSocial__qxpvA.c-public-nav-list-social li a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--px24);
  height: var(--px24);
  transition: opacity 0.3s ease;
}
.PublicPageNavItemsSocial_cPublicNavListSocial__qxpvA.c-public-nav-list-social li a:hover, .PublicPageNavItemsSocial_cPublicNavListSocial__qxpvA.c-public-nav-list-social li a:focus {
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/styles/PublicPageNavItemsSocial.module.scss"],"names":[],"mappings":"AAEI;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;AADN;AAGM;EACE,cAAA;AADR;AAGQ;EACE,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;EACA,6BAAA;AADV;AAGU;EAEE,YAAA;AAFZ","sourcesContent":[".cPublicNavListSocial {\n  &:global {\n    &.c-public-nav-list-social {\n      flex-shrink: 0;\n      display: flex;\n      align-items: center;\n      column-gap: 1em;\n      margin: 0;\n      padding: 0;\n      list-style: none;\n\n      li {\n        display: block;\n\n        a {\n          position: relative;\n          display: inline-flex;\n          align-items: center;\n          justify-content: center;\n          width: var(--px24);\n          height: var(--px24);\n          transition: opacity 0.3s ease;\n\n          &:hover,\n          &:focus {\n            opacity: 0.6;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cPublicNavListSocial": `PublicPageNavItemsSocial_cPublicNavListSocial__qxpvA`
};
export default ___CSS_LOADER_EXPORT___;
