import { LeaseDetailsResponse, LeaseDetails } from "../../types/leaseDetails"
import { loanDetailsApiResponse } from "../../types/approvedLoan"
import { BasicApiResponse } from "../../types/types"
import { fetch } from "./fetchWrapper"

export const getLeaseDetails = async (
  lease_id: string,
  cookies?: string[],
): Promise<LeaseDetailsResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, LeaseDetailsResponse>({
    endpoint: "/get-lease-details",
    method: "get",
    headers,
    options: {
      params: { lease_id },
    },
  })

  return response.data
}

export const updateLeaseDetails = async (
  lease_id: string,
  details: LeaseDetails,
  cookies?: string[],
): Promise<BasicApiResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<LeaseDetails, BasicApiResponse>({
    endpoint: "/update-lease-details",
    method: "post",
    payload: details,
    headers,
    options: {
      params: { lease_id },
    },
  })

  return response.data
}

export const getApprovedLoanDetails = async (
  loan_id: string,
  cookies?: string[],
): Promise<loanDetailsApiResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, loanDetailsApiResponse>({
    endpoint: "/get-loan-details",
    method: "get",
    headers,
    options: {
      params: { loan_id },
    },
  })

  return response.data
}
