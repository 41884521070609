import {useTranslation} from "react-i18next"
import CustomInputRadioCheckbox from "../../form-elements/CustomInputRadioCheckbox"

interface FormStep7Props {
  control: any;
}

function FormStep7({control}: FormStep7Props) {
  const { t } = useTranslation()

  return (
    <fieldset className="c-fieldset">

      <legend className="c-visually-hidden">
        {t("Terms")}
      </legend>

      <p>
        {t("The personal information we collect from you is shared with fraud prevention agencies as part of our commitment to preventing fraud and money laundering, as well as verifying your identity. This information may be used to detect and prevent fraudulent activities. It's important to note that if fraud is detected, you may be refused certain services, financial products, or employment opportunities.")}
      </p>

      <CustomInputRadioCheckbox
        extraClassName="c-visually-hidden"
        labelText="I accept the terms"
        type="checkbox"
        id="id_terms"
        name="terms"
        checked={true}
        disabled
        control={control}
      />
    </fieldset>
  )
}

export default FormStep7