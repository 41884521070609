import clsx from "clsx"
import LoanNewLayout from "../layouts/LoanNewLayout"
import LoanAdditionalDocumentsForm from "../components/forms/LoanAdditionalDocumentsForm"
import styles from "../styles/LoanSubpageAdditionalDocuments.module.scss"

function LoanPendingAdditionalDocuments() {

  return (
    <LoanNewLayout
      subPageTitle="Additional Documents"
      subPageExtraClassName={clsx(styles.cPageAdditionalDocuments, "c-page-additional-documents")}
      sectionExtraClassName="c-loan-additional-documents"
    >
      <LoanAdditionalDocumentsForm/>

    </LoanNewLayout>
  )
}

export default LoanPendingAdditionalDocuments
