import { ReactNode, useEffect } from "react"
import {Helmet, HelmetProvider} from "react-helmet-async"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

interface LayoutProps {
  bodyClassName?: string;
  pageTitle: string,
  pageClassName: string;
  children: ReactNode,
}

function BaseLayout({bodyClassName, pageClassName, pageTitle, children,}: LayoutProps) {
  const { t } = useTranslation()

  useEffect(() => {
    if (bodyClassName) {
      document.body.classList.add(bodyClassName);
    }

    return () => {
      if (bodyClassName) {
        document.body.classList.remove(bodyClassName);
      }
    };
  }, [bodyClassName]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Leasyfi | {t(pageTitle)}</title>
      </Helmet>

      <section className={clsx("c-page", pageClassName)}>
        {children}
      </section>

    </HelmetProvider>
  )
}

export default BaseLayout