import { useCallback, useMemo, useState } from "react"
import { useTranslation, Trans } from "react-i18next"
import clsx from "clsx"
import { NumericFormat } from "react-number-format"
import { LoanDetails } from "../../types/approvedLoan"
import CustomModal from "../common/CustomModal"
import Button from "../common/Button"
import ButtonLink from "../common/ButtonLink"
import { ReactComponent as IcnArrowUp } from "../../assets/icons/icn-arrow-up.svg"
import { ReactComponent as IcnChevronRight } from "../../assets/icons/icn-chevron-right.svg"
import styles from "../../styles/LoanApprovedModal.module.scss"

interface LoanApprovedDetailsModalProps {
  data?: LoanDetails
}
function LoanApprovedDetailsModal({ data }: LoanApprovedDetailsModalProps) {
  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleOpenModal = useCallback(() => {
    setModalIsOpen((modalIsOpen) => !modalIsOpen)
  }, [])
  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false)
  }, [])

  const LoanDetailsList = useMemo(
    () => [
      {
        name: t("Total loan amount"),
        value: data?.loan_details.total_loan_amount,
        isNumericValue: true,
        prefix: "$",
        suffix: null,
      },
      {
        name: t("Total paid to date"),
        value: data?.loan_details.total_paid_to_date,
        isNumericValue: true,
        prefix: "$",
        suffix: null,
      },
      {
        name: t("Loan term"),
        value: data?.loan_details.loan_term,
        isNumericValue: false,
        prefix: null,
        suffix: t(" months"),
      },
      {
        name: t("Term frequency"),
        value: t("Monthly"),
        isNumericValue: false,
        prefix: null,
        suffix: null,
      },
      {
        name: t("Remaining payments"),
        value: data?.loan_details.remaining_payments,
        isNumericValue: false,
        prefix: null,
        suffix: null,
      },
      {
        name: t("Interest rate"),
        value: data?.loan_details.interest_rate,
        isNumericValue: true,
        prefix: null,
        suffix: "%",
      },
      {
        name: t("Monthly payment amount"),
        value: data?.loan_balance.upcoming_payment.amount_owed,
        isNumericValue: true,
        prefix: "$",
        suffix: null,
      },
      {
        name: t("Fees paid for late payments"),
        value: data?.loan_details.fees_paid_for_late_payments,
        isNumericValue: true,
        prefix: "$",
        suffix: null,
      },
      {
        name: t("Application number"),
        value: data?.loan_details.application_number,
        isNumericValue: false,
        prefix: "#",
        suffix: null,
      },
    ],
    [data, t],
  )

  return (
    <>
      <Button
        extraClassName="c-loan-approved-modal-btn"
        btnVariant="link"
        btnColor="ghost"
        title="View loan details"
        iconSize="sm"
        onClick={handleOpenModal}
      >
        <span className="c-text-px18">{t("Loan Details")}</span>

        <span className="c-text-px14">
          {t("View")}
          <i>
            <IcnChevronRight />
          </i>
        </span>
      </Button>

      <CustomModal
        extraClassName={clsx(
          styles.cLoanApprovedModal,
          "c-loan-approved-modal",
          "details",
        )}
        isModalOpen={modalIsOpen}
        onCloseModal={handleCloseModal}
        modalSize="xs"
        addModalHeader
        modalHeaderTitle="Loan Details"
        modalHeaderParagraphContent={
          <Trans>
            Below is confirmed details of your loan, if there is something
            incorrect please{" "}
            <ButtonLink url="/support" btnVariant="link" btnColor="blue">
              contact us
            </ButtonLink>
            .
          </Trans>
        }
      >
        <ul className="c-loan-approved-modal-list">
          {LoanDetailsList.map((item, idx) => (
            <li key={idx}>
              <em>{item.name}</em>

              {item.isNumericValue ? (
                <NumericFormat
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                  prefix={item.prefix ? item.prefix : ""}
                  suffix={item.suffix ? item.suffix : ""}
                  value={item.value}
                />
              ) : (
                <span>
                  {item.prefix && item.prefix}
                  {item.value}
                  {item.suffix && item.suffix}
                </span>
              )}
            </li>
          ))}

          <li>
            <em>{t("Loan agreement")}</em>
            <span>
              <ButtonLink
                url="#"
                btnVariant="link"
                text="View"
                icon={<IcnArrowUp />}
                iconSize="xs"
                iconPlacement="right"
              />
            </span>
          </li>
        </ul>

        <div className="c-button-container">
          <Button text="Close" onClick={handleCloseModal} />
        </div>
      </CustomModal>
    </>
  )
}

export default LoanApprovedDetailsModal
