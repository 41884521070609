import { ReactNode, useEffect, useState } from "react"
import clsx from "clsx"
import styles from '../../styles/Navbar.module.scss'
interface HeaderProps {
  extraClassName?: string;
  children?: ReactNode;
}
function Header({extraClassName, children}: HeaderProps) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <header className={clsx(styles.cHeader, "c-header", extraClassName && extraClassName, isScrolled && 'is-scrolled')}>

      <nav className="c-navbar">
        {children && children}
      </nav>

    </header>
  )
}

export default Header
