import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { useLocation, useNavigate } from "react-router-dom"
import { checkAuthKey } from "../services/api/userService"
import AccountsLayout from "../layouts/AccountsLayout"
import SignupForm from "../components/forms/SignupForm"
import ButtonLink from "../components/common/ButtonLink"
import { ReactComponent as IcnLogo } from "../assets/icons/icn-logo-brandmark.svg"
import styles from "../styles/AccountsLayout.module.scss"

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

function SignUp() {
  const { t } = useTranslation()
  const query = useQuery()
  const [email, setEmail] = useState("")
  const [authKey, setAuthKey] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    const emailParam = query.get("email")
    const authKeyParam = query.get("auth_key")

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (emailParam && authKeyParam && emailRegex.test(emailParam)) {
      setEmail(emailParam)
      setAuthKey(authKeyParam)
    } else {
      navigate("/apply")
    }
  }, [navigate, query])

  useEffect(() => {
    const verifyAuthKey = async () => {
      if (authKey) {
        try {
          await checkAuthKey(authKey)
        } catch (error) {
          navigate("/apply")
        }
      }
    }

    verifyAuthKey()
  }, [authKey, navigate])

  return (
    <AccountsLayout
      pageExtraClassName={clsx(
        styles.cPageSignup,
        "c-page-signup",
        "c-page-password",
      )}
      pageExtraTitle="Sign up"
    >
      <section className="c-accounts-section-form">
        <div className="c-accounts-section-form-wrapper">
          <div className="c-accounts-section-form-inner">
            <div className="c-accounts-section-form-header">
              <i className="c-accounts-section-form-header-icon">
                <IcnLogo />
              </i>

              <h1 className="h4">{t("Create your password")}</h1>
              <p>
                {t(
                  "Your password must be at least 8 characters long, and include 1 symbol and 1 number.",
                )}
              </p>
            </div>

            <SignupForm email={email} authKey={authKey} />
          </div>

          <div className="c-accounts-section-form-footer">
            <span>
              {t("Already have an account?")}&nbsp;
              <ButtonLink
                url="/login"
                btnColor="blue"
                btnVariant="link"
                text="Login"
              />
            </span>
          </div>
        </div>
      </section>
    </AccountsLayout>
  )
}

export default SignUp
