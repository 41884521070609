import Button from "../common/Button"
import CustomModal from "../common/CustomModal"
import clsx from "clsx"
import AccountSettingsEditEmailForm from "../forms/AccountSettingsEditEmailForm"
import {ReactComponent as IcnChevronRight} from "../../assets/icons/icn-chevron-right.svg"
import styles from "../../styles/AccountSettingsModal.module.scss"

interface AccountsSettingsEditEmailModalProps {
  isModalOpen: boolean;
  onClickOpen: () => void;
  onClickClose: () => void;
}
function AccountsSettingsEditEmailModal(
  {
    isModalOpen,
    onClickOpen,
    onClickClose,
  }: AccountsSettingsEditEmailModalProps) {
  return (
    <>
      <Button
        btnVariant="link"
        btnSize="md"
        text="Edit"
        icon={<IcnChevronRight/>}
        iconSize="sm"
        iconPlacement="right"
        onClick={onClickOpen}
      />

      <CustomModal
        extraClassName={clsx(styles.cSectionAccountSettingsModal, "c-account-settings-section-modal","edit-email")}
        isModalOpen={isModalOpen}
        onCloseModal={onClickClose}
        addModalHeader
        modalHeaderTitle="Change Contact Information"
        modalHeaderParagraph="Please provide a valid form of identification to confirm the change."
      >

        <AccountSettingsEditEmailForm
          onCloseModal={onClickClose}
        />

      </CustomModal>

    </>
  );
}

export default AccountsSettingsEditEmailModal