import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from "react"
import { getCookie, deleteCookie } from "../utils/utils"

interface AuthContextType {
  isAuthenticated: boolean
  login: (userId: string) => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  useEffect(() => {
    const user_id = getCookie("user_id")
    setIsAuthenticated(!!user_id)
  }, [])

  const login = (userId: string) => {
    document.cookie = `user_id=${userId}; path=/; max-age=86400`
    setIsAuthenticated(true)
  }

  const logout = () => {
    setIsAuthenticated(false)
    deleteCookie("user_id")
    localStorage.clear()
  }

  const value = useMemo(
    () => ({
      isAuthenticated,
      login,
      logout,
    }),
    [isAuthenticated],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
