import { useTranslation } from "react-i18next"

function LoanConsentTerms() {
  const { t } = useTranslation()
  return (
    <div className="c-loan-consent-section-form-terms">

      <div className="c-loan-consent-section-form-terms-wrapper">
        <p>
          {t("I, the undersigned, acknowledge and agree that, upon receipt of a duly signed copy of this application and consent to collect, use and disclose personal information (the \"LoanPending\"), LEASYFI Inc. shall be entitled and authorized to establish a file on me containing personal information. “Personal Information” means any information that can be used, either alone or in combination with other information, to identify me")}
        </p>

        <p>
          {t("The purposes of the present LoanPending and consent are to allow LEASYFI Inc. to: (i) to evaluate my credit and solvency; (ii) to make a decision about this LoanPending and execute a contract with me for the lease, financing or purchase of a motor vehicle; (iii) to monitor, record and determine during the term of such a contract my compliance with all or part of the obligations contained therein; (iv) to answer any questions I might have with respect to the LoanPending, any contract I may enter into and the LoanPending and information therein in general; (v) to record, manage, evaluate and collect, if applicable, any amount owing by me to LEASYFI Inc.; (vi) to maintain and use the information as a form of credit history and to simplify the verification of my information in subsequent transactions with LEASYFI Inc.; (vii) to meet any applicable legal and regulatory requirements; (viii) to develop and implement customer programs based on the history of my account, including those providing me with customized offers, promotions, updates or other information from LEASYFI Inc., its affiliates or partners; and (ix) to facilitate outreach by affiliated dealers about products and services I might be interested in.")}
        </p>

        <p>
          {t("If I have provided my Social Insurance Number, which I agree is voluntary and not necessary to fulfill the above purposes, I consent to LEASYFI Inc. using my Social Insurance Number as unique identifier to keep my information separate from other customers and people with similar names and for the purpose of recording, identifying and retrieving my personal information.")}
        </p>

        <p>
          {t("I acknowledge that LEASYFI Inc. may render decisions about my LoanPending based exclusively on automated processing of my personal information.")}
        </p>

        <p>
          {t("I acknowledge that, in addition to the information included in this LoanPending, LEASYFI Inc. may need to collect personal information from third parties to fulfill the above purposes. I hereby consent to LEASYFI Inc. collecting the necessary personal information from those third parties, including credit agencies, information and collection agencies, credit reporting bureaus, financial institutions, insurance companies, insurance brokers, my past, present and future employers, creditors and landlords, motor vehicle dealers, government agencies, my spouse or any other person who has or will have information related to my credit history and my solvency, my whereabouts or the whereabouts or condition of any property that is or has been owned, held or leased by me.")}
        </p>

        <p>
          {t("To achieve the purposes of the LoanPending, I understand that LEASYFI Inc. may need to disclose personal information collected via the LoanPending to third parties such as credit agencies, information and collection agencies, credit reporting bureaus, financial institutions, insurance companies, insurance brokers, vehicle manufacturers, motor vehicle dealers, creditors and persons to whom you have applied for credit.")}
        </p>

      </div>

    </div>
  )
}

export default LoanConsentTerms