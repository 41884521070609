// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportForm_cSupportForm__UuJ\\+X.c-support-form .c-form-group {
  margin-bottom: 0;
}
.SupportForm_cSupportForm__UuJ\\+X.c-support-form .c-button-container .c-button {
  width: 100%;
}
.SupportForm_cSupportForm__UuJ\\+X .c-support-form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  margin-bottom: var(--px24);
}
.SupportForm_cSupportForm__UuJ\\+X .c-support-form-grid .c-form-group:not(:first-child):not(:nth-child(2)) {
  grid-column: span 2;
}
@media (max-width: 991px) {
  .SupportForm_cSupportForm__UuJ\\+X .c-support-form-grid {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/SupportForm.module.scss"],"names":[],"mappings":"AAGM;EACE,gBAAA;AAFR;AAMQ;EACE,WAAA;AAJV;AAYM;EACE,aAAA;EACA,qCAAA;EACA,QAAA;EACA,0BAAA;AAVR;AAYQ;EACE,mBAAA;AAVV;AAcM;EACE;IACE,aAAA;IACA,sBAAA;IACA,kBAAA;EAZR;AACF","sourcesContent":[".cSupportForm {\n  &:global {\n    &.c-support-form {\n      .c-form-group {\n        margin-bottom: 0;\n      }\n\n      .c-button-container {\n        .c-button {\n          width: 100%;\n        }\n      }\n    }\n  }\n\n  :global {\n    .c-support-form {\n      &-grid {\n        display: grid;\n        grid-template-columns: repeat(2,1fr);\n        gap: 1em;\n        margin-bottom: var(--px24);\n\n        .c-form-group:not(:first-child):not(:nth-child(2)) {\n          grid-column: span 2;\n        }\n      }\n\n      @media (max-width: 991px) {\n        &-grid {\n          display: flex;\n          flex-direction: column;\n          margin-bottom: 1em;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cSupportForm": `SupportForm_cSupportForm__UuJ+X`
};
export default ___CSS_LOADER_EXPORT___;
