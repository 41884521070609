import { useState } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { contactSupport } from "../../services/api/userService"
import { useAppContext } from "../../contexts/AppContext"
import CustomInput from "../form-elements/CustomInput"
import CustomTextArea from "../form-elements/CustomTextArea"
import Button from "../common/Button"
import CustomAlert from "../common/CustomAlert"
import styles from "../../styles/SupportForm.module.scss"

interface FormData {
  firstName: string
  lastName: string
  email: string
  subject: string
  message: string
}

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  subject: yup.string().required("Subject is required"),
  message: yup.string().required("Message is required"),
})
function SupportForm() {
  const { t } = useTranslation()
  const { firstName, lastName, userEmail } = useAppContext()
  const [submitSuccess, setSubmitSuccess] = useState(false)

  //TODO: YANNICK this is broken and causing console error user is null when authenticated and in public page. Was greyed out temporarily

  // const userString = localStorage.getItem("user")
  //
  // const localUser = userString ? JSON.parse(userString) : null


  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      firstName: firstName || "",
      lastName: lastName || "",
      email: userEmail || "",
      subject: "",
      message: "",
    },
  })

  const onSubmit = async (data: FormData) => {
    setSubmitSuccess(false)
    await contactSupport(
      data.firstName,
      data.lastName,
      data.email,
      data.subject,
      data.message,
    )
    reset()
    setSubmitSuccess(true)
  }

  return (
    <form
      className={clsx(styles.cSupportForm, "c-support-form")}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset>
        <legend className="c-visually-hidden">
          {t("Enter your contact information and message")}
        </legend>

        {submitSuccess && (
          <CustomAlert
            extraClassName="c-form-alert"
            alertType="success"
            alertText="Your message has successfully been submitted. The Leasyfi team will contact you as soon as possible."
          />
        )}

        <div className="c-support-form-grid">
          <CustomInput
            labelText="Legal first name"
            name="firstName"
            control={control}
            readOnly={!!firstName}
          />

          <CustomInput
            labelText="Legal last name"
            name="lastName"
            control={control}
            readOnly={!!lastName}
          />

          <CustomInput
            type="email"
            labelText="Email address"
            name="email"
            control={control}
            readOnly={!!userEmail}
          />

          <CustomInput
            type="text"
            labelText="Subject"
            name="subject"
            control={control}
          />

          <CustomTextArea
            labelText="Message"
            name="message"
            control={control}
          />
        </div>
      </fieldset>

      <div className="c-button-container">
        <Button type="submit" btnColor="blue" text="Send" disabled={!isValid} />
      </div>
    </form>
  )
}

export default SupportForm
