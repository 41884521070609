import {useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {useMediaQuery} from "usehooks-ts"
import {responsiveBreakpoints} from "../../constant/constants"
import UserDropdownMenu from "./UserDropdownMenu"
import BackButton from "./BackButton"
import GetSupportLink from "./GetSupportLink"

interface NavbarDashboardProps {
  navbarHasBackLink?: boolean;
  navbarBackLinkUrl?: string;
  navbarBackLinkText?: string;
  navbarBackLinkTitle?: string;
  navbarBackLinkVariant?: "icon" | "link"
  navbarTitle?: string;
}

function NavbarDashboard(
  {
    navbarHasBackLink,
    navbarBackLinkUrl,
    navbarBackLinkTitle,
    navbarBackLinkText,
    navbarBackLinkVariant,
    navbarTitle,
  }:NavbarDashboardProps) {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const location = useLocation()
  const shouldDisplaySupportLink = location.pathname!== "/support"

  return (
    <>
      {navbarHasBackLink && (
        <BackButton
          extraClassName="c-navbar-back-button"
          backLinkUrl={navbarBackLinkUrl || "/dashboard"}
          backLinkTitle={navbarBackLinkTitle}
          backLinkText={navbarBackLinkText}
          backLinkVariant={navbarBackLinkVariant}
        />
      )}

      {navbarTitle && (
        <h1 className="h3 c-navbar-title">
          {t(navbarTitle)}
        </h1>
      )}

      {!isMobileBreakpoint && (
        <UserDropdownMenu btnColor="ghost-grey"/>
      )}

      {isMobileBreakpoint && shouldDisplaySupportLink && (
        <div className="c-button-container">
          <GetSupportLink hasMobileVersion/>
        </div>
      )}

    </>
  )
}

export default NavbarDashboard