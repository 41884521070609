import React from 'react'
import clsx from "clsx"
import {useTranslation} from "react-i18next"
import AccountsLayout from "../layouts/AccountsLayout"
import ResetPasswordForm from "../components/forms/ResetPasswordForm"
import ButtonLink from "../components/common/ButtonLink"
import {ReactComponent as IcnLogo} from "../assets/icons/icn-logo-brandmark.svg"
import styles from "../styles/AccountsLayout.module.scss"

function ResetPassword() {
  const { t } = useTranslation()
  return (
    <AccountsLayout
      pageExtraClassName={clsx(styles.cPageResetPassword, "c-page-reset-password", "c-page-password")}
      pageExtraTitle="Reset Password"
    >

      <section className="c-accounts-section-form">

        <div className="c-accounts-section-form-wrapper">

          <div className="c-accounts-section-form-inner">

            <div className="c-accounts-section-form-header">

              <i className="c-accounts-section-form-header-icon">
                <IcnLogo/>
              </i>

              <h1 className="h4">
                {t("Reset your password")}
              </h1>
              <p>
                {t("Your password must be at least 8 characters long, and include 1 symbol and 1 number.")}
              </p>
            </div>

            <ResetPasswordForm/>

          </div>

          <div className="c-accounts-section-form-footer">
            <span>
              {t("Back to")}&nbsp;<ButtonLink url="/login" btnColor="blue" btnVariant="link" text="login"/>
            </span>
          </div>

        </div>

      </section>

    </AccountsLayout>
  )
}

export default ResetPassword