import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { useDropzone } from "react-dropzone"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMediaQuery } from "usehooks-ts"
import { useAppContext } from "../../contexts/AppContext"
import { updateAddress } from "../../services/api/userService"
import {
  bytesToMB,
  provinces,
  responsiveBreakpoints,
} from "../../constant/constants"
import CustomInput from "../form-elements/CustomInput"
import CustomSelect from "../form-elements/CustomSelect"
import CustomInputPostalCode from "../form-elements/CustomInputPostalCode"
import DropzoneText from "../common/DropzoneText"
import Button from "../common/Button"
import { ReactComponent as IcnDelete } from "../../assets/icons/icn-close.svg"
import { ReactComponent as IcnFile } from "../../assets/icons/icn-file.svg"
import CustomAlert from "../common/CustomAlert"

interface AccountSettingsEditAddressFormProps {
  onCloseModal: () => void
}

interface FormData {
  address: string
  city: string
  province: string
  postal_code: string
  files?: {
    name: string
    size: number
    type: string
  }[]
}

const schema = yup.object().shape({
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  province: yup.string().required("Province is required"),
  postal_code: yup
    .string()
    .required("Postal code is required")
    .min(7, "Postal code must be 6 characters")
    .max(7, "Postal code must be 6 characters")
    .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, "Invalid postal code"),
  files: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      size: yup.number().required(),
      type: yup.string().required(),
    }),
  ),
})
function AccountSettingsEditAddressForm({
  onCloseModal,
}: AccountSettingsEditAddressFormProps) {
  const { t } = useTranslation()
  const { setAddress } = useAppContext()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const [files, setFiles] = useState<(File & { preview: string })[]>([])

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      address: "",
      city: "",
      province: "",
      postal_code: "",
      files: [],
    },
  })

  const { getRootProps, getInputProps, isFocused, fileRejections } =
    useDropzone({
      accept: {
        "image/*": [".jpeg", ".png", ".jpg", ".webp"],
        "application/pdf": [".pdf"],
      },
      maxSize: 4194304, // 4MB in bytes
      maxFiles: 1,
      onDrop: (acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        )
        setFiles(newFiles)
        setValue(
          "files",
          newFiles.map((file) => ({
            name: file.name,
            size: file.size,
            type: file.type,
          })),
        )
      },
    })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      <span>
        {file.name} - {bytesToMB(file.size)}&nbsp;{t("Mb")}
      </span>
      <ul>
        {errors.map((e) => (
          <li key={e.code}>
            <small>{t(e.message)}</small>
          </li>
        ))}
      </ul>
    </li>
  ))

  useEffect(() => {
    // Revoke the data URIs to avoid memory leaks
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [files])

  const deleteFile = (fileName: string) => {
    const updatedFiles = files.filter((file) => file.name !== fileName)
    setFiles(updatedFiles)
    setValue(
      "files",
      updatedFiles.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
      })),
    )
  }

  const onSubmit = async (data: FormData) => {
    try {
      await updateAddress(
        data.address,
        data.city,
        data.province,
        data.postal_code,
      )
      setAddress(
        `${data.address}, ${data.city}, ${data.province}, ${data.postal_code}`,
      )
      onCloseModal()
    } catch (e) {
      console.log("error updating phone number", e)
    }
  }
  return (
    <form
      className={"c-account-settings-section-edit-address-form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset>
        <legend>
          <span>{t("Please type in your correct address")}</span>

          <small>
            {t(
              "Ensure you are correctly typing your address as this change will require time to validate its accuracy.",
            )}
          </small>
        </legend>

        <div className="c-account-settings-section-edit-address-form-grid">
          <CustomInput labelText="Address" name="address" control={control} />

          <CustomInput labelText="City" name="city" control={control} />

          <CustomSelect
            labelText="Province"
            name="province"
            options={provinces}
            control={control}
          />

          <CustomInputPostalCode name="postal_code" control={control} />
        </div>
      </fieldset>

      <fieldset>
        <legend>{t("Please upload the supporting identification")}</legend>

        <Controller
          name="files"
          control={control}
          render={({ field }) => (
            <div
              {...getRootProps({ className: "dropzone" })}
              className={clsx(
                "c-form-group-file-dropzone",
                "c-with-dashed-border",
                isFocused && "is-focused",
              )}
            >
              <input {...getInputProps()} />
              <DropzoneText />
            </div>
          )}
        />

        {fileRejections.length > 0 && (
          <CustomAlert
            extraClassName="c-form-group-file-dropzone-alert rejected-files"
            alertType="danger"
            alertContent={
              <>
                <p>{t("The following files cannot be uploaded:")}</p>
                <ul>{fileRejectionItems}</ul>
              </>
            }
          />
        )}

        <aside className="c-form-group-file-dropzone-list">
          <p className="legend">{t("Uploaded Files")}</p>

          {files.length > 0 ? (
            <ul>
              {files.map((file) => (
                <li key={file.name}>
                  <i>
                    <IcnFile />
                  </i>

                  <div>
                    <p>{file.name}</p>
                    <small>
                      {bytesToMB(file.size)}&nbsp;{t("Mb")}
                    </small>
                  </div>

                  <Button
                    text={isMobileBreakpoint ? undefined : "Delete"}
                    title="Delete"
                    btnColor="white-outline"
                    btnSize={isMobileBreakpoint ? "xs" : "md"}
                    btnVariant={isMobileBreakpoint ? "icon" : undefined}
                    icon={isMobileBreakpoint && <IcnDelete />}
                    iconSize="xs"
                    onClick={() => deleteFile(file.name)}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <small className="default-empty">
              {t("No files have been uploaded yet")}
            </small>
          )}
        </aside>
      </fieldset>

      <div className="c-button-container">
        <Button text="Cancel" onClick={onCloseModal} />

        <Button
          type="submit"
          btnColor="blue"
          text="Save changes"
          disabled={!isValid || !isDirty}
        />
      </div>
    </form>
  )
}

export default AccountSettingsEditAddressForm
