import { LeasesResponse, loanDetails } from "../types/dashboard"

export const getCookie = (name: string): string => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const cookieValue = parts.pop()?.split(";").shift()
    return cookieValue ? decodeURIComponent(cookieValue) : ""
  }
  return ""
}
export function deleteCookie(name: string) {
  document.cookie = name + "=; Max-Age=-99999999;"
}

export const transformLeasesResponse = (data: LeasesResponse) => {
  const approved: loanDetails[] = []
  const pending: loanDetails[] = []
  const refused: loanDetails[] = []

  if (data.Approved) {
    for (const [id, details] of Object.entries(data.Approved)) {
      approved.push({ id: id, ...details } as loanDetails)
    }
  }

  if (data.Pending) {
    for (const [id, details] of Object.entries(data.Pending)) {
      pending.push({ id: id, ...details } as loanDetails)
    }
  }

  if (data.Refused) {
    for (const [id, details] of Object.entries(data.Refused)) {
      refused.push({ id: id, ...details } as loanDetails)
    }
  }

  return { approved, pending, refused }
}

export const formatPercentage = (value: number): string => {
  const percentageValue = value * 100

  const roundedValue = Math.round(percentageValue * 100) / 100

  const formattedValue = roundedValue.toFixed(2)

  return `${formattedValue}%`
}
