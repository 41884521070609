import {
  LeasesResponse,
  leaseOverviewResponse,
  upcomingPaymentApiResponse,
} from "../../types/dashboard"
import { fetchUserResponseInterface } from "../../types/user"
import { fetch } from "./fetchWrapper"

export interface GetAllLeasesResponse {
  status: number
  message: LeasesResponse
}

export const getAllLeases = async (
  cookies?: string[],
): Promise<GetAllLeasesResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, GetAllLeasesResponse>({
    endpoint: "/get-all-leases",
    method: "get",
    headers,
  })

  return response.data
}

export const getUserData = async (
  cookies?: string[],
): Promise<fetchUserResponseInterface> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, fetchUserResponseInterface>({
    endpoint: "/get-user-settings",
    method: "get",
    headers,
  })

  return response.data
}

export const getLeaseOverview = async (
  lease_id: string,
  cookies?: string[],
): Promise<leaseOverviewResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, leaseOverviewResponse>({
    endpoint: "/get-lease-overview",
    method: "get",
    headers,
    options: {
      params: { lease_id },
    },
  })

  return response.data
}

export const getUpcomingPayment = async (
  cookies?: string[],
): Promise<upcomingPaymentApiResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, upcomingPaymentApiResponse>({
    endpoint: "/get-upcoming-payment",
    method: "get",
    headers,
  })

  return response.data
}
