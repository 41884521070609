import React, { ReactNode } from "react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useLanguage } from "../../contexts/LanguageContext"
import Button from "./Button"
import { ReactComponent as IcnLanguage } from "../../assets/icons/icn-language.svg"

interface LanguageSwitcherProps {
  extraClassName?: string;
  hideIcon?: boolean;
  children?: ReactNode;
}

function LanguageSwitcher({ extraClassName, hideIcon, children }: LanguageSwitcherProps) {
  const { t } = useTranslation()
  const { language, setLanguage } = useLanguage()

  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "fr" : "en"
    setLanguage(newLanguage)
  }

  return (
    <Button
      extraClassName={clsx("language-switcher-button", extraClassName && extraClassName)}
      onClick={toggleLanguage}
      btnVariant="link"
      btnColor="ghost"
      title={t("Change language")}
      
    >
      {!hideIcon && (
        <i>
          <IcnLanguage />
        </i>
      )}
      <span>
        {language === "en" ? "English (CA)" : "Français (CA)"}
      </span>

      {children}

    </Button>
  )
}

export default LanguageSwitcher
