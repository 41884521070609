import { useLocation } from "react-router-dom"
import clsx from "clsx"
import { useMediaQuery } from "usehooks-ts"
import {useAppContext} from "../contexts/AppContext"
import { responsiveBreakpoints } from "../constant/constants"
import { LoanLayoutProps } from "../types/types"
import DashboardLayout from "./DashboardLayout"
import styles from "../styles/LoanLayout.module.scss"

function LoanNewLayout(
  {
    subPageTitle,
    subPageExtraClassName,
    sectionExtraClassName,
    children,
  }: LoanLayoutProps) {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  const location = useLocation()
  const isLoanNewPage = location.pathname === `/loan/new`

  const {activeLoan } = useAppContext()
  const id = activeLoan.message

  return (
    <DashboardLayout
      pageClassName={clsx(styles.cPageLoan, "c-page-loan", subPageExtraClassName && subPageExtraClassName, !isLoanNewPage && "subpage")}
      pageTitle={isLoanNewPage? "New loan" : `New loan | ${subPageTitle}`}

      navbarHasBackLink={isLoanNewPage && isMobileBreakpoint ? true : !isLoanNewPage}
      navbarBackLinkUrl={isLoanNewPage && isMobileBreakpoint ? '/dashboard': `/loan/new`}
      navbarBackLinkText={isLoanNewPage && isMobileBreakpoint ? "" : "Return to loan overview / new"}
      navbarBackLinkTitle={isLoanNewPage? "Back to Dashboard" : "Back to new loan"}
      navbarBackLinkVariant={isLoanNewPage && isMobileBreakpoint? "icon" : "link"}
      navbarTitle={isLoanNewPage ? "New loan" : ""}
    >
      <section className={clsx("c-loan", sectionExtraClassName)}>
        {children}
      </section>

    </DashboardLayout>
  )
}

export default LoanNewLayout