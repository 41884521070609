import React, {ReactNode} from "react"
import clsx from "clsx"
import {useTranslation} from "react-i18next"
import styles from "../../styles/CustomSectionBoxed.module.scss"

interface CustomSectionBoxedProps {
  classname?: string;
  headerContentType: "title" | "custom"
  headerTitle?: string;
  headerTitleParagraph?: string;
  headerContent?: ReactNode;
  children: ReactNode;
}
function CustomSectionBoxed(
  {
    classname,
    headerContentType = "title",
    headerTitle,
    headerTitleParagraph,
    headerContent,
    children
  }: CustomSectionBoxedProps) {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.cSectionBoxed, "c-section-boxed", classname && classname)}>

      <div className={clsx("c-section-boxed-header", classname &&`${classname}-header`)}>

        {headerContentType === "title" ? (
          <div className={clsx("c-section-boxed-header-title", classname &&`${classname}-header-title`)}>

            <h2 className="h5">
              {t(headerTitle as string)}
            </h2>

            <small>
              {t(headerTitleParagraph as string)}
            </small>

          </div>
        ):(
          <>{headerContent}</>
        )}

      </div>

      <div className={clsx("c-section-boxed-content", classname && `${classname}-content`)}>
        <div className="c-section-boxed-content-wrapper">
          {children}
        </div>
      </div>

    </div>
  )
}

export default CustomSectionBoxed