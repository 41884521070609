import {useTranslation} from "react-i18next"
import clsx from "clsx"
import AccountsLayout from "../layouts/AccountsLayout"
import LoginForm from "../components/forms/LoginForm"
import ButtonLink from "../components/common/ButtonLink"
import styles from "../styles/Login.module.scss"

function Login() {
  const { t } = useTranslation()

  return (
    <AccountsLayout
      pageExtraClassName={clsx(styles.cPageLogin, "c-page-login")}
      pageExtraTitle="Login"
    >

      <section className="c-accounts-section-form">

        <div className="c-accounts-section-form-wrapper">

          <div className="c-accounts-section-form-inner">

            <div className="c-accounts-section-form-header">

              <h1 className="h4">
                {t("Welcome back!")}
              </h1>

              <p>
                {t("Please enter your login credentials to continue")}
              </p>

            </div>

            <LoginForm/>

          </div>

          <div className="c-accounts-section-form-footer">
            <span>
              {t("New to Leasyfi?")}&nbsp;<ButtonLink url="/apply" btnColor="blue" btnVariant="link" text="Get started"/>
            </span>
          </div>

        </div>

      </section>

    </AccountsLayout>
  )
}

export default Login