import {useTranslation} from "react-i18next"
import clsx from "clsx"
import BaseLayout from "../layouts/BaseLayout"
import Logo from "../components/common/Logo"
import ButtonLink from "../components/common/ButtonLink"
import styles from "../styles/Error.module.scss"
function NotFound() {
  const { t } = useTranslation()

  return (
    <BaseLayout
      pageClassName={clsx(styles.cPageError, "c-page-error", "error-404")}
      pageTitle="Not found"
    >

      <main className="c-main">

        <section className="c-error">

          <div className="c-error-wrapper">

            <Logo/>

            <h1>404</h1>

            <h2 className="h4">
              {t("Oops! Looks like this page doesn't exist. Let's get you back on track...")}
            </h2>

            <ButtonLink
              url="/"
              btnColor="blue"
              text="Back to homepage"
            />

          </div>

        </section>

      </main>

    </BaseLayout>
  )
}

export default NotFound
