import * as yup from "yup"

const emptySchema = yup.object()

const currentYear = new Date().getFullYear()
const maxYear = currentYear - 18

const dateRegex = new RegExp(
  `^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19[0-9]{2}|20[0-9]{2}|${maxYear})$`,
)

const validationSchema = [
  yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
  }),

  yup.object({
    birthDate: yup
      .string()
      .required("Date of birth is required")
      .matches(dateRegex, `Enter a valid date in MM/DD/YYYY format.`)
      .test(
        "age",
        "Enter a valid date in MM/DD/YYYY format.",
        function (value) {
          const [month, day, year] = value.split("/")
          const birthDate = new Date(
            parseInt(year),
            parseInt(month) - 1,
            parseInt(day),
          )
          let age = new Date().getFullYear() - birthDate.getFullYear()
          const m = new Date().getMonth() - birthDate.getMonth()
          if (
            m < 0 ||
            (m === 0 && new Date().getDate() < birthDate.getDate())
          ) {
            age--
          }
          return age >= 18
        },
      ),
  }),

  yup.object({
    address_google: yup.string(),
    address: yup.object().shape({
      addressField: yup.string().required("Address is required"),
      city: yup.string().required("City is required"),
      province: yup.string().required("Province is required"),
      postal_code: yup
        .string()
        .required("Postal code is required")
        .min(7, "Postal code must be 6 characters")
        .max(7, "Postal code must be 6 characters")
        .matches(/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/, "Invalid postal code"),
    }),
  }),

  yup.object({
    employmentStatus: yup.string().required("Employment status is required"),
    jobTitle: yup
      .string()
      .test("jobTitle-required", "Job title is required", function (value) {
        const { employmentStatus } = this.parent
        return employmentStatus !== "Retired" &&
          employmentStatus !== "Unemployed"
          ? yup.string().required("Job title is required").isValidSync(value)
          : true
      }),
    employerName: yup
      .string()
      .test(
        "employerName-required",
        "Employer name is required",
        function (value) {
          const { employmentStatus } = this.parent
          return employmentStatus === "Full-Time" ||
            employmentStatus === "Part-Time"
            ? yup
                .string()
                .required("Employer name is required")
                .isValidSync(value)
            : true
        },
      ),
  }),

  yup.object({
    monthlyIncome: yup
      .number()
      .typeError("Monthly Income is required")
      .required("Monthly Income is required"),
    monthlyExpenses: yup
      .number()
      .typeError("Monthly Expenses is required")
      .required("Monthly Expenses is required"),
  }),

  yup.object({
    email: yup.string().email("Invalid email").required("Email is required"),
    mobileNumber: yup.string().required("Mobile number is required"),
  }),

  yup.object({
    smsValidationCode: yup.string().required("The code is required"),
  }),

  yup.object({
    terms: yup.boolean().oneOf([true], "Terms must be accepted"),
  }),

  emptySchema,
]

export default validationSchema
