// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomBadge_cBadge__11oHS.c-badge {
  --badge-bg-color: #FAFBFB;
  --badge-border-color: var(--medium-light-grey);
  --badge-color: var(--medium-grey);
  display: inline-flex;
  align-items: center;
  padding: var(--px1) var(--px8);
  background-color: var(--badge-bg-color);
  border: var(--px1) solid var(--badge-border-color);
  border-radius: var(--px4);
  color: var(--badge-color);
}
.CustomBadge_cBadge__11oHS.c-badge.info {
  --badge-bg-color: #C2EAF7;
  --badge-border-color: #83C6EB;
  --badge-color: #015669;
}
.CustomBadge_cBadge__11oHS.c-badge.success {
  --badge-bg-color: #D7F7C2;
  --badge-border-color: #A6EB83;
  --badge-color: #016908;
}
.CustomBadge_cBadge__11oHS.c-badge.warning {
  --badge-bg-color: #FDFCF5;
  --badge-border-color: #EAC771;
  --badge-color: #695201;
}
.CustomBadge_cBadge__11oHS.c-badge.danger {
  --badge-bg-color: #F7C2C2;
  --badge-border-color: #EB8383;
  --badge-color: #690101;
}
.CustomBadge_cBadge__11oHS.c-badge .c-badge-text {
  font-size: var(--px12);
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.CustomBadge_cBadge__11oHS.c-badge[data-size=sm] {
  padding: var(--px1) var(--px4);
}
.CustomBadge_cBadge__11oHS.c-badge[data-size=sm] .c-badge-text {
  font-size: var(--px11);
  letter-spacing: 0.02em;
}`, "",{"version":3,"sources":["webpack://./src/styles/CustomBadge.module.scss"],"names":[],"mappings":"AAEI;EACE,yBAAA;EACA,8CAAA;EACA,iCAAA;EAEA,oBAAA;EACA,mBAAA;EACA,8BAAA;EACA,uCAAA;EACA,kDAAA;EACA,yBAAA;EACA,yBAAA;AAFN;AAIM;EACE,yBAAA;EACA,6BAAA;EACA,sBAAA;AAFR;AAKM;EACE,yBAAA;EACA,6BAAA;EACA,sBAAA;AAHR;AAMM;EACE,yBAAA;EACA,6BAAA;EACA,sBAAA;AAJR;AAOM;EACE,yBAAA;EACA,6BAAA;EACA,sBAAA;AALR;AAQM;EACE,sBAAA;EACA,gBAAA;EACA,sBAAA;EACA,yBAAA;AANR;AASM;EACE,8BAAA;AAPR;AASQ;EACE,sBAAA;EACA,sBAAA;AAPV","sourcesContent":[".cBadge {\n  &:global {\n    &.c-badge {\n      --badge-bg-color: #FAFBFB;\n      --badge-border-color: var(--medium-light-grey);\n      --badge-color: var(--medium-grey);\n\n      display: inline-flex;\n      align-items: center;\n      padding: var(--px1) var(--px8);\n      background-color: var(--badge-bg-color);\n      border: var(--px1) solid var(--badge-border-color);\n      border-radius: var(--px4);\n      color: var(--badge-color);\n\n      &.info {\n        --badge-bg-color: #C2EAF7;\n        --badge-border-color: #83C6EB;\n        --badge-color: #015669;\n      }\n\n      &.success {\n        --badge-bg-color: #D7F7C2;\n        --badge-border-color: #A6EB83;\n        --badge-color: #016908;\n      }\n\n      &.warning {\n        --badge-bg-color: #FDFCF5;\n        --badge-border-color: #EAC771;\n        --badge-color: #695201;\n      }\n\n      &.danger {\n        --badge-bg-color: #F7C2C2;\n        --badge-border-color: #EB8383;\n        --badge-color: #690101;\n      }\n\n      .c-badge-text {\n        font-size: var(--px12);\n        font-weight: 500;\n        letter-spacing: 0.04em; //4% @12px\n        text-transform: uppercase;\n      }\n\n      &[data-size=\"sm\"] {\n        padding: var(--px1) var(--px4);\n\n        .c-badge-text {\n          font-size: var(--px11);\n          letter-spacing: 0.02em; // 2% &11px\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cBadge": `CustomBadge_cBadge__11oHS`
};
export default ___CSS_LOADER_EXPORT___;
