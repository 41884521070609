import React, { HTMLProps, ReactNode } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import {CustomButtonProps} from "../../types/types";

interface CustomInputRadioCheckboxProps extends CustomButtonProps, HTMLProps<HTMLInputElement> {
  extraClassName?: string
  labelText: string
  labelIsButton?: boolean
  type?: 'radio' | 'checkbox'
  id: string
  name: string
  defaultValue?: any
  checked?: boolean
  control: any
  helpText?: ReactNode
}

function CustomInputRadioCheckbox(
  {
    extraClassName,
    labelText,
    labelIsButton,
    btnColor,
    btnSize,
    btnVariant,
    type,
    id,
    name,
    defaultValue,
    checked,
    helpText,
    control,
    ...rest
  }: CustomInputRadioCheckboxProps) {
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field: { onChange, onBlur, value }, fieldState: { error },}) => (
        <div
          className={clsx(
            'c-form-group',
            'c-form-group-check',
            labelIsButton && 'is-button',
            extraClassName && extraClassName,
          )}
        >
          <input
            className={clsx(
              'c-form-group-check-input',
              labelIsButton && 'c-visually-hidden',
            )}
            type={type ? type : 'radio'}
            id={id}
            aria-invalid={!!error}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            checked={type === 'checkbox' ? value : value === id}
            {...rest}
          />

          <label
            htmlFor={id}
            className={clsx(
              'c-form-group-check-label',
              labelIsButton && 'c-button',
            )}
            data-color={labelIsButton && btnColor && btnColor}
            data-size={labelIsButton && btnSize && btnSize}
            data-variant={labelIsButton && btnVariant && btnVariant}
          >
            {t(labelText)}
          </label>

          {helpText && <div className="c-form-help-text">{helpText}</div>}

          {error && (
            <div className="c-form-error-feedback">
              {t(error.message || "")}
            </div>
          )}
        </div>
      )}
    />
  )
}

export default CustomInputRadioCheckbox
