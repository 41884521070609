import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useLoadScript, Autocomplete } from "@react-google-maps/api"
import CustomInputPattern from "../form-elements/CustomInputPattern"
import { provinces } from "../../constant/constants"
import CustomAlert from "../common/CustomAlert"
import CustomInput from "../form-elements/CustomInput"
import CustomSelect from "../form-elements/CustomSelect"
import CustomInputPostalCode from "../form-elements/CustomInputPostalCode"
import Button from "../common/Button"

const libraries: ("places" | "geometry")[] = ["places"]
export interface IAddress {
  addressField: string
  city: string
  province: string
  postal_code: string
}

interface FormData {
  dealership_name: string
  dealership_job_title: string
  first_name: string
  last_name: string
  dealership_email: string
  dealership_phone_number: string
  dealership_address: {
    address_google: string
    address: IAddress
  }
}

const schema = yup.object().shape({
  dealership_name: yup.string().required("Dealership name is required"),
  dealership_job_title: yup
    .string()
    .required("Dealership job title is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  dealership_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  dealership_phone_number: yup.string().required("Phone number is required"),
  dealership_address: yup.object().shape({
    address_google: yup.string(),
    address: yup.object().shape({
      addressField: yup.string().required("Address is required"),
      city: yup.string().required("City is required"),
      province: yup.string().required("Province is required"),
      postal_code: yup
        .string()
        .required("Postal code is required")
        .min(7, "Postal code must be 6 characters")
        .max(7, "Postal code must be 6 characters")
        .matches(/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/, "Invalid postal code"),
    }),
  }),
})

const defaultValues: FormData = {
  dealership_name: "",
  dealership_job_title: "",
  first_name: "",
  last_name: "",
  dealership_email: "",
  dealership_phone_number: "",
  dealership_address: {
    address_google: "",
    address: {
      addressField: "",
      city: "",
      province: "",
      postal_code: "",
    },
  },
}

function CommercialLeasingForm() {
  const { t } = useTranslation()

  const [showManualEntry, setShowManualEntry] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const methods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema) as any,
    mode: "onChange",
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = methods

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB-QjsJ3LzffiFH9UZ86zUXv9SX_Lz5crk",
    libraries: libraries,
  })
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)

  const parseAddress = (addressString: string) => {
    const parts = addressString?.split(",").map((part) => part.trim())

    const address = parts[0]
    const city = parts[1]
    const provinceAndPostal = parts[2]?.split(" ")
    const provinceAcronym = provinceAndPostal[0]
    const postal_code = provinceAndPostal[1] + " " + provinceAndPostal[2]

    const provinceFullName =
      provinces.find(
        (province) =>
          province.label.toLowerCase() === provinceAcronym.toLowerCase(),
      )?.value || ""

    return {
      addressField: address,
      city,
      province: provinceFullName,
      postal_code,
    }
  }

  const handleAddressSelect = () => {
    const place = autocompleteRef.current?.getPlace()
    if (place && place.formatted_address) {
      setValue("dealership_address.address_google", place.formatted_address, {
        shouldValidate: false,
      })
      setValue(
        "dealership_address.address",
        parseAddress(place.formatted_address),
        {
          shouldValidate: true,
        },
      )
    }
  }

  const toggleManualEntry = () => {
    setShowManualEntry(!showManualEntry)
    if (!showManualEntry) {
      setValue("dealership_address.address_google", "", {
        shouldValidate: true,
      })
    }
  }

  if (!isLoaded) return null
  if (loadError) return <div>Error loading maps</div>

  const onSubmit = (data: FormData) => {
    console.log(data)
  }

  return (
    <form
      className="c-commercial-leasing-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="c-commercial-leasing-form-wrapper">
        <div className="c-commercial-leasing-form-title">
          <h3 className="h2">
            {t("Welcome, let’s get your dealership Leasyfi certified.")}
          </h3>
        </div>

        <fieldset>
          <legend>{t("What is your dealerships name?")}</legend>

          <div className="c-form-grid">
            <CustomInput
              labelText="Dealership name"
              name="dealership_name"
              control={control}
            />

            <CustomInput
              labelText="Dealership job title"
              name="dealership_job_title"
              control={control}
            />
          </div>
        </fieldset>

        <fieldset>
          <legend>{t("Tell us about yourself")}</legend>

          <div className="c-form-grid">
            <CustomInput
              labelText="Legal first name"
              name="first_name"
              control={control}
            />

            <CustomInput
              labelText="Legal last name"
              name="last_name"
              control={control}
            />
          </div>
        </fieldset>

        <fieldset>
          <legend>{t("How can we contact you?")}</legend>

          <div className="c-form-grid">
            <CustomInput
              type="email"
              labelText="Dealership email address"
              name="dealership_email"
              control={control}
            />
            <CustomInputPattern
              labelText="Dealership phone number"
              id="id_dealership_phone_number"
              name="dealership_phone_number"
              placeholder=" "
              control={control}
              format={"+1 (###) ### ####"}
            />
          </div>
        </fieldset>

        <fieldset>
          <legend>{t("Where is your dealership located?")}</legend>

          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={handleAddressSelect}
            options={{ componentRestrictions: { country: "ca" } }}
          >
            <CustomInput
              labelText="Dealership address"
              name="dealership_address.address_google"
              control={control}
              disabled={showManualEntry}
              helpText={t(
                "Note: We only offer our services to Canada for the time being.",
              )}
            />
          </Autocomplete>

          <Button
            extraClassName="c-form-manual-address-button"
            btnVariant="link"
            btnColor="blue"
            text="Enter manually"
            onClick={toggleManualEntry}
          />

          {showManualEntry && (
            <div className="c-form-manual-address">
              <CustomInput
                labelText={t("Address")}
                name="dealership_address.address.addressField"
                control={control}
              />

              <div className="c-form-grid">
                <CustomInput
                  labelText={t("City")}
                  name="dealership_address.address.city"
                  control={control}
                />

                <CustomSelect
                  labelText={t("Province")}
                  name="dealership_address.address.province"
                  options={provinces}
                  control={control}
                />

                <CustomInputPostalCode
                  name="dealership_address.address.postal_code"
                  control={control}
                />
              </div>
            </div>
          )}
        </fieldset>
      </div>

      <div className="c-button-container">
        <Button
          type="submit"
          btnColor="blue"
          text="Send"
          disabled={!isValid || !isDirty}
        />
      </div>

      {submitSuccess && (
        <CustomAlert
          extraClassName="c-form-alert"
          alertType="success"
          alertText="Your request has successfully been submitted. The Leasyfi team will contact you as soon as possible."
        />
      )}
    </form>
  )
}

export default CommercialLeasingForm
