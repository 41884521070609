import {Link} from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import {CustomButtonProps} from "../../types/types"

interface ButtonLinkProps extends CustomButtonProps {
  extraClassName?: string;
  url: string;
  isDisabled?: boolean;
}

function ButtonLink(
  {
    extraClassName,
    url,
    text,
    title,
    children,
    btnColor,
    btnSize,
    btnVariant,
    icon,
    iconSize,
    iconPlacement,
    isDisabled,
    ...props
  }: ButtonLinkProps) {
  const { t } = useTranslation()
  return (
    <Link
      to={url}
      className={clsx("c-button", extraClassName && extraClassName, isDisabled && "is-disabled")}
      title={t(title ?? text ?? "Link Title") as string}
      aria-label={t(title ?? text ?? "Link Title") as string}
      data-color={btnColor && btnColor}
      data-size={btnSize && btnSize}
      data-variant={btnVariant && btnVariant}
      data-icon-placement={iconPlacement && iconPlacement}
       data-icon-size={iconSize && iconSize}
      {...props}
    >
      {icon && (<i>{icon}</i>)}
      {text ? <span>{t(text)}</span> : children}
    </Link>
  )
}

export default ButtonLink