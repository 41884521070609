import {ReactNode} from "react"
import {useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {useMediaQuery} from "usehooks-ts"
import {responsiveBreakpoints} from "../../constant/constants"
import Logo from "./Logo"
import ButtonLink from "./ButtonLink"
import GetSupportLink from "./GetSupportLink"
import {ReactComponent as IcnChevronLeft} from "../../assets/icons/icn-chevron-left.svg"
import BackButton from "./BackButton"

interface NavbarProps {
  navbarTitle?: string;
  children?: ReactNode;
}

function NavbarAccounts({navbarTitle, children}:NavbarProps) {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const location = useLocation()
  const shouldDisplaySupportLink = location.pathname!== "/support"

  return (
    <>
      {isMobileBreakpoint? (
          <BackButton
            extraClassName="c-navbar-back-button"
            backLinkUrl="/"
            backLinkText={undefined}
            backLinkTitle="Back to Homepage"
            backLinkVariant="icon"
          />
        ):(
        <Logo/>
        )}

      {navbarTitle ? <h1 className="h4 c-navbar-title">{t(navbarTitle)}</h1> : children ? children : null}

      {shouldDisplaySupportLink && (
        <div className="c-button-container">
          <GetSupportLink
            hasMobileVersion
            btnVariant={undefined}
            btnColor={isMobileBreakpoint? undefined : "blue"}
          />
        </div>
      )}

    </>
  )
}

export default NavbarAccounts