import { consentRespone } from "../../types/leaseDetails"
import { BasicApiResponse } from "../../types/types"
import { fetch } from "./fetchWrapper"

export const updateEquifaxConsent = async (
  checked: boolean,
  lease_id: string,
): Promise<BasicApiResponse> => {
  const response = await fetch<null, BasicApiResponse>({
    endpoint: "/update-equifax-consent",
    method: "post",
    payload: null,
    options: {
      params: { checked, lease_id },
    },
  })

  return response.data
}

export const getEquifaxConsentStatus = async (
  lease_id: string,
  cookies?: string[],
): Promise<boolean> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, consentRespone>({
    endpoint: "/get-equifax-consent",
    method: "get",
    headers,
    options: {
      params: { lease_id },
    },
  })

  return response.data.message
}
