import {ReactNode} from "react"
import clsx from "clsx"
import {useMediaQuery} from "usehooks-ts"
import {responsiveBreakpoints} from "../constant/constants"
import BaseLayout from "./BaseLayout"
import Header from "../components/common/Header"
import NavbarAccounts from "../components/common/NavbarAccounts"
import Main from "../components/common/Main"
import Footer from "../components/common/Footer"
import styles from "../styles/AccountsLayout.module.scss"
interface AccountsLayoutProps {
  pageExtraClassName?: string;
  pageExtraTitle: string;
  navbar?: ReactNode;
  sectionExtraClassName?: string;
  children?: ReactNode;
}

function AccountsLayout(
  {
    pageExtraClassName,
    pageExtraTitle,
    navbar,
    sectionExtraClassName,
    children
  }:AccountsLayoutProps) {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  return (
    <BaseLayout
      bodyClassName="c-body-accounts"
      pageTitle={pageExtraTitle}
      pageClassName={clsx(styles.cPageAccounts, "c-page-accounts", pageExtraClassName && pageExtraClassName)}
    >
      <Header>
        {navbar? (
          navbar
        ) : (
          <NavbarAccounts navbarTitle={isMobileBreakpoint? pageExtraTitle : undefined}/>
        )}
      </Header>

      <Main>
        <section className={clsx(sectionExtraClassName ? sectionExtraClassName : "c-accounts")}>
          {children}
        </section>
      </Main>

      <Footer/>

    </BaseLayout>
  )
}

export default AccountsLayout