import { useTranslation } from "react-i18next"
import clsx from "clsx"
import PublicPageLayout from "../layouts/PublicPageLayout"
import CustomSectionBlueBg from "../components/common/CustomSectionBlueBg"
import styles from "../styles/CommercialLeasing.module.scss"
import CommercialLeasingForm from "../components/forms/CommercialLeasingForm"
import { CommercialLeasingList } from "../constant/constants"

function CommercialLeasing() {
  const { t } = useTranslation()

  return (
    <PublicPageLayout
      pageClassName={clsx(styles.cPageCommercialLeasing, "c-page-commercial-leasing")}
      pageTitle="Commercial leasing"
    >
      <CustomSectionBlueBg
        extraClassName="c-commercial-leasing"
        title="Focus on selling, we'll take care of the rest"
        paragraph="Leasyfi provides efficient and adaptable car financing solutions. Check your eligibility in just 60 seconds, with no impact on your credit score."
      >
        <div className="c-commercial-leasing-section-form">

          <div className="c-commercial-leasing-section-form-header">

            <div className="c-commercial-leasing-section-form-header-title">
              <h2 className="h4">
                {t("Looking to partner with Leasyfi? Here's what we're seeking in dealerships")}
              </h2>
            </div>

            <ul className="c-commercial-leasing-section-form-header-list">
              {CommercialLeasingList.map((list, commercialListIdx) => (
                <li key={commercialListIdx}>
                  <article>
                    <i>{list.icon}</i>
                    <h3 className="h5">{t(list.title)}</h3>
                    <p>{t(list.text)}</p>
                  </article>
                </li>
              ))}
            </ul>



          </div>

          <div className="c-commercial-leasing-section-form-content">

            <CommercialLeasingForm/>

          </div>

        </div>

      </CustomSectionBlueBg>

    </PublicPageLayout>
  )
}

export default CommercialLeasing