// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_cPageLogin__w4vTM .c-accounts-section-form form .c-flexbox {
  --flexbox-justify: space-between;
  --flexbox-wrap: wrap;
  margin-bottom: 4.25em;
}
.Login_cPageLogin__w4vTM .c-accounts-section-form form .c-flexbox .c-form-group-check {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .Login_cPageLogin__w4vTM .c-accounts-section-form form .c-flexbox {
    margin-bottom: var(--px32);
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Login.module.scss"],"names":[],"mappings":"AAKU;EACE,gCAAA;EACA,oBAAA;EAEA,qBAAA;AALZ;AAOY;EACE,gBAAA;AALd;AAWM;EACE;IACE,0BAAA;EATR;AACF","sourcesContent":[".cPageLogin {\n  :global {\n    .c-accounts-section {\n      &-form {\n        form {\n          .c-flexbox {\n            --flexbox-justify: space-between;\n            --flexbox-wrap: wrap;\n\n            margin-bottom: 4.25em; //68px\n\n            .c-form-group-check {\n              margin-bottom: 0;\n            }\n          }\n        }\n      }\n\n      @media (max-width: 991px) {\n        &-form form .c-flexbox {\n          margin-bottom: var(--px32);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cPageLogin": `Login_cPageLogin__w4vTM`
};
export default ___CSS_LOADER_EXPORT___;
