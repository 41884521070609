import { useRef } from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { PublicPageNavItems } from "../../constant/constants"
import CustomMobileMenu from "./CustomMobileMenu"
import NavItemsPublicSocial from "./NavItemsPublicSocial"
import CustomBadge from "./CustomBadge"
import LanguageSwitcher from "./LanguageSwitcher"
import ButtonLink from "./ButtonLink"
import { ReactComponent as IcnChevronRight } from "../../assets/icons/icn-chevron-right.svg"
import styles from "../../styles/PublicPageMobileMenu.module.scss"

function MobileMenuPublic() {
  const { t } = useTranslation()
  const mobileMenuPublicRef = useRef<HTMLDivElement>(null)

  return (
    <CustomMobileMenu
      extraClassName={clsx(styles.cPublicMobileMenu, "c-public-mobile-menu")}
      ref={mobileMenuPublicRef}
      menuBtnSize="md"
      menuSubHeader={
        <>
          <div className="c-button-container">
            <ButtonLink url="/login" btnSize="md" text="Login"/>
            <ButtonLink url="/apply" btnSize="md" btnColor="blue" text="Get approved"/>
          </div>

          <LanguageSwitcher>
            <small>{t("Change")}</small>
          </LanguageSwitcher>
        </>
      }
    >

      {PublicPageNavItems.map((nav, navIdx) => {
        return (
          <nav
            key={navIdx}
            className={clsx(nav.categoryId)}
            aria-label={t(nav.category)}
          >
            <p>
              {t(nav.category)}
            </p>

            <ul>
              {nav.navItems.map((link, linkIdx) => (

                <li key={linkIdx}>

                  <NavLink
                    to={link.url}
                    title={t(link.title)}
                    className={clsx(link.url === "#" && "is-disabled")}
                    aria-disabled={link.url === "#"}
                  >
                    <i>{link.icon}</i>

                    <span>
                      {t(link.title)}&nbsp;
                      {link.isComingSoon && (
                        <CustomBadge badgeType="default" badgeText="Coming soon"/>
                      )}
                    </span>

                    <i><IcnChevronRight /></i>

                  </NavLink>

                </li>

              ))}

            </ul>

          </nav>
        )
      })}

      <NavItemsPublicSocial/>

    </CustomMobileMenu>
  )
}

export default MobileMenuPublic
