import { NavLink } from "react-router-dom"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { PublicPageDesktopNavItems } from "../../constant/constants"

interface NavItemsPublicProps {
  extraClassName?: string;
}

function NavItemsPublicDesktop({extraClassName}: NavItemsPublicProps) {
  const { t } = useTranslation()

  return (
    <ul className={clsx("c-navbar-nav", extraClassName && extraClassName)}>
      {PublicPageDesktopNavItems.map((item, idx) => (
        <li key={idx}>
          <NavLink
            to={item.url}
            title={t(item.title)}
            aria-disabled={item.url === "#"}
          >
            {t(item.title)}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default NavItemsPublicDesktop
