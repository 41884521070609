import { fetch } from "./fetchWrapper"
import {
  User,
  CreateUserRequest,
  loginCredentials,
  resetPasswordData,
} from "../../types/user"
import { BasicApiResponse } from "../../types/types"

export const signup = async (data: CreateUserRequest): Promise<User> => {
  const response = await fetch<CreateUserRequest, User>({
    endpoint: "/signup",
    method: "post",
    payload: data,
  })

  return response.data
}

export const resetPassword = async (
  data: resetPasswordData,
): Promise<BasicApiResponse> => {
  const response = await fetch<resetPasswordData, BasicApiResponse>({
    endpoint: "/reset-password",
    method: "post",
    payload: data,
  })

  return response.data
}

export const checkAuthKey = async (
  auth_key: string,
): Promise<BasicApiResponse> => {
  const response = await fetch<null, BasicApiResponse>({
    endpoint: "/check-auth",
    method: "get",
    options: {
      params: { auth_key },
    },
  })

  return response.data
}

export const loginUser = async (
  data: loginCredentials,
): Promise<{ userData: loginCredentials; cookies?: string[] }> => {
  const response = await fetch<loginCredentials, loginCredentials>({
    endpoint: "login",
    method: "post",
    payload: data,
  })

  const cookies = response.headers ? response.headers["set-cookie"] : undefined
  return {
    userData: response.data,
    cookies,
  }
}
export const updatePhoneNumber = async (
  newPhoneNumber: string,
  password: string,
  cookies?: string[],
): Promise<BasicApiResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<
    { new_phone_number: string; password: string },
    BasicApiResponse
  >({
    endpoint: "/update-user-phone-number",
    method: "post",
    payload: { new_phone_number: newPhoneNumber, password },
    headers,
  })

  return response.data
}

export const updateAddress = async (
  address: string,
  city: string,
  province: string,
  postal_code: string,
  cookies?: string[],
): Promise<BasicApiResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<
    { address: string; city: string; province: string; postal_code: string },
    BasicApiResponse
  >({
    endpoint: "/update-user-address",
    method: "post",
    payload: { address, city, province, postal_code },
    headers,
  })

  return response.data
}

export const contactSupport = async (
  first_name: string,
  last_name: string,
  email: string,
  subject: string,
  description: string,
): Promise<BasicApiResponse> => {
  const response = await fetch<
    {
      first_name: string
      last_name: string
      email: string
      subject: string
      description: string
    },
    BasicApiResponse
  >({
    endpoint: "/new-issue",
    method: "post",
    payload: { first_name, last_name, email, subject, description },
  })

  return response.data
}
