import React from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { Controller } from "react-hook-form"
import { NumericFormat, NumericFormatProps } from "react-number-format"

interface CustomNumberInputProps extends NumericFormatProps {
  extraClassName?: string
  labelText: string
  isLabelHidden?: boolean
  name: string
  defaultValue?: string | number
  placeholder?: string
  control: any
  helpText?: React.ReactNode
}

function CustomNumberInput({
                             extraClassName,
                             labelText,
                             isLabelHidden,
                             name,
                             defaultValue,
                             placeholder,
                             helpText,
                             control,
                             ...rest
                           }: CustomNumberInputProps) {
  const { t } = useTranslation()

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = parseFloat(event.target.value.replace(/[^0-9.]/g, ""))
    return numericValue
  }

  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={(
          {
            field: { onChange, onBlur, value, ref },
            fieldState: { error },
          }) => (
          <div className={clsx("c-form-group", extraClassName)}>

            <div className="c-form-group-input-wrapper">

              <NumericFormat
                id={`id_${name}`}
                placeholder={placeholder ? t(placeholder) : " "}
                value={value}
                onChange={(e) => onChange(handleValueChange(e))}
                onBlur={onBlur}
                getInputRef={ref}
                displayType="input"
                thousandSeparator=","
                decimalSeparator="."
                aria-invalid={!!error}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                  }
                }}
                {...rest}
              />

              <label
                htmlFor={`id_${name}`}
                className={clsx(isLabelHidden && "c-visually-hidden")}
              >
                {t(labelText)}
              </label>

            </div>

            {error && (
              <div className="c-form-error-feedback">
                {t(error.message || "")}
              </div>
            )}

            {helpText && <div className="c-form-help-text">{helpText}</div>}

          </div>
        )}
      />
    </>
  )
}

export default CustomNumberInput
