import { axiosClient } from "./axiosClient"
import { AxiosRequestConfig, AxiosError } from "axios"
import { FetchParams } from "../../types/types"

export const fetch = async <R, T>({
  endpoint,
  method,
  payload,
  headers = {},
  options,
}: FetchParams<R, T>): Promise<{ data: T; headers?: any }> => {
  const config: AxiosRequestConfig = {
    ...options,
    headers,
  }

  try {
    let response
    switch (method.toLowerCase()) {
      case "get":
        response = await axiosClient.get<T>(endpoint, config)
        break
      case "post":
        response = await axiosClient.post<T>(endpoint, payload, config)
        break
      case "put":
        response = await axiosClient.put<T>(endpoint, payload, config)
        break
      case "delete":
        response = await axiosClient.delete<T>(endpoint, config)
        break
      default:
        throw new Error("Method not supported")
    }

    return { data: response.data, headers: response.headers }
  } catch (error) {
    const axiosError = error as AxiosError
    console.error(`API call error: ${error}`)
    handleCustomEvent(axiosError)
    throw axiosError
  }
}

function handleCustomEvent(axiosError: AxiosError) {
  let customEvent: CustomEvent
  if (axiosError.response?.status === 400) {
    customEvent = new CustomEvent("bad-credentials-error", {
      detail: axiosError,
    })
  } else if (axiosError.response?.status === 401) {
    customEvent = new CustomEvent("unauthorized-error", { detail: axiosError })
  } else if (axiosError.response?.status === 403) {
    customEvent = new CustomEvent("unsubscribed-error", { detail: axiosError })
  } else if (axiosError.response?.status === 404) {
    customEvent = new CustomEvent("notfound-error", { detail: axiosError })
  } else if (axiosError.response?.status === 405) {
    customEvent = new CustomEvent("methodNotAllowed-error", {
      detail: axiosError,
    })
  } else if (axiosError.response?.status === 409) {
    customEvent = new CustomEvent("alreadyExists-error", { detail: axiosError })
  } else if (axiosError.response?.status === 413) {
    customEvent = new CustomEvent("contentTooLarge-error", {
      detail: axiosError,
    })
  } else if (axiosError.response?.status === 422) {
    customEvent = new CustomEvent("invalidQuery-error", { detail: axiosError })
  } else if (axiosError.response?.status === 500) {
    customEvent = new CustomEvent("internalServer-error", {
      detail: axiosError,
    })
  } else if (axiosError.response?.status === 503) {
    customEvent = new CustomEvent("serverDown-error", { detail: axiosError })
  } else {
    customEvent = new CustomEvent("general-error", { detail: axiosError })
  }

  window.dispatchEvent(customEvent)
}
