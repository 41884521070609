// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PublicPageMobileMenu_cPublicMobileMenu__XXsFy.c-public-mobile-menu .c-custom-mobile-menu-content-subheader {
  padding-bottom: var(--px8);
}
.PublicPageMobileMenu_cPublicMobileMenu__XXsFy.c-public-mobile-menu .c-custom-mobile-menu-content-subheader > .c-button-container {
  --btn-container-wrap: wrap;
  padding: var(--px24);
  border-bottom: var(--px1) solid var(--medium-light-grey);
}
.PublicPageMobileMenu_cPublicMobileMenu__XXsFy.c-public-mobile-menu .c-custom-mobile-menu-content-subheader > .c-button-container .c-button {
  flex: 1 1;
}
.PublicPageMobileMenu_cPublicMobileMenu__XXsFy.c-public-mobile-menu .c-custom-mobile-menu-content-subheader .language-switcher-button {
  --btn-padding-y: var(--font-size-14-px12);
  --btn-padding-x: var(--font-size-14-px24);
  --btn-bg-color: var(--white);
  --btn-color: var(--body-color);
  --btn-icon-size: var(--font-size-14-px16);
  --btn-font-size: var(--px14);
  gap: var(--font-size-14-px12);
  width: 100%;
  border-bottom: var(--font-size-14-px1) solid var(--medium-light-grey);
}
.PublicPageMobileMenu_cPublicMobileMenu__XXsFy.c-public-mobile-menu .c-custom-mobile-menu-content-subheader .language-switcher-button i {
  color: var(--medium-grey);
}
.PublicPageMobileMenu_cPublicMobileMenu__XXsFy.c-public-mobile-menu .c-custom-mobile-menu-content-subheader .language-switcher-button small {
  margin-left: auto;
  color: var(--medium-grey);
  font-size: var(--font-size-14-px12);
}
.PublicPageMobileMenu_cPublicMobileMenu__XXsFy.c-public-mobile-menu .c-public-nav-list-social {
  padding-left: var(--px24);
  padding-right: var(--px24);
}`, "",{"version":3,"sources":["webpack://./src/styles/PublicPageMobileMenu.module.scss"],"names":[],"mappings":"AAGM;EACE,0BAAA;AAFR;AAIQ;EACE,0BAAA;EAEA,oBAAA;EACA,wDAAA;AAHV;AAKU;EACE,SAAA;AAHZ;AAOQ;EACE,yCAAA;EACA,yCAAA;EACA,4BAAA;EACA,8BAAA;EACA,yCAAA;EACA,4BAAA;EAEA,6BAAA;EACA,WAAA;EACA,qEAAA;AANV;AAQU;EACE,yBAAA;AANZ;AASU;EACE,iBAAA;EACA,yBAAA;EACA,mCAAA;AAPZ;AAYM;EACE,yBAAA;EACA,0BAAA;AAVR","sourcesContent":[".cPublicMobileMenu {\n  &:global {\n    &.c-public-mobile-menu {\n      .c-custom-mobile-menu-content-subheader {\n        padding-bottom: var(--px8);\n\n        >.c-button-container {\n          --btn-container-wrap: wrap;\n\n          padding: var(--px24);\n          border-bottom: var(--px1) solid var(--medium-light-grey);\n\n          .c-button {\n            flex: 1;\n          }\n        }\n\n        .language-switcher-button {\n          --btn-padding-y: var(--font-size-14-px12);\n          --btn-padding-x: var(--font-size-14-px24);\n          --btn-bg-color: var(--white);\n          --btn-color: var(--body-color);\n          --btn-icon-size: var(--font-size-14-px16);\n          --btn-font-size: var(--px14);\n\n          gap: var(--font-size-14-px12);\n          width: 100%;\n          border-bottom: var(--font-size-14-px1) solid var(--medium-light-grey);\n\n          i {\n            color: var(--medium-grey);\n          }\n\n          small {\n            margin-left: auto;\n            color: var(--medium-grey);\n            font-size: var(--font-size-14-px12);\n          }\n        }\n      }\n\n      .c-public-nav-list-social {\n        padding-left: var(--px24);\n        padding-right: var(--px24);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cPublicMobileMenu": `PublicPageMobileMenu_cPublicMobileMenu__XXsFy`
};
export default ___CSS_LOADER_EXPORT___;
