import { BasicApiResponse } from "../../types/types"
import { fetch } from "./fetchWrapper"

export const getLeaseOptions = async (
  lease_id: string,
  cookies?: string[],
): Promise<BasicApiResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, BasicApiResponse>({
    endpoint: "/get-lease-option",
    method: "get",
    headers,
    options: {
      params: { lease_id },
    },
  })

  return response.data
}

export const updateLeaseOptions = async (
  lease_id: string,
  lease_option: string,
  cookies?: string[],
): Promise<BasicApiResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, BasicApiResponse>({
    endpoint: "/update-lease-option",
    method: "post",
    headers,
    options: {
      params: { lease_id, lease_option },
    },
  })

  return response.data
}
