import { ReactNode, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import * as yup from "yup"
import { useForm, FormProvider, Resolver } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import validationSchema from "./validationSchema"
import moment from "moment"
import { useMediaQuery } from "usehooks-ts"
import { useLanguage } from "../../contexts/LanguageContext"
import { useAppContext } from "../../contexts/AppContext"
import {
  FormData,
  SmsAuthNmbr,
  PhoneNumberData,
  IAddressApi,
  PreApprovalAPI,
} from "../../types/preApproval"
import {
  createPreApproval,
  submitSmsAuth,
  submitPhoneNumber,
  createLinkToken,
  getPreApprovalId,
} from "../../services/api/preApprovalService"
import { responsiveBreakpoints } from "../../constant/constants"
import PreApprovalLayout from "../../layouts/PreApprovalLayout"
import PlaidLink from "../../components/PlaidLink"
import FormStep0 from "../../components/forms/preApprovalFormSteps/FormStep0"
import FormStep1 from "../../components/forms/preApprovalFormSteps/FormStep1"
import FormStep2 from "../../components/forms/preApprovalFormSteps/FormStep2"
import FormStep3 from "../../components/forms/preApprovalFormSteps/FormStep3"
import FormStep4 from "../../components/forms/preApprovalFormSteps/FormStep4"
import FormStep5 from "../../components/forms/preApprovalFormSteps/FormStep5"
import FormStep6 from "../../components/forms/preApprovalFormSteps/FormStep6"
import FormStep7 from "../../components/forms/preApprovalFormSteps/FormStep7"
import FormStep8 from "../../components/forms/preApprovalFormSteps/FormStep8"
import Button from "../../components/common/Button"
import { ReactComponent as IcnChevronLeft } from "../../assets/icons/icn-chevron-left.svg"
import { ReactComponent as IcnVerified } from "../../assets/icons/icn-shield-check.svg"
import { ReactComponent as IcnCertified } from "../../assets/icons/icn-badge-check.svg"
import styles from "../../styles/PreApprovalForm.module.scss"

interface StepProps {
  id: number
  className: string
  category: string
  title: string
  field: ReactNode
  footerNote?: string
  subFooterNote?: string
  subFooterIcon?: ReactNode
}

interface GroupedStepProps {
  title: string
  category: string
  className: string
  steps: StepProps[]
}

const defaultValues: FormData = {
  firstName: "",
  lastName: "",
  birthDate: "",
  address: {
    addressField: "",
    city: "",
    province: "",
    postal_code: "",
  },
  employmentStatus: "",
  jobTitle: "",
  employerName: "",
  monthlyIncome: "",
  monthlyExpenses: "",
  email: "",
  mobileNumber: "",
  smsValidationCode: "",
  terms: true,
}

function PreApproval() {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const { language } = useLanguage()
  const { setActiveLoan, setUserEmail, setFirstName, setFullName } =
    useAppContext()

  const [emailInUse, setEmailInUse] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [token, setToken] = useState("")
  const [userToken, setUserToken] = useState("")

  const noValidationResolver: Resolver<FormData> = async (values) => {
    return {
      values,
      errors: {},
    }
  }

  const getResolver = (
    schema: yup.ObjectSchema<any> | null,
  ): Resolver<FormData> => {
    return schema ? yupResolver(schema) : noValidationResolver
  }

  const methods = useForm<FormData>({
    defaultValues,
    resolver: getResolver(validationSchema[activeStep]),
    mode: "onChange",
  })

  const {
    control,
    trigger,
    handleSubmit,
    watch,
    formState: { isValid },
  } = methods

  const firstName = watch("firstName", "")
  const lastName = watch("lastName", "")
  const birthDate = watch("birthDate", "")
  const email = watch("email", "")
  const employmentStatus = watch("employmentStatus")

  function deleteAllCookies() {
    const cookies = document.cookie.split(";")

    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=")
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && buttonRef.current) {
        buttonRef.current.click()
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    deleteAllCookies()
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  useEffect(() => {
    setUserEmail(email)
    setFirstName(firstName)
    setFullName(`${firstName} ${lastName}`)
  }, [email, setUserEmail, firstName, setFirstName, setFullName, lastName])

  const handleNext = async () => {
    let isValid = true
    if (validationSchema[activeStep]) {
      isValid = await trigger()
    }
    console.log("isValid:", isValid)

    if (isValid && activeStep < steps.length - 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleGetSMSAuth = async (formData: FormData) => {
    const apiData: PhoneNumberData = {
      email: formData.email,
      phone_number: formData.mobileNumber,
    }

    try {
      await submitPhoneNumber(apiData)
    } catch (error) {
      console.error("Error submitting phone number:", error)
      throw error
    }
  }

  const handleVerifySMS = async (formData: FormData) => {
    const apiData: SmsAuthNmbr = {
      email: formData.email,
      phone_number: formData.mobileNumber,
      sms_code: formData.smsValidationCode,
    }

    try {
      await submitSmsAuth(apiData)
      handleNext()
    } catch (error) {
      console.error("Error submitting phone number:", error)
    }
  }

  const handleSubmitForm = async (formData: FormData) => {
    const dateFromPicker = formData.birthDate
    const formattedDate = moment(dateFromPicker).format("YYYY-MM-DD")

    const addressData: IAddressApi = {
      address: formData.address?.addressField || "",
      city: formData.address.city || "",
      province: formData.address.province,
      postal_code: formData.address.postal_code,
    }

    const apiData: PreApprovalAPI = {
      legal_first_name: formData.firstName,
      legal_last_name: formData.lastName,
      birth_date: formattedDate,
      address: addressData,
      employment_status: formData.employmentStatus,
      job_title: formData.jobTitle || "",
      employer_name: formData.employerName || "",
      user_monthly_income: parseInt(formData.monthlyIncome || "0"),
      user_monthly_expenses: parseInt(formData.monthlyExpenses || "0"),
      email: formData.email,
      mobile_number: formData.mobileNumber,
      language_preference: language.toLocaleLowerCase(),
    }

    if (activeStep === 7) {
      const response = await createLinkToken(apiData)
      setToken(response.message.link_token)
      setUserToken(response.message.user_token)
      const preApprovalId = await getPreApprovalId(formData.email)
      setActiveLoan(preApprovalId)
      handleNext()
    } else {
      try {
        setEmailInUse(false)
        await createPreApproval(apiData)
      } catch (error: any) {
        if (error.response.request.status === 409) {
          setEmailInUse(true)
        }
        throw error
      }
    }
  }

  const handleFormSubmission = async (formData: FormData) => {
    try {
      await handleSubmitForm(formData)
      await handleGetSMSAuth(formData)
      handleNext()
    } catch (error) {
      console.error("Error in form submission flow:", error)
    }
  }

  const steps: StepProps[] = [
    {
      id: 0,
      category: "personal-details",
      className: "personal-details",
      title: "Welcome, let’s get you into your new car.",
      field: <FormStep0 control={control} />,
      footerNote: undefined,
      subFooterIcon: <IcnVerified />,
      subFooterNote: "Your credit rating won't be affected.",
    },
    {
      id: 1,
      category: "date-of-birth",
      className: "date-of-birth",
      title: "Date of birth",
      field: <FormStep1 control={control} firstName={firstName} />,
      footerNote: undefined,
      subFooterIcon: null,
      subFooterNote: undefined,
    },
    {
      id: 2,
      category: "address",
      className: "address",
      title: "Your address",
      field: <FormStep2 control={control} />,
      footerNote: undefined,
      subFooterIcon: null,
      subFooterNote: undefined,
    },
    {
      id: 3,
      category: "employment-status",
      className: "employment-status",
      title: "Employment status",
      field: (
        <FormStep3 control={control} employmentStatus={employmentStatus} />
      ),
      footerNote: undefined,
      subFooterIcon: null,
      subFooterNote: undefined,
    },
    {
      id: 4,
      category: "personal-finances",
      className: "personal-finances",
      title: "Personal finances",
      field: <FormStep4 control={control} />,
      footerNote: undefined,
      subFooterIcon: null,
      subFooterNote: undefined,
    },
    {
      id: 5,
      category: "contact-details",
      className: "contact-details",
      title: "Contact details",
      field: <FormStep5 control={control} emailInUse={emailInUse} />,
      footerNote: undefined,
      subFooterIcon: null,
      subFooterNote: undefined,
    },
    {
      id: 6,
      category: "contact-details",
      className: "contact-details-confirmation",
      title: "Contact details",
      field: <FormStep6 control={control} />,
      footerNote: undefined,
      subFooterIcon: null,
      subFooterNote: undefined,
    },
    {
      id: 7,
      category: "bank-verification",
      className: "terms",
      title: "Our primary goal is to ensure your safety and security.",
      field: <FormStep7 control={control} />,
      footerNote: undefined,
      subFooterIcon: null,
      subFooterNote: undefined,
    },
    {
      id: 8,
      category: "bank-verification",
      className: "bank-verification",
      title: "Bank Verification",
      field: <FormStep8 />,
      footerNote:
        "By clicking 'Connect your bank' you certify that you understand you will not receive documents in the mail and have read and agree to the Paperless Agreement, Credit Report Policy, Terms of Service, Privacy Policy, and State-Specific Disclosures.",
      subFooterIcon: <IcnCertified />,
      subFooterNote: "Using 256-bit encryption",
    },
  ]

  const groupedSteps: GroupedStepProps[] = []
  steps.forEach((step) => {
    const existingGroup = groupedSteps.find(
      (group) => group.category === step.category,
    )
    if (existingGroup) {
      existingGroup.steps.push(step)
    } else {
      groupedSteps.push({
        category: step.category,
        title: step.title,
        className: step.className,
        steps: [step],
      })
    }
  })

  const currentStep = steps[activeStep] || {}
  const currentStepTitle = currentStep.title || ""
  const currentStepClassName = currentStep.className || ""
  const currentStepCategory = currentStep.category || ""

  const stepProgress = groupedSteps.map((group, idx) => {
    const currentStepIndex = group.steps.findIndex(
      (step) => step.category === currentStepCategory,
    )
    const completed = idx < activeStep && activeStep !== -1
    return {
      completed,
      current: currentStepIndex !== -1,
    }
  })

  const currentStepIndex = stepProgress.findIndex((step) => step.current)
  const stepsLeft = groupedSteps.length - currentStepIndex - 1

  return (
    <PreApprovalLayout
      pageExtraClassName={clsx(
        styles.cPreApprovalForm,
        "c-page-pre-approval-form",
      )}
      navbarRemoveBadge={activeStep === 0}
      navbarUserFirstName={activeStep > 0 ? firstName : ""}
      navbarBadgeType="warning"
      navbarBadgeText="In progress"
    >
      <FormProvider {...methods}>
        <form>
          <section
            className={clsx(
              "c-pre-approval-section-form",
              currentStepClassName,
            )}
          >
            <div className="c-pre-approval-section-form-wrapper">
              {activeStep === 0 || activeStep === 7 ? (
                <></>
              ) : (
                <div className="c-pre-approval-section-form-header">
                  <div
                    className={clsx(
                      "c-pre-approval-section-form-stepper",
                      "boxed",
                    )}
                  >
                    <div className="c-pre-approval-section-form-stepper-subheader">
                      <span>
                        {activeStep === 8 ? (
                          t("Final Step")
                        ) : (
                          <>
                            {stepsLeft}{" "}
                            {stepsLeft > 1 ? t("steps left") : t("step left")}
                          </>
                        )}
                      </span>
                    </div>

                    <h1 className="h3">{t(currentStepTitle)}</h1>

                    <ul className="c-pre-approval-section-form-stepper-progress-list">
                      {stepProgress.map(({ current, completed }, idx) => (
                        <li
                          key={idx}
                          className={clsx(
                            "c-pre-approval-section-form-stepper-progress-list-item",
                            current ? "current" : completed ? "completed" : "",
                          )}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              <div className="c-pre-approval-section-form-body">
                <div className="c-pre-approval-section-form-body-content">
                  {activeStep === 0 || activeStep === 7 ? (
                    <div className="c-pre-approval-section-form-body-content-header">
                      <h1 className="h3">{t(currentStepTitle)}</h1>
                    </div>
                  ) : (
                    <></>
                  )}

                  {steps[activeStep].field}
                </div>

                <div className="c-pre-approval-section-form-body-footer">
                  <div className="c-pre-approval-section-form-body-footer-wrapper">
                    <div className="c-button-container">
                      {activeStep !== 6 && activeStep > 0 && activeStep < 7 && (
                        <Button
                          onClick={handleBack}
                          text="Previous step"
                          icon={!isMobileBreakpoint && <IcnChevronLeft />}
                          iconSize="sm"
                        />
                      )}

                      {activeStep === 8 && token ? (
                        <PlaidLink
                          email={email}
                          public_token={token}
                          user_token={userToken}
                          firstName={firstName}
                          lastName={lastName}
                          birthDate={moment(birthDate).format("YYYY-MM-DD")}
                          languagePreference={language.toLocaleLowerCase()}
                        />
                      ) : (
                        <Button
                          ref={buttonRef}
                          extraClassName="c-pre-approval-section-form-btn-next"
                          btnColor="blue"
                          text={
                            activeStep === 7
                              ? "I accept, continue to final step"
                              : "Next Step"
                          }
                          onClick={handleSubmit((data) => {
                            if (activeStep === 7) {
                              handleSubmitForm(data)
                            } else if (activeStep === 6) {
                              handleVerifySMS(data)
                            } else if (activeStep === 5) {
                              handleFormSubmission(data)
                            } else {
                              handleNext()
                            }
                          })}
                          disabled={!isValid}
                        />
                      )}
                    </div>

                    {steps[activeStep].footerNote && (
                      <small className="c-pre-approval-section-form-body-footer-note">
                        {t(steps[activeStep].footerNote as string)}
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {steps[activeStep].subFooterNote && (
                <div className="c-pre-approval-section-form-sub-footer">
                  <small>
                    <i>{steps[activeStep].subFooterIcon}</i>
                    <span>{t(steps[activeStep].subFooterNote as string)}</span>
                  </small>
                </div>
              )}
            </div>
          </section>
        </form>
      </FormProvider>
    </PreApprovalLayout>
  )
}

export default PreApproval
