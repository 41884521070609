import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { useForm, Controller } from "react-hook-form"
import { useDropzone } from "react-dropzone"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMediaQuery } from "usehooks-ts"
import {
  getLeaseDocuments,
  addLeaseDocuments,
} from "../../services/api/documentsService"
import { bytesToMB, responsiveBreakpoints } from "../../constant/constants"
import CustomSectionBoxed from "../common/CustomSectionBoxed"
import Button from "../common/Button"
import DropzoneText from "../common/DropzoneText"
import { ReactComponent as IcnDelete } from "../../assets/icons/icn-close.svg"
import { ReactComponent as IcnFile } from "../../assets/icons/icn-file.svg"
import { ReactComponent as IcnCheck } from "../../assets/icons/icn-check.svg"
import CustomAlert from "../common/CustomAlert"
import { useAppContext } from "../../contexts/AppContext"
import { FileItemProps } from "../../types/document"

const schema = yup.object().shape({
  files: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      size: yup.number().required(),
      type: yup.string().required(),
    }),
  ),
})

interface FormValues {
  files?: {
    name: string
    size: number
    type: string
  }[]
}

function LoanAdditionalDocumentsForm() {
  const { t } = useTranslation()
  const { activeLoan } = useAppContext()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
    reset,
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  const [files, setFiles] = useState<(File & { preview: string })[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<FileItemProps[]>([])
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { getRootProps, getInputProps, fileRejections, isFocused } =
    useDropzone({
      accept: {
        "image/*": [".jpeg", ".png", ".jpg", ".webp"],
        "application/pdf": [".pdf"],
      },
      maxSize: 4194304, // 4MB in bytes
      onDrop: (acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        )
        setFiles([...files, ...newFiles])
        setValue(
          "files",
          newFiles.map((file) => ({
            name: file.name,
            size: file.size,
            type: file.type,
          })),
        )
      },
    })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      <span>
        {file.name} - {bytesToMB(file.size)}&nbsp;{t("Mb")}
      </span>
      <ul>
        {errors.map((e) => (
          <li key={e.code}>
            <small>{t(e.message)}</small>
          </li>
        ))}
      </ul>
    </li>
  ))

  useEffect(() => {
    const fetchExistingDocuments = async () => {
      try {
        const data = await getLeaseDocuments(activeLoan.message)
        const documents = data.message
        setUploadedFiles(documents)
      } catch (e: any) {
        console.log(e)
      }
    }
    fetchExistingDocuments()
  }, [])

  /*useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview))
      uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview))
    }
  }, [files, uploadedFiles])**/

  const onSubmit = (data: FormValues) => {
    const onSubmitDocument = async () => {
      try {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append("documents_data", file)
        })

        for (let pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1])
        }

        await addLeaseDocuments(activeLoan.message, formData)
        console.log("Files successfully uploaded")
        setFiles([])
        setIsSubmitted(true)
        reset()
        navigate(-1)
      } catch (error) {
        console.error("Error uploading files:", error)
      }
    }
    onSubmitDocument()
  }

  const onReset = () => {
    reset()
    setFiles([])
    setIsSubmitted(false)
    navigate(-1)
  }

  const deleteFile = (fileName: string) => {
    setFiles((prev) => prev.filter((file) => file.name !== fileName))
    setValue(
      "files",
      files
        .filter((file) => file.name !== fileName)
        .map((file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
        })),
    )
  }

  return (
    <CustomSectionBoxed
      classname="c-loan-additional-documents-section-form"
      headerContentType="title"
      headerTitle="Additional documents"
      headerTitleParagraph="Below are the guidelines for submitting any additional documents"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="c-loan-additional-documents-form"
      >
        <Controller
          name="files"
          control={control}
          render={({ field }) => (
            <div
              {...getRootProps({ className: "dropzone" })}
              className={clsx(
                "c-form-group-file-dropzone",
                "c-with-dashed-border",
                isFocused && "is-focused",
                isSubmitted && "is-disabled",
              )}
            >
              <input {...getInputProps()} disabled={isSubmitted} />
              <DropzoneText />
            </div>
          )}
        />

        {fileRejections.length > 0 && (
          <CustomAlert
            extraClassName="c-form-group-file-dropzone-alert rejected-files"
            alertType="danger"
            alertContent={
              <>
                <p>{t("The following files cannot be uploaded:")}</p>
                <ul>{fileRejectionItems}</ul>
              </>
            }
          />
        )}

        {files.length > 0 && (
          <aside className="c-form-group-file-dropzone-list">
            <p className="legend">{t("Selected files")}</p>
            <ul>
              {files.map((file) => (
                <li key={file.name}>
                  <i>
                    <IcnFile />
                  </i>

                  {/*<img*/}
                  {/*  src={file.preview}*/}
                  {/*  alt={file.name}*/}
                  {/*  width={40}*/}
                  {/*  height={40}*/}
                  {/*  onLoad={() => URL.revokeObjectURL(file.preview)}*/}
                  {/*/>*/}

                  <div>
                    <p>{file.name}</p>

                    <small>
                      {bytesToMB(file.size)}&nbsp;{t("Mb")}
                    </small>
                  </div>

                  <Button
                    btnSize={isMobileBreakpoint ? "xs" : "md"}
                    btnVariant={isMobileBreakpoint ? "icon" : undefined}
                    btnColor="white-outline"
                    text={isMobileBreakpoint ? undefined : "Delete"}
                    title="Delete"
                    icon={isMobileBreakpoint && <IcnDelete />}
                    iconSize="xs"
                    onClick={() => deleteFile(file.name)}
                  />
                </li>
              ))}
            </ul>
          </aside>
        )}

        <aside className="c-form-group-file-dropzone-list">
          <p className="legend">{t("Uploaded Files")}</p>

          {uploadedFiles.length === 0 ? (
            <p className="default-empty">
              {t("No files have been uploaded yet")}
            </p>
          ) : (
            <ul>
              <>
                {uploadedFiles.map((file) => (
                  <li key={file.file_name}>
                    <i>
                      <IcnFile />
                    </i>

                    <div>
                      <p>{file.file_name}</p>

                      {file.file_size && (
                        <small>
                          {bytesToMB(file.file_size)}&nbsp;{t("Mb")}
                        </small>
                      )}
                    </div>

                    <i className="icon-submit-success">
                      <IcnCheck />
                    </i>
                  </li>
                ))}
              </>
            </ul>
          )}
        </aside>

        <div className="c-button-container">
          <Button text="Cancel" onClick={onReset} />

          <Button
            type="submit"
            text="Save & close"
            btnColor="blue"
            disabled={!isValid || files.length === 0}
          />
        </div>
      </form>
    </CustomSectionBoxed>
  )
}

export default LoanAdditionalDocumentsForm
