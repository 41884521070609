import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import clsx from "clsx"
import { ColumnDef } from "@tanstack/react-table"
import moment from "moment/moment"
import { NumericFormat } from "react-number-format"
import { useMediaQuery } from "usehooks-ts"
import { useAppContext } from "../contexts/AppContext"
import { responsiveBreakpoints } from "../constant/constants"
import CustomTable from "./common/CustomTable"
import Button from "./common/Button"
import { ReactComponent as IcnChevronRight } from "../assets/icons/icn-chevron-right.svg"
import { loanDetails, DashboardLoansTableProps } from "../types/dashboard"

// TODO: DEFINE TYPES FOR SETACTIVELOAN & NAVIGATE
const generateColumns = (
  t: (key: string) => string,
  isMobileBreakpoint: boolean,
  setActiveLoan: any,
  navigate: any,
): ColumnDef<loanDetails>[] => {
  return [
    {
      id: "vehicle",
      header: () => <>{t("Vehicle")}</>,
      cell: ({ row }) => {
        return (
          <>
            {row.original.vehicle}
            <small>#{row.original.id}</small>
          </>
        )
      },
    },
    {
      id: "submitted",
      header: () => <>{t("Submitted on")}</>,
      cell: ({ row }) => {
        return (
          <>
            {row.original.submitted_on === "N/A" ? (
              <>N/A</>
            ) : (
              <time>
                {moment(row.original.submitted_on).format("MMM D, YYYY")}
              </time>
            )}
          </>
        )
      },
    },
    {
      id: "loanAmount",
      header: () => <>{t("Loan amount")}</>,
      cell: ({ row }) => {
        return (
          <NumericFormat
            displayType="text"
            value={row.original.lease_amount || 0}
            prefix="$"
            decimalScale={2}
            thousandSeparator=","
            decimalSeparator="."
            fixedDecimalScale
            valueIsNumericString
          />
        )
      },
    },
    {
      id: "action",
      header: () => <span className="is-hidden">{t("Action")}</span>,
      cell: ({ row }) => {
        const onLoanClick = () => {
          setActiveLoan({ message: row.original.id })
          navigate("/loan/pending")
        }
        return (
          <Button
            onClick={onLoanClick}
            btnVariant="link"
            btnColor={isMobileBreakpoint ? "ghost" : undefined}
            text={isMobileBreakpoint ? row.original.vehicle : "Manage loan"}
            icon={<IcnChevronRight />}
            iconPlacement="right"
            iconSize="sm"
          />
        )
      },
    },
  ]
}

function DashboardLoansPendingTable({ loans }: DashboardLoansTableProps) {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const { setActiveLoan } = useAppContext()
  const navigate = useNavigate()
  const columns = generateColumns(
    t,
    isMobileBreakpoint,
    setActiveLoan,
    navigate,
  )

  return (
    <CustomTable
      extraClassName={clsx(
        "is-responsive",
        "c-dashboard-section-table",
        "pending",
      )}
      data={loans}
      columns={columns}
      customDefaultEmpty
      defaultEmptyHeading="No pending loans"
      defaultEmptyTitle="All pending loans will appear here"
    />
  )
}

export default DashboardLoansPendingTable
