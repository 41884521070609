import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import Select, { components } from "react-select"
import { Controller } from "react-hook-form"
import { ReactComponent as IcnChevronDown } from "../../assets/icons/icn-chevron-down.svg"

interface OptionProps {
  label: string;
  value: any
}
// Props from the library react-select can no longer be extended classically via the interface
// because of its native onChange overriding the controller's onChange.
// You can manually add extra props to the interface such as "isSearchable","isDisabled", etc. according to the needs

interface CustomSelectProps {
  extraClassName?: string;
  labelText: string;
  isLabelHidden?: boolean;
  name: string;
  placeholder?: string;
  options: OptionProps[];
  defaultValue?: any;
  control: any;
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  helpText?: ReactNode;
}

function CustomSelect(
  {
    extraClassName,
    labelText,
    isLabelHidden,
    name,
    placeholder,
    options,
    defaultValue,
    helpText,
    control,
    isSearchable,
    isClearable,
    isDisabled,
    ...rest
  }: CustomSelectProps) {
  const { t } = useTranslation()

  const Control = ({ children, hasValue, ...props }: any) => (
    <div className="c-form-group-input-wrapper">
      <components.Control
        className={clsx(
          hasValue && "has-value",
          isLabelHidden && "has-hidden-label",
        )}
        {...props}
      >
        {children}
      </components.Control>

      <label
        htmlFor={`id_${name}`}
        className={clsx(
          isLabelHidden && "c-visually-hidden",
          hasValue && "is-floating",
        )}
      >
        {t(labelText)}
      </label>
    </div>
  )

  const DropdownIndicator = ({ ...props }: any) => (
    <components.DropdownIndicator {...props}>
      <IcnChevronDown />
    </components.DropdownIndicator>
  )

  const NoOptionsMessage = ({ ...props }: any) => (
    <components.NoOptionsMessage {...props}>
      {t("No results found")}
    </components.NoOptionsMessage>
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue !== undefined ? defaultValue : ""}
      render={({ field: {value, onChange}, fieldState: { error } }) => (
        <div className={clsx("c-form-group", extraClassName && extraClassName)}>
          <Select
            id={`id_${name}`}
            className={clsx("c-custom-select", error && "is-invalid")}
            classNamePrefix="c-custom-select"
            options={options}
            placeholder={placeholder ? t(placeholder) : ""}
            inputId={`id_search_${name}`}
            value={options.find(option => option.value === value) || null}
            onChange={(selectedOption: OptionProps | null) => {
              onChange(selectedOption ? selectedOption.value : null);
            }}
            aria-invalid={!!error}
            menuPlacement="auto"
            components={{
              Control,
              DropdownIndicator,
              NoOptionsMessage,
            }}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isDisabled={isDisabled}
            {...rest}
          />

          {helpText && <div className="c-form-help-text">{helpText}</div>}

          {error && (
            <div className="c-form-error-feedback">
              {t(error.message || "")}
            </div>
          )}
        </div>
      )}
    />
  )
}

export default CustomSelect
