import { useState } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import Tippy from "@tippyjs/react"
import { Link } from "react-router-dom"
import { useAppContext } from "../../contexts/AppContext"
import { CustomButtonProps } from "../../types/types"
import LogoutButton from "./LogoutButton"
import LanguageSwitcher from "./LanguageSwitcher"
import { ReactComponent as IcnAccount } from "../../assets/icons/icn-user.svg"
import { ReactComponent as IcnChevronDown } from "../../assets/icons/icn-chevron-down.svg"
import styles from "../../styles/UserDropdown.module.scss"

interface UserDropdownMenuProps extends CustomButtonProps {}

function DashboardUserDropdownMenu(
  {
    btnColor,
  }: UserDropdownMenuProps) {
  const { t } = useTranslation()
  const { fullName } = useAppContext()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={clsx(
        styles.cUserDropdown,
        "c-dropdown",
        "c-user-dropdown",
      )}
    >
      <Tippy
        className="c-user-dropdown-menu"
        interactive
        animation="shift-toward"
        placement="bottom-end"
        trigger="click"
        maxWidth="198px"
        onShow={() => setIsOpen(true)}
        onHide={() => setIsOpen(false)}
        arrow={false}
        content={
          <>
            <ul className="c-user-dropdown-nav-list">
              <li>
                <Link to="/account-settings">
                  <i>
                    <IcnAccount />
                  </i>
                  <span>{t("Account Settings")}</span>
                </Link>
              </li>

              <li>
                <LanguageSwitcher />
              </li>

              <li className="logout">
                <LogoutButton />
              </li>
            </ul>
          </>
        }
      >
        <button
          className={clsx(
            "c-button",
            "c-user-dropdown-toggle",
            isOpen && "is-open",
          )}
          data-icon-size="sm"
          data-size="md"
          data-color={btnColor? btnColor : "ghost"}
        >
          <span>{fullName}</span>
          <i>
            <IcnChevronDown />
          </i>
        </button>
      </Tippy>
    </div>
  )
}

export default DashboardUserDropdownMenu
