import React, {ReactNode, TextareaHTMLAttributes} from "react"
import {useTranslation} from "react-i18next"
import { Controller } from 'react-hook-form'
import clsx from "clsx"
interface CustomTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  extraClassName?: string;
  labelText: string;
  isLabelHidden?: boolean;
  name: string;
  defaultValue?: any;
  placeholder?: string;
  control: any;
  helpText?: ReactNode,
}
function CustomTextArea(
  {
    extraClassName,
    labelText,
    isLabelHidden,
    name,
    defaultValue,
    placeholder,
    helpText,
    control,
    ...rest
  }:CustomTextAreaProps) {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (

        <div className={clsx("c-form-group", extraClassName && extraClassName)}>

          <div className="c-form-group-input-wrapper">

            <textarea
              id={`id_${name}`}
              placeholder={placeholder ? t(placeholder): " "}
              aria-invalid={!!error}
              rows={7}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                }
              }}
              {...field}
              {...rest}
            ></textarea>

            <label htmlFor={`id_${name}`} className={clsx(isLabelHidden && 'c-visually-hidden')}>
              {t(labelText)}
            </label>

          </div>

          {error && (
            <div className="c-form-error-feedback">
              {t(error.message || "")}
            </div>
          )}

          {helpText && (
            <div className="c-form-help-text">
              {helpText}
            </div>
          )}

        </div>
      )}
    />
  )
}

export default CustomTextArea