import {useCallback, useMemo, useState} from "react"
import {Trans, useTranslation} from "react-i18next"
import clsx from "clsx"
import {NumericFormat} from "react-number-format"
import {DummyLoanListProps} from "../../mock-data/dummyTypes"
import CustomModal from "../common/CustomModal"
import Button from "../common/Button"
import ButtonLink from "../common/ButtonLink"
import {ReactComponent as IcnArrowUp} from "../../assets/icons/icn-arrow-up.svg"
import {ReactComponent as IcnChevronRight} from "../../assets/icons/icn-chevron-right.svg"
import styles from "../../styles/LoanApprovedModal.module.scss"

interface LoanApprovedVehicleDetailsModalProps {
  data?: DummyLoanListProps;
}

function LoanApprovedVehicleDetailsModal({data}: LoanApprovedVehicleDetailsModalProps) {
  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleOpenModal = useCallback( () => {
    setModalIsOpen(modalIsOpen => !modalIsOpen)
  }, [])
  const handleCloseModal = useCallback( () => {
    setModalIsOpen(false)
  }, [])

  const LoanVehicleDetailsList = useMemo(
    () => [
      {
        name: t("Make"),
        value: "Audi",
        isNumericValue: false,
        prefix: null,
        suffix: null
      },
      {
        name: t("Model"),
        value: "Q8 E-tron",
        isNumericValue: false,
        prefix: null,
        suffix: null
      },
      {
        name: t("Year"),
        value: "2023",
        isNumericValue: false,
        prefix: null,
        suffix: null
      },
      {
        name: t("Mileage"),
        value: 24599,
        isNumericValue: true,
        prefix: null,
        suffix: " KM"
      },
      {
        name: t("VIN"),
        value: "1FTCR10A2PPB68256",
        isNumericValue: false,
        prefix: null,
        suffix: null
      },
    ],
    [data, t]
  )

  return (
    <>
      <Button
        extraClassName="c-loan-approved-modal-btn"
        btnVariant="link"
        btnColor="ghost"
        title="View loan vehicle details"
        iconSize="sm"
        onClick={handleOpenModal}
      >
        <span className="c-text-px18">
          {t("Vehicle details")}
        </span>

        <span className="c-text-px14">
          {t("View")}
          <i><IcnChevronRight/></i>
        </span>
      </Button>

      <CustomModal
        extraClassName={clsx(styles.cLoanApprovedModal, "c-loan-approved-modal", "vehicle-details")}
        isModalOpen={modalIsOpen}
        onCloseModal={handleCloseModal}
        modalSize="xs"
        addModalHeader
        modalHeaderTitle="Vehicle Details"
        modalHeaderParagraphContent={
          <Trans>
            Below is confirmed details of your vehicle, if there is something incorrect please <ButtonLink url="/support" btnVariant="link" btnColor="blue">contact us</ButtonLink>.
          </Trans>
        }
      >

        <ul className="c-loan-approved-modal-list">
          {LoanVehicleDetailsList.map((item, idx) => (
            <li key={idx}>

              <em>{t(item.name)}</em>

              {item.isNumericValue? (
                <NumericFormat
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                  prefix={item.prefix? item.prefix: ""}
                  suffix={item.suffix? item.suffix: ""}
                  value={item.value}
                />
              ):(
                <span>
                  {item.prefix && item.prefix}
                  {item.value}
                  {item.suffix && item.suffix}
                </span>
              )}
            </li>
          ))}

          <li>
            <em>{t("Vehicle Insurance Policy")}</em>
            <span>
              <ButtonLink
                url="#"
                btnVariant="link"
                text="View"
                icon={<IcnArrowUp/>}
                iconSize="sm"
                iconPlacement="right"
              />
            </span>
          </li>

          <li>
            <em>{t("Vehicle Registrations")}</em>
            <span>
              <ButtonLink
                url="#"
                btnVariant="link"
                text="View"
                icon={<IcnArrowUp/>}
                iconSize="sm"
                iconPlacement="right"
              />
            </span>
          </li>

        </ul>

        <div className="c-button-container">
          <Button
            text="Close"
            onClick={handleCloseModal}
          />
        </div>

      </CustomModal>
    </>
  )
}

export default LoanApprovedVehicleDetailsModal