// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardMobileMenu_cDashboardMobileMenu__rh80Y {
  display: block;
}
.DashboardMobileMenu_cDashboardMobileMenu__rh80Y.c-dashboard-mobile-menu {
  height: 100%;
}
.DashboardMobileMenu_cDashboardMobileMenu__rh80Y.c-dashboard-mobile-menu .c-custom-mobile-menu-toggle {
  height: 100%;
}
.DashboardMobileMenu_cDashboardMobileMenu__rh80Y.c-dashboard-mobile-menu .c-custom-mobile-menu-content {
  background-color: var(--light-grey);
}
.DashboardMobileMenu_cDashboardMobileMenu__rh80Y.c-dashboard-mobile-menu .c-custom-mobile-menu-content-wrapper {
  padding-bottom: var(--dashboard-mobile-sidebar-height);
}`, "",{"version":3,"sources":["webpack://./src/styles/DashboardMobileMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAEI;EACE,YAAA;AAAN;AAEM;EACE,YAAA;AAAR;AAGM;EACE,mCAAA;AADR;AAIM;EACE,sDAAA;AAFR","sourcesContent":[".cDashboardMobileMenu {\n  display: block;\n\n  &:global {\n    &.c-dashboard-mobile-menu {\n      height: 100%;\n\n      .c-custom-mobile-menu-toggle {\n        height: 100%;\n      }\n\n      .c-custom-mobile-menu-content {\n        background-color: var(--light-grey);\n      }\n\n      .c-custom-mobile-menu-content-wrapper {\n        padding-bottom: var(--dashboard-mobile-sidebar-height);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cDashboardMobileMenu": `DashboardMobileMenu_cDashboardMobileMenu__rh80Y`
};
export default ___CSS_LOADER_EXPORT___;
