import { Trans, useTranslation } from "react-i18next"
import { ReactComponent as IcnCloudUpload } from "../../assets/icons/icn-cloud-upload.svg"
function DropzoneText() {
  const { t } = useTranslation()

  return (
    <div className="c-form-group-file-dropzone-text">
      <i>
        <IcnCloudUpload />
      </i>

      <span>
        <span>
          <Trans>
            <u>Click to upload</u> or drag & drop
          </Trans>
        </span>

        <small>{t("JPG, PNG, JPEG, WEBP, PDF only, up to 4MB x file")}</small>
      </span>
    </div>
  )
}

export default DropzoneText
