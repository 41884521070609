import { Link, NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { PublicPageNavItems } from "../../constant/constants"
import CustomBadge from "./CustomBadge"
import NavItemsPublicSocial from "./NavItemsPublicSocial"
import { ReactComponent as Logo } from "../../assets/images/leasify-logo-boxed.svg"
import {ReactComponent as LogoTrustpilot} from "../../assets/images/logo-trustpilot.svg"
import {ReactComponent as LogoAppStoreEn} from "../../assets/images/logo-app-store-en.svg"
import {ReactComponent as LogoPlayStoreEn} from "../../assets/images/logo-google-play-en.svg"
import styles from "../../styles/Subfooter.module.scss"

function SubFooter() {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.cSubfooter, "c-subfooter")}>

      <div className="c-subfooter-nav">

        <nav className="c-subfooter-nav-item logo-social" aria-label={t("Social media")}>
          <Link
            className="c-logo"
            to="/"
            title={t("Go to main page")}
          >
            <Logo/>

          </Link>

          <NavItemsPublicSocial/>

        </nav>

        <div className="c-subfooter-nav-item links">

          <div className="c-subfooter-nav-list">
            {PublicPageNavItems.filter(category => category.isSubFooterList).map((nav, navSubfooterIdx) => {
              return (
                <nav
                  className="c-subfooter-nav-list-item"
                  key={navSubfooterIdx}
                  aria-label={t(nav.category)}
                >

                  <p className="h5">
                    {t(nav.category)}
                  </p>

                  <ul>

                    {nav.navItems.map((link, linkSubfooterIdx) => (
                      <li key={linkSubfooterIdx}>
                        <NavLink
                          to={link.url}
                          className="c-button"
                          data-variant="link"
                          data-color="ghost"
                          title={t(link.title)}
                          aria-disabled={link.url === "#"}
                        >
                          {t(link.title)}
                        </NavLink>

                        {link.isComingSoon && (
                          <CustomBadge badgeType="default" badgeText="Coming soon"/>
                        )}
                      </li>
                    ))}

                  </ul>

                </nav>
              )
            })}

          </div>

        </div>

        <div className="c-subfooter-nav-item apps">

          <div className="apps-logo trustpilot">
            <LogoTrustpilot/>
          </div>

          <div className="c-button-container">
            <LogoAppStoreEn/>
            <LogoPlayStoreEn/>
          </div>

        </div>

      </div>

    </div>
  )
}

export default SubFooter