import React, {InputHTMLAttributes, ReactNode} from "react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form"

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  extraClassName?: string
  labelText: string
  isLabelHidden?: boolean
  type?: string
  name: string
  defaultValue?: any
  placeholder?: string
  control: any
  helpText?: ReactNode
}

function CustomInput({
  extraClassName,
  labelText,
  isLabelHidden,
  type,
  name,
  defaultValue,
  placeholder,
  helpText,
  control,
  ...rest
}: CustomInputProps) {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <div className={clsx("c-form-group", extraClassName && extraClassName)}>
          <div className="c-form-group-input-wrapper">
            <input
              type={type || "text"}
              id={`id_${name}`}
              placeholder={placeholder ? t(placeholder) : " "}
              aria-invalid={!!error}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                }
              }}
              {...field}
              {...rest}
            />

            <label
              htmlFor={`id_${name}`}
              className={clsx(isLabelHidden && "c-visually-hidden")}
            >
              {t(labelText)}
            </label>
          </div>

          {error && (
            <div className="c-form-error-feedback">
              {t(error.message || "")}
            </div>
          )}

          {helpText && <div className="c-form-help-text">{helpText}</div>}
        </div>
      )}
    />
  )
}

export default CustomInput
