import {
  PreApprovalAPI,
  SmsAuthNmbr,
  PhoneNumberData,
  IPreApprovalStatusResponse,
} from "../../types/preApproval"
import { fetch } from "./fetchWrapper"

interface CreateLinkTokenResponse {
  status: number
  message: {
    link_token: string
    user_token: string
  }
}

interface linkSuccessItem {
  email: string
  public_token: string
  user_token: string
  application_status: string
}

interface ActiveLoanType {
  status: number
  message: string
}

export const createPreApproval = async (
  data: PreApprovalAPI,
): Promise<PreApprovalAPI> => {
  const response = await fetch<PreApprovalAPI, PreApprovalAPI>({
    endpoint: "/pre-approval",
    method: "post",
    payload: data,
    headers: {
      "Content-Type": "application/json",
    },
  })

  return response.data
}

export const submitPhoneNumber = async (
  data: PhoneNumberData,
): Promise<PhoneNumberData> => {
  const response = await fetch<PhoneNumberData, PhoneNumberData>({
    endpoint: "/send-sms",
    method: "post",
    payload: data,
  })

  return response.data
}

export const submitSmsAuth = async (
  data: SmsAuthNmbr,
): Promise<SmsAuthNmbr> => {
  const response = await fetch<SmsAuthNmbr, SmsAuthNmbr>({
    endpoint: "/verify-sms",
    method: "post",
    payload: data,
  })

  return response.data
}

export const createLinkToken = async (
  data: PreApprovalAPI,
): Promise<CreateLinkTokenResponse> => {
  const response = await fetch<PreApprovalAPI, CreateLinkTokenResponse>({
    endpoint: "/create-link-token",
    method: "post",
    payload: data,
  })

  return response.data
}

export const submitOnLinkSuccess = async (
  data: linkSuccessItem,
): Promise<string> => {
  const response = await fetch<linkSuccessItem, string>({
    endpoint: "/register-bank-info",
    method: "post",
    payload: data,
  })

  return response.data
}

export const getPreApprovalId = async (
  email: string,
): Promise<ActiveLoanType> => {
  const response = await fetch<null, ActiveLoanType>({
    endpoint: "/get-pre-approval-id",
    method: "get",
    options: {
      params: { email },
    },
  })

  return response.data
}

export const getPreApprovalStatus = async (
  id: string,
): Promise<IPreApprovalStatusResponse> => {
  const response = await fetch<null, IPreApprovalStatusResponse>({
    endpoint: "/get-pre-approval-status",
    method: "get",
    options: {
      params: { application_id: id },
    },
  })

  return response.data
}
