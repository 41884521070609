import { Trans, useTranslation } from "react-i18next"
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts"
import { HomeSectionDealershipsGridItems, responsiveBreakpoints } from "../constant/constants"
import ButtonLink from "./common/ButtonLink"
import styles from "../styles/HomeSectionDealerships.module.scss"

function HomeSectionDealerships() {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  return (
    <section className={clsx(styles.cHomeSectionDealerships, "c-home-section-dealerships")}>

      <div className="c-home-section-dealerships-wrapper">

        <div className="c-home-section-dealerships-wrapper-inner">

          <div className="c-home-section-dealerships-content">

            <div className="c-home-section-dealerships-header">

              <div className="c-home-section-dealerships-header-grid">

                <div className="c-home-section-dealerships-header-grid-item text">

                  <h2 className="c-font-staatliches">
                    {t("Designed for dealerships too")}
                  </h2>

                  <p className="c-text-px18-responsive">
                    {t("Dealerships make sales hassle free knowing Leasyfi will manage all the finance documents and work required to make a sale go through.")}
                  </p>

                </div>

                {!isMobileBreakpoint && (
                  <div className="c-home-section-dealerships-header-grid-item cta">
                    <ButtonLink url="#" btnColor="blue" text="Learn more"/>
                  </div>
                )}

              </div>

            </div>

            <div className="c-home-section-dealerships-body">

              <div className="c-home-section-dealerships-grid">

                {HomeSectionDealershipsGridItems.map((item, idx) => (
                  <article key={idx} className="c-home-section-dealerships-grid-item">

                    {idx === 1 && (
                      <div className="c-home-section-dealerships-grid-item-title">
                        <p>
                          <Trans>
                            Make sales <em>hassle free</em> knowing Leasyfi will <em>manage</em> all the finances
                          </Trans>
                        </p>
                      </div>
                    )}

                    <img
                      src={isMobileBreakpoint && item.mobileImage? item.mobileImage : item.image}
                      alt={t(item.imageAlt)}
                    />

                    {isMobileBreakpoint && idx === 3 ? (
                      <div className="c-button-container">
                        <ButtonLink url="#" btnColor="blue" text="Learn more"/>
                      </div>
                    ): ""}
                  </article>
                ))}

              </div>

            </div>

          </div>

        </div>

      </div>

    </section>
  )
}

export default HomeSectionDealerships