import {Trans, useTranslation} from "react-i18next"
import clsx from "clsx"
import {HomeSectionGetApprovedList} from "../constant/constants"
import CustomBadge from "./common/CustomBadge"
import ButtonLink from "./common/ButtonLink"
import styles from "../styles/HomeSectionGetApproved.module.scss"
function HomeSectionGetApproved() {
  const { t } = useTranslation()

  return (
    <section className={clsx(styles.cHomeSectionGetApproved, "c-home-section-get-approved")}>

      <div className="c-home-section-get-approved-wrapper">

        <div className="c-home-section-get-approved-header">

          <h2 className="c-font-staatliches">
            <Trans>
              Get approved in just <em>three</em>&nbsp;<em>easy</em>&nbsp;steps
            </Trans>
          </h2>

        </div>

        <div className="c-home-section-get-approved-body">

          <ul className="c-home-section-get-approved-list">
            {HomeSectionGetApprovedList.map((step, idx) => (
              <li key={idx}>

                <article className="c-home-section-get-approved-list-card">

                  <div className="c-home-section-get-approved-list-card-image">
                    <img src={step.image} alt=""/>
                  </div>

                  <div className="c-home-section-get-approved-list-card-body">

                    <CustomBadge
                      badgeType="success"
                      badgeText={t(`Step ${idx + 1}`)}
                    />

                    <h3 className="h6">
                      {t(step.title)}
                    </h3>

                    <p>
                      {t(step.paragraph)}
                    </p>

                  </div>

                </article>

              </li>
            ))}

          </ul>

          <div className="c-button-container home-section-button-container">

            <ButtonLink
              url="/apply"
              text="Get pre-approved"
              btnColor="blue"
            />

            <small>{t("APR at 8.99%")}</small>

          </div>

        </div>

      </div>

    </section>
  )
}

export default HomeSectionGetApproved