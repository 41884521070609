import { useCallback, useEffect, useRef, useState, ReactNode, forwardRef, useImperativeHandle } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { useMediaQuery, useOnClickOutside } from "usehooks-ts"
import { responsiveBreakpoints } from "../../constant/constants"
import Button from "./Button"
import Copyright from "./Copyright"
import styles from "../../styles/CustomMobileMenu.module.scss"

interface CustomMobileMenuProps {
  extraClassName?: string;
  menuBtnExtraClassName?: string;
  menuBtnText?: string;
  menuBtnTitle?: string;
  menuBtnVariant?: "icon" | "link";
  menuBtnSize?: "md" | "sm" | "xs";
  menuBtnColor?: "blue" | "white-outline" | "ghost" | "ghost-grey";
  menuBtnIcon?: ReactNode;
  menuHeaderTitle?: string;
  menuSubHeader?: ReactNode;
  children?: ReactNode
}

const CustomMobileMenu = forwardRef<HTMLDivElement, CustomMobileMenuProps>((
  {
    extraClassName,
    menuBtnExtraClassName,
    menuBtnText,
    menuBtnTitle,
    menuBtnVariant,
    menuBtnSize,
    menuBtnColor,
    menuBtnIcon,
    menuHeaderTitle,
    menuSubHeader,
    children
  }, ref) => {
  const { t } = useTranslation()
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)

  const internalRef = useRef<HTMLDivElement>(null)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  useImperativeHandle(ref, () => internalRef.current!)

  const handleToggleMobileMenu = useCallback(() => {
    setOpenMobileMenu(prev => !prev)
  }, [])
  const handleCloseMobileMenu = useCallback(() => setOpenMobileMenu(false), [])


  useEffect(() => {
    if (isMobileBreakpoint) {
      if (openMobileMenu) {
        document.body.classList.add("mobile-menu-is-open")
      } else {
        document.body.classList.remove("mobile-menu-is-open")
      }
    } else {
      document.body.classList.remove("mobile-menu-is-open")
      setOpenMobileMenu(false)
    }

    return () => {
      document.body.classList.remove("mobile-menu-is-open")
    }
  }, [openMobileMenu, isMobileBreakpoint])

  useOnClickOutside(internalRef, handleCloseMobileMenu)

  return (

    <div
      className={clsx(styles.cCustomMobileMenu, "c-custom-mobile-menu", extraClassName && extraClassName)}
      ref={internalRef}
    >
      <Button
        extraClassName={clsx("c-custom-mobile-menu-toggle", openMobileMenu && "is-open active", menuBtnExtraClassName ? menuBtnExtraClassName : "default")}
        title={openMobileMenu ? "Close menu" : "Open menu"}
        text={menuBtnText? menuBtnText : undefined}
        btnVariant={menuBtnVariant? menuBtnVariant : "icon"}
        btnSize={menuBtnSize && menuBtnSize}
        btnColor={menuBtnColor ? menuBtnColor : "ghost"}
        icon={menuBtnIcon? menuBtnIcon : <span className="c-custom-mobile-menu-toggle-icon"/>}
        onClick={handleToggleMobileMenu}
      />

      <aside
        className={clsx("c-custom-mobile-menu-content", openMobileMenu && "is-open")}
      >
        <div className="c-custom-mobile-menu-content-wrapper">

          <div className="c-custom-mobile-menu-content-header">
            {menuHeaderTitle && (
              <h1>
                {t(menuHeaderTitle)}
              </h1>
            )}
          </div>

          {menuSubHeader && (
            <div className="c-custom-mobile-menu-content-subheader">
              {menuSubHeader}
            </div>
          )}

          <div className="c-custom-mobile-menu-content-body">

            {children}

            <div className="c-custom-mobile-menu-content-body-footer">
              <Copyright extraClassName="c-custom-mobile-menu-content-footer-copyright" />
            </div>

          </div>

        </div>

      </aside>

    </div>
  )
})

export default CustomMobileMenu
