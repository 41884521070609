import { Trans } from "react-i18next"
import clsx from "clsx"
import PreApprovalLayout from "../layouts/PreApprovalLayout"
import PreApprovalSectionStatusGrid from "../components/PreApprovalSectionStatusGrid"
import { ReactComponent as IcnInvalid } from "../assets/icons/icn-list-invalid.svg"
import { ReactComponent as IcnInfo } from "../assets/icons/icn-circle-info.svg"
import styles from "../styles/PreApprovalStatus.module.scss"
import { IPreApprovalStatusComponent } from "../types/preApproval"

const PreApprovalStatusFailed = (props: IPreApprovalStatusComponent) => {
  return (
    <PreApprovalLayout
      pageExtraClassName={clsx(
        styles.cPreApprovalStatus,
        "c-page-pre-approval-status",
        "failed",
      )}
      pageExtraTitle="Failed"
      navbarUserFirstName={props.firstName}
      navbarBadgeType="danger"
      navbarBadgeText="Failed"
    >
      <PreApprovalSectionStatusGrid
        isSingleColumn
        icon={<IcnInvalid />}
        iconType="danger"
        preApprovalStatusData={props}
        title="Failed Pre-Approval"
        subtitle="Your pre-approval application has failed - this happens sometimes. Your application has been flagged and we have been notified of your situation."
        footNote={
          <Trans>
            Please confirm if we can contact you via the email you provided{" "}
            <em>rebecca192@hotmail.com</em> to guide you through the following
            steps to getting you approved!
          </Trans>
        }
        footCta={{
          url: "/",
          text: "Get Support",
          icon: <IcnInfo />,
        }}
      />
    </PreApprovalLayout>
  )
}

export default PreApprovalStatusFailed
