import React, { ButtonHTMLAttributes, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { CustomButtonProps } from "../../types/types"

interface ButtonProps extends CustomButtonProps, ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "submit" | "reset";
  extraClassName?: string;
  onClick?: () => void;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type,
      extraClassName,
      text,
      title,
      children,
      btnColor,
      btnSize,
      btnVariant,
      icon,
      iconSize,
      iconPlacement,
      onClick,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const { t } = useTranslation()
    return (
      <button
        ref={ref}
        type={type ? type : "button"}
        className={clsx("c-button", extraClassName && extraClassName)}
        title={t(title ?? text ?? "Button Title") as string}
        aria-label={t(title ?? text ?? "Button Title") as string}
        data-color={btnColor && btnColor}
        data-size={btnSize && btnSize}
        data-variant={btnVariant && btnVariant}
        data-icon-placement={iconPlacement && iconPlacement}
        data-icon-size={iconSize && iconSize}
        onClick={onClick}
        {...props}
      >
        {icon && <i>{icon}</i>}
        {text ? <span>{t(text)}</span> : children}
      </button>
    );
  }
);

export default Button;
