import { fetchDocumentsResponse } from "../../types/document"
import { fetch } from "./fetchWrapper"
import { BasicApiResponse } from "../../types/types"

export const getAllDocuments = async (
  cookies?: string[],
): Promise<fetchDocumentsResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, fetchDocumentsResponse>({
    endpoint: "/get-all-documents",
    method: "get",
    headers,
  })

  return response.data
}

export const addUserDocuments = async (
  formData: FormData,
  cookies?: string[],
): Promise<BasicApiResponse> => {
  const headers: Record<string, string> = {
    "Content-Type": "multipart/form-data",
  }
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<FormData, BasicApiResponse>({
    endpoint: "/add-user-documents",
    method: "post",
    payload: formData,
    headers,
  })

  return response.data
}

export const getLeaseDocuments = async (
  lease_id: string,
  cookies?: string[],
): Promise<fetchDocumentsResponse> => {
  const headers: Record<string, string> = {}
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<null, fetchDocumentsResponse>({
    endpoint: "/get-lease-documents",
    method: "get",
    headers,
    options: { params: { lease_id } },
  })

  return response.data
}

export const addLeaseDocuments = async (
  lease_id: string,
  formData: FormData,
  cookies?: string[],
): Promise<BasicApiResponse> => {
  const headers: Record<string, string> = {
    "Content-Type": "multipart/form-data",
  }
  if (cookies && cookies.length > 0) {
    headers.Cookie = cookies.join("; ")
  }

  const response = await fetch<FormData, BasicApiResponse>({
    endpoint: "/add-lease-documents",
    method: "post",
    payload: formData,
    headers,
    options: { params: { lease_id } },
  })

  return response.data
}
