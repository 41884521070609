import {useTranslation} from "react-i18next"
import clsx from "clsx"
import {useMediaQuery} from "usehooks-ts"
import {NavLink} from "react-router-dom";
import {LegalNavItems, responsiveBreakpoints} from "../constant/constants"
import Logo from "./common/Logo"
import DashboardSidebarNavItems from "./DashboardSidebarNavItems"
import Copyright from "./common/Copyright"
import styles from "../styles/DashboardSidebar.module.scss"
function DashboardSidebar() {
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.isMobile)
  const { t } = useTranslation()

  return (
    <>
      {isMobileBreakpoint? (
        <aside className={clsx(styles.cSidebar, "c-sidebar", "c-mobile")}>
          <DashboardSidebarNavItems/>
        </aside>
      ):(
        <aside className={clsx(styles.cSidebar, "c-sidebar", "c-desktop")}>

          <div className="c-sidebar-logo-wrapper">
            <Logo/>
          </div>

          <DashboardSidebarNavItems/>

          <div className="c-sidebar-footer">

            <ul>
              {LegalNavItems.map((link, idx) => (
                <li key={idx}>

                  <NavLink
                    to={link.url}
                    title={t(link.title)}
                  >
                    {t(link.title)}
                  </NavLink>

                </li>
              ))}

              <li>
                <Copyright/>
              </li>
            </ul>

          </div>

        </aside>
      )}
    </>
  )
}

export default DashboardSidebar