// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserStatus_cUserStatus__LQTgk.c-user-status {
  display: inline-flex;
  align-items: center;
  gap: var(--px12);
}
.UserStatus_cUserStatus__LQTgk.c-user-status h1 {
  margin-bottom: 0;
}
.UserStatus_cUserStatus__LQTgk.c-user-status .c-badge {
  flex: 0 0 auto;
}
@media (max-width: 991px) {
  .UserStatus_cUserStatus__LQTgk.c-user-status {
    min-width: 0;
    gap: 0.5em;
  }
  .UserStatus_cUserStatus__LQTgk.c-user-status h1 {
    font-size: 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .UserStatus_cUserStatus__LQTgk.c-user-status .c-badge {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
  }
  .UserStatus_cUserStatus__LQTgk.c-user-status .c-badge.default {
    background-color: var(--medium-grey);
  }
  .UserStatus_cUserStatus__LQTgk.c-user-status .c-badge.success {
    background-color: var(--green);
  }
  .UserStatus_cUserStatus__LQTgk.c-user-status .c-badge.warning {
    background-color: var(--orange);
  }
  .UserStatus_cUserStatus__LQTgk.c-user-status .c-badge.danger {
    background-color: var(--red);
  }
  .UserStatus_cUserStatus__LQTgk.c-user-status .c-badge-text {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/UserStatus.module.scss"],"names":[],"mappings":"AAEI;EACE,oBAAA;EACA,mBAAA;EACA,gBAAA;AADN;AAGM;EACE,gBAAA;AADR;AAIM;EACE,cAAA;AAFR;AAKM;EAbF;IAcI,YAAA;IACA,UAAA;EAFN;EAIM;IACE,cAAA;IACA,mBAAA;IACA,uBAAA;IACA,gBAAA;EAFR;EAKM;IACE,UAAA;IACA,WAAA;IACA,UAAA;IACA,kBAAA;EAHR;EAKQ;IACE,oCAAA;EAHV;EAMQ;IACE,8BAAA;EAJV;EAOQ;IACE,+BAAA;EALV;EAQQ;IACE,4BAAA;EANV;EASQ;IACE,aAAA;EAPV;AACF","sourcesContent":[".cUserStatus {\n  &:global {\n    &.c-user-status {\n      display: inline-flex;\n      align-items: center;\n      gap: var(--px12);\n\n      h1 {\n        margin-bottom: 0;\n      }\n\n      .c-badge {\n        flex: 0 0 auto;\n      }\n\n      @media(max-width: 991px) {\n        min-width: 0;\n        gap: 0.5em;\n\n        h1 {\n          font-size: 1em;\n          white-space: nowrap;\n          text-overflow: ellipsis;\n          overflow: hidden;\n        }\n\n        .c-badge {\n          width: 8px;\n          height: 8px;\n          padding: 0;\n          border-radius: 50%;\n\n          &.default {\n            background-color: var(--medium-grey);\n          }\n\n          &.success {\n            background-color: var(--green);\n          }\n\n          &.warning {\n            background-color: var(--orange);\n          }\n\n          &.danger {\n            background-color: var(--red);\n          }\n\n          &-text {\n            display: none;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cUserStatus": `UserStatus_cUserStatus__LQTgk`
};
export default ___CSS_LOADER_EXPORT___;
