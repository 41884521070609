import clsx from "clsx"
import LoanPendingLayout from "../layouts/LoanPendingLayout"
import LoanConsentForm from "../components/forms/LoanConsentForm"
import styles from "../styles/LoanSubpageConsent.module.scss"

function LoanPendingConsent() {

  return (
    <LoanPendingLayout
      subPageTitle="Consent for Equifax Credit Check"
      subPageExtraClassName={clsx(styles.cPageConsent, "c-page-consent")}
      sectionExtraClassName="c-loan-consent"
    >
      <LoanConsentForm/>

    </LoanPendingLayout>
  )
}

export default LoanPendingConsent